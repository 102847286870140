<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import TopButton from "@/components/button/TopButton.vue";
import { handleUserLogout } from "@/utils/authUtils";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import ConnectAsModal from "@/views/connectAs/ConnectAsModal.vue";
import connectAsApi from "@/apiCalls/connectAsApi";
import { isEmpty, isNull } from "lodash";
import { useToast } from "vue-toast-notification";
import MenuBurger from "./MenuBurger.vue";
import PortalInformationModal from "@/components/modal/PortalInformationModal.vue";
import { useHeaderBar } from "@/composables/useHeaderBar";
import { useJourneyId } from "@/composables/useJourneyId";
import Notification from "@/components/header/Notifications.vue";
import DownloadModal from "../modal/DownloadModal.vue";
import { getData } from "@/apiCalls/taskApi";
import { SYNTHESIS_FIELDS } from "@/utils/dtoFields";
import { ModalData } from "@/types/DownloadModalTypes";
import { getPdfSynthesis } from "@/apiCalls/toolBox";
import {translate} from "@/i18n";


const props = withDefaults(
  defineProps<{
    route: string;
  }>(),
  {}
);

const toast = useToast();
const store = useStore();
const router = useRouter();
const headerBar = useHeaderBar();
const journeyId = useJourneyId();
const routePath = useRoute();
const disabledSynthesis = ref(false);
const showMenu = ref(false);
const modalConnectAs = ref(false);
const modalPortalInformation = ref(false);
const companySelected = ref("");
const userSelected = ref("");
const openedComponent = ref("");
const routes = [
  "/analysis-b2c",
  "/analysis-b2b",
  "/synthesis",
  "/open-banking",
  "/recovery",
, '/document-analysis'];
const modalData = ref<ModalData>();
const defaultFileName = ref("");
const hasMissingInfo = ref(false);
const downloadModal = ref(false);
const clientId = ref("");
const isDownloading = ref(false);


const user = computed(() => store.getters.user);

const badgeClass = computed(() => {
  return props.route?.startsWith("/back-office")
    ? "header-bar__identifier backoffice__identifier"
    : !isNull(user.value.realUser)
    ? "header-bar__identifier connect-as"
    : "header-bar__identifier";
});

const LocaleSwitcherClass = computed(() => {
  return props.route?.startsWith("/back-office")
    ? "switch-lang-backoffice"
    : "";
});

const usernameBadge = computed(() => {
  return !isEmpty(user.value) ? user.value.username : "";
});

const displayDownloadTopButton = computed(() => {
  return ((['/analysis-b2b', '/open-banking', '/analysis-b2c', '/document-analysis'].includes(props.route)) && journeyId.getJourneyId.value) || (props.route === '/synthesis');
});

const updateUserInfo = () => {
  userSelected.value = "";
  companySelected.value = "";
  modalConnectAs.value = false;
};

const logoutAs = async () => {
  toast.info(`${translate("CONNECT_AS.TOAST.LOGOUT")} ${userSelected.value}`);
  await connectAsApi.logoutConnectAs();
  await store.dispatch("fetchUser");
  updateUserInfo();
  await router.push("/home");
  location.reload();
};

const logOut = async () => {
  if (!isNull(user.value.realUser)) {
    await connectAsApi.logoutConnectAs();
    await store.dispatch("fetchUser");
    updateUserInfo();
  }
  await handleUserLogout();
};

const handleComponentOpened = (componentName: string) => {
  openedComponent.value = componentName;
};

const toggleMenu = async (label: string) => {
  showMenu.value = !showMenu.value;
  switch (label) {
    case "MENU.LOG_AS":
      modalConnectAs.value = true;
      break;
    case "MENU.PORTAL_INFORMATION":
      modalPortalInformation.value = true;
      break;
    case "MENU.LOGOUT_AS":
      await logoutAs();
      break;
    case "MENU.LOGOUT":
      await logOut();
      break;
  }
};

const openDownloadModal = async () => {
  const paths = ["/analysis-b2c", "/analysis-b2b", "/open-banking"];
  if (paths.includes(routePath.path)) {
    await journeyId.getDetailsForJourneyId(journeyId.getJourneyId.value);
    if (journeyId.details.value) {
      clientId.value = journeyId.details.value?.clientId;
    }}
    
    const response = await getData(
      journeyId.getJourneyId.value,
      SYNTHESIS_FIELDS
    );
    modalData.value = response?.data.data;
    downloadModal.value = !downloadModal.value;
    const fullName = `${modalData.value?.applicant?.[0]?.firstname || ""} ${
    modalData.value?.applicant?.[0]?.lastname || ""
  }`;
  const companyName = `${modalData.value?.company_legal_unit?.[0]?.corporateName || ""}`;
  const accountOwner = `${modalData.value?.open_banking_account?.[0]?.holder_name || ""}`;
  hasMissingInfo.value = !(fullName || companyName || accountOwner);
  defaultFileName.value = getFileName(fullName, companyName, accountOwner);
};

const getFileName = (
  fullName: string,
  companyName: string,
  accountOwner: string
) => {
  const showJourneyId: string = journeyId.getJourneyId.value;
  switch (clientId.value) {
    case "portal-b2c":
      return hasMissingInfo.value
        ? `${translate("TABLE.SUMMARY")}  - ${translate("PRIVATE_FILE")} ${showJourneyId}`
        : `${translate("TABLE.SUMMARY")} - ${fullName}`;

    case "portal-b2b":
      return hasMissingInfo.value
        ? `${translate("TABLE.SUMMARY")} - ${translate("BUSINESS_FILE")} ${showJourneyId}`
        : `${translate("TABLE.SUMMARY")} - ${companyName}`;

    case "open-banking":
      return hasMissingInfo.value
        ? `${translate("TABLE.SUMMARY")} - ${translate("OPEN_BANKING_FILE")} ${showJourneyId}`
        : `${translate("TABLE.SUMMARY")} - ${accountOwner}`;

    case "document-analysis":
      return translate("DOCUMENT_SYNTHESIS");

    default:
      return`${translate("TABLE.SUMMARY")} - ${showJourneyId}`;
  }
};
  
  const startDownload = async (fileName: string) => {
    if (isDownloading.value) return;
    isDownloading.value = true;
    setTimeout(() => {
      downloadModal.value = false;
    }, 1500);
    try {
      await getPdfSynthesis(journeyId.getJourneyId.value, fileName);
    } catch (error) {
      console.error("Error while downloading", error);
    } finally {
      isDownloading.value = false;
    }
  }
  const handleFileNameUpdate = (newFileName: string) => {
    startDownload(newFileName);
  };

  watch(
    () => routePath.path,
    async () => {
      if (routePath.path?.startsWith("/synthesis")) {
        await journeyId.getDetailsForJourneyId(journeyId.getJourneyId.value);
        if (journeyId.details.value)
          clientId.value = journeyId.details.value?.clientId;
      }
    }
  );
  
  onMounted(async () => {
    if (routePath.path?.startsWith("/synthesis")) {
      await journeyId.getDetailsForJourneyId(journeyId.getJourneyId.value);
      if (journeyId.details.value)
        clientId.value = journeyId.details.value?.clientId;
    }
  });


</script>

<template>
  <div class="header-bar">
    <div v-if="routes.includes(route)" class="header-bar__group-button">
      <TopButton
        v-for="button in headerBar.getInfoButtons(
          route,
          journeyId.getJourneyId,
          journeyId.details.value
        )"
        :color="button?.color"
        :label="button?.title"
        @click="headerBar.navigate(route, button?.route)"
        class="top-button"
      />
      <TopButton
        v-if="displayDownloadTopButton"
        @click="openDownloadModal"
        :loading="disabledSynthesis"
        :label="translate('BUTTONS.EXPORT_PDF')"
        color="#ff7676"
        :disabled="isDownloading"
      />
      <DownloadModal
        v-model:open-modal="downloadModal"
        :modalData="modalData"
        :defaultFileName="defaultFileName"
        @updateFileName="handleFileNameUpdate"
        :isDownloading="isDownloading"
      />
    </div>
    <div class="locale-logout-container">
      <LocaleSwitcher :class="LocaleSwitcherClass" />
      <div v-if="!isEmpty(user)" :class="badgeClass">
        <div
          v-if="companySelected || userSelected || user.realUser !== null"
          class="header-bar__identifier__user-name-connect-as"
        >
          <span class="header-bar__identifier__user-name-connect-as__content">
            {{ user.realUser?.username }}
            {{ translate("CONNECT_AS.BADGE") }}</span
          >
          <span class="strong-name">{{ user.username }}</span>
        </div>
        <span v-else class="header-bar__identifier__user-name">
          {{ usernameBadge }}
        </span>
        <Notification
          :opened-component="openedComponent"
          @notifications-opened="handleComponentOpened"
        />
        <div>
          <MenuBurger
            :show-menu="showMenu"
            :opened-component="openedComponent"
            :menu-class="`${
              companySelected || userSelected || user.realUser !== null
                ? 'menu-connect'
                : 'menu'
            }`"
            @click="toggleMenu"
            @menu-burger-opened="handleComponentOpened"
          >
            <div class="menu-list">
              <router-link
                v-for="(item, index) in headerBar.menuFiltered(
                  user,
                  userSelected,
                  companySelected,
                  route
                )"
                :key="index"
                :to="item.route"
                @click="toggleMenu(item.label)"
                class="menu-list-item"
              >
                <img
                  :src="require(`../../../public/images/${item.imageSrc}`)"
                  :alt="require(`../../../public/images/${item.imageSrc}`)"
                  class="menu-item-icon"
                />
                <span
                  :class="`${
                    translate(item.label) === translate('MENU.LOGOUT_AS') &&
                    user &&
                    user.realUser !== null
                      ? 'disconnect-option'
                      : ''
                  }`"
                >
                  {{ translate(item.label) }}</span
                >
              </router-link>
            </div>
          </MenuBurger>
          <ConnectAsModal
            v-model:openModal="modalConnectAs"
            @confirm-selection="updateUserInfo"
          />
          <PortalInformationModal v-model:openModal="modalPortalInformation" />
        </div>
      </div>
    </div>
  </div>
</template>
