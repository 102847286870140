<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue";

type Option = {
  name: string;
  imageSrc?: string;
  value: string;
};

const props = defineProps<{
  label: string;
  options: Option[];
  modelValue: string;
  disabled: boolean;
}>();

const emit = defineEmits(["update:modelValue"]);

const showOptions = ref(false);
const options = ref<Option[]>(props.options);
const selectContainer = ref<HTMLDivElement>();

const selectedOption = computed(() => {
  return options.value.find((option) => option.value === props.modelValue);
});
const optionsToDisplay = computed(() => {
  return options.value.filter((option) => !(option.value === props.modelValue));
});

const setSelected = (option: Option) => {
  emit("update:modelValue", option.value);
  showOptions.value = false;
};

const toggleOptions = () => {
  if (props.disabled || options.value.length === 0) return;
  showOptions.value = !showOptions.value;
};

const hideOptionsOnClickOutside = (e: MouseEvent) => {
  const target = e.target;
  if (!selectContainer.value?.contains(target as Node))
    if (showOptions.value) showOptions.value = false;
};

onMounted(() => {
  document.addEventListener("click", hideOptionsOnClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", hideOptionsOnClickOutside);
});
</script>

<template>
  <div class="select-parent-container">
    <span class="label">{{ label }}</span>
    <div class="select-container" ref="selectContainer">
      <div
        @click="toggleOptions"
        class="select"
        :class="{ fold: showOptions, disabled: props.disabled }"
      >
        <span v-if="selectedOption" class="option selected" :class="{ disabled: props.disabled }">
          <img v-if="selectedOption.imageSrc" :src="selectedOption.imageSrc" alt="" />
          {{ selectedOption.name }}
        </span>
        <img
          src="/images/custom-select/down-arrow.png"
          class="select-arrow"
          alt=""
        />
      </div>
      <div class="option-container" :class="{ show: showOptions }">
        <span
          v-for="option in optionsToDisplay"
          @click="() => setSelected(option)"
          class="option"
        >
          <img v-if="option.imageSrc" :src="option.imageSrc" alt="" />
          {{ option.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/customSelect.scss";
</style>
