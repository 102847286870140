import {TreeNodeType} from "@/types/Tree";
import {translate} from "@/i18n";

export type GenericContextResponse = {
  [key: string]: {
    [key: string]: any;
  }
}

const translateContextKeys = (keys: string[]): string => {
  const joinedKeys = keys.join('.');
  const translated = translate(`CLIENT_CONTEXT.COMPANIES.${joinedKeys}`);
  return translated === `CLIENT_CONTEXT.COMPANIES.${joinedKeys}` ? keys[keys.length - 1] : translated;
}

const transformToTreeNodeAndTranslateKeys = (data: GenericContextResponse, companyName: string, contextName: string): TreeNodeType[] => {
  const transform = (obj: any): TreeNodeType[] => {
    return Object.keys(obj).map(key => {
      const value = obj[key];
      if (typeof value === 'object' && value !== null) {
        return {
          key: translateContextKeys([companyName, contextName, key]),
          value: null,
          children: transform(value)
        };
      } else {
        return {
          key: translateContextKeys([companyName, contextName, key]),
          value: String(value)
        };
      }
    });
  };

  return transform(data);
}

export const createTreeData = (response: GenericContextResponse, contextNames: string[], companyName: string): TreeNodeType[] => {
  if (contextNames.length === 0) return [];
  return contextNames.map(contextName => {
    const contextData = response[contextName]?.data;
    // e.g. CONSENT.data = 'true' | 'false';
    if (typeof contextData === 'boolean') {
      return {
        key: contextName,
        value: '',
        children: [{
          key: translateContextKeys([companyName, contextName, contextName]),
          value: String(contextData),
          children: []
        }]
      };
    } else {
      return {
        key: contextName,
        value: '',
        children: transformToTreeNodeAndTranslateKeys(contextData || {}, companyName, contextName)
      };
    }
  });
}