<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import CustomModal from "./CustomModal.vue";
import { translate } from "@/i18n";
import { TextInputType } from "@/types/TextInputType";
import InlineTextInput from "@/components/input/InlineTextInput.vue";
import Button from "../button/Button.vue";
import ResultCard from "../card/ResultCard.vue";
import { ModalData } from "@/types/DownloadModalTypes";

const props = withDefaults(
  defineProps<{
    modalData?: ModalData;
    defaultFileName: string;
    isDownloading: boolean;
  }>(),
  {
    isDownloading: false,
  }
);
const fileName = ref(props.defaultFileName);
const FILE_NAME = TextInputType.SYNTHESIS_FILE_NAME ;
const openModal = defineModel("openModal", { default: false, type: Boolean });
const emit = defineEmits(["updateFileName"]);
const sendFileName = () => {
  emit("updateFileName", fileName.value);
};

watch(
  () => props.defaultFileName,
  (newVal) => {
    fileName.value = newVal;
  }
);

onMounted(
  () => {
    fileName.value = props.defaultFileName;
  }
);

</script>

<template v-if="!isEmpty(modalData)">
  <CustomModal v-model:open="openModal">
    <span class="downloadModalTitle">{{translate("SYNTESIS_DOWNLOAD")}}</span>
    <ResultCard>
      <InlineTextInput
        class="text-input_file-name"
        :label="translate('FILE_NAME')"
        v-model="fileName"
        :type="FILE_NAME"
        :placeholder="fileName"
      />
      <Button
        :label="
          isDownloading
            ? translate('DOWNLOADING')
            : translate('SYNTESIS_DOWNLOAD')
        "
        :disabled="props.isDownloading"
        @click="sendFileName"
      />
    </ResultCard>
  </CustomModal>
</template>
