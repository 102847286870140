<script setup lang="ts">
import { isEmpty } from "lodash";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { usePhone } from "@/composables/usePhone";
import { translate } from "@/i18n";
import { getRegex, TextInputType } from "@/types/TextInputType";
import { openBankingCountryoptions } from "@/utils/openbankingCountry";
import { Routes } from "@/utils/openBankingUtils";

import Button from "@/components/button/Button.vue";
import ContentCard from "@/components/card/ContentCard.vue";
import TextInput from "@/components/input/TextInput.vue";
import PhoneInputMessage from "@/components/message/PhoneInputMessage.vue";
import CustomSelect from "@/components/select/CustomSelect.vue";
import PhoneInput from "../input/PhoneInput.vue";

const props = withDefaults(
  defineProps<{
    propPrefillClientForm: object;
    permissions: Array<String>;
    canOpenBaking: boolean;
    companyCountry?: string;
  }>(),
  {
    canOpenBaking: false,
    companyCountry: "FR",
  }
);

const EMAIL = TextInputType.EMAIL;

const route = useRoute();
const phoneCheck = usePhone();

const emit = defineEmits(["generate-link"]);

const phoneCountry = ref("+33");
const displayButton = ref(true);
const phone = ref({ disabled: false, value: "+33" });
const obCountry = ref({ disabled: false, value: props.companyCountry });
const email = ref({ disabled: false, value: "" });

const isFormValid = () => {
  return (
    // @ts-ignore
    getRegex(TextInputType.EMAIL).test(email.value.value) ||
    phoneCheck.checkPhoneValue(phone.value.value, phoneCountry.value)
  );
};

const generateLink = (e: SubmitEvent) => {
  e.preventDefault();
  phone.value.disabled = true;
  email.value.disabled = true;
  obCountry.value.disabled = true;
  if (!isFormValid()) return;
  const phoneNumber =
    phone.value.value === phoneCountry.value
      ? { value: "", disabled: true }
      : phone.value;
  if (route.path === "/analysis-b2b") {
    emit(
      "generate-link",
      { phoneNumber, email: email.value, country: obCountry.value },
      "MEELO_OPENBANKING_PROFESSIONAL_SCORING_MODEL"
    );
  } else if (route.path === "/analysis-b2c") {
    emit(
      "generate-link",
      { phoneNumber, email: email.value, country: obCountry.value },
      "MEELO_OPENBANKING_PERSONAL_SCORING_MODEL"
    );
  } else {
    emit("generate-link", {
      phoneNumber,
      email: email.value,
      country: obCountry.value,
    });
  }
  displayButton.value = false;
};

const checkPermission = (permission: string) => {
  if (route.path === Routes.OPEN_BANKING) {
    return props.permissions?.includes(`KIT_OB_${permission}`);
  } else if (route.path === Routes.ANALYSIS_B2B) {
    return props.permissions?.includes(`KIT_B2B_${permission}`);
  } else if (route.path === Routes.ANALYSIS_B2C) {
    return props.permissions?.includes(`KIT_B2C_${permission}`);
  } else if (route.path === Routes.SYNTHESIS) {
    return props.permissions?.some(
      (elt: any) =>
        elt.includes(`KIT_OB_${permission}`) ||
        elt.includes(`KIT_B2B_${permission}`) ||
        elt.includes(`KIT_B2C_${permission}`)
    );
  } else if (route.path === Routes.RECOVERY) {
    return true;
  }
};

const computeDefaultPhoneCountry = () => {
  const countryCode = phoneCheck.computePhoneCountry(props.companyCountry);
  phoneCountry.value = countryCode;
  phone.value.value = countryCode;
};

watch(
  () => props.propPrefillClientForm,
  (newVal) => {
    if (!isEmpty(newVal)) {
      email.value = newVal?.email ?? { disabled: true, value: "" };
      phone.value = newVal?.phone ?? { disabled: true, value: "+33" };
      obCountry.value = newVal?.country ?? { disabled: true, value: props.companyCountry };
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

onMounted(() => {
  computeDefaultPhoneCountry();
});
</script>

<template>
  <ContentCard
    v-if="!canOpenBaking"
    class="openbanking-informations"
    :title="translate('PROCESS_OPEN_BANKING')"
    :checked-display-content="false"
    :is-padlock="true"
  />
  <template v-else>
    <ContentCard
      class="openbanking-informations"
      :title="translate('PROCESS_OPEN_BANKING')"
    >
      <form class="openbanking-informations__form">
        <div class="openbanking-informations__form__country">
          <CustomSelect
            :label="translate('ADDRESS.COUNTRY')"
            :options="openBankingCountryoptions"
            :disabled="obCountry.disabled"
            v-model="obCountry.value"
          />
        </div>
        <PhoneInput
          v-if="checkPermission('OB_INPUT_TEXT_MESSAGE')"
          v-model:phoneValue="phone.value"
          v-model:phoneCountry="phoneCountry"
          :required="true"
          :title="translate('IDENTITY.PHONE_NUMBER')"
          class="openbanking-informations__form__phone"
          :disable-phone-value="phone.disabled"
        />
        <TextInput
          v-if="checkPermission('OB_INPUT_MAIL')"
          class="openbanking-informations__form__mail"
          :label="translate('IDENTITY.EMAIL')"
          v-model="email.value"
          :type="EMAIL"
          :disabled="email.disabled"
          required
        />
        <Button
          v-if="displayButton"
          class="openbanking-informations__form__action"
          type-button="submit"
          :disabled="!isFormValid()"
          :label="translate('BUTTONS.VALIDATE')"
          @click="generateLink"
        />
      </form>
      <div class="openbanking-informations__message">
        <PhoneInputMessage />
        <span>{{ `⚠️ ${translate("OPEN_BANKING.MESSAGE")}` }}</span>
      </div>
      <slot />
    </ContentCard>
  </template>
</template>
