export default {
  "ACCOUNT_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn account"])},
    "GENERAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemene informatie"])},
    "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf"])},
    "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersnaam"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
    "PHONE_NUMBER_NOT_SPECIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet gespecificeerd"])},
    "TWO_FACTOR_AUTHENTICATION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tweestapsverificatie instellen"])},
      "REQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereist door het bedrijf"])},
      "CHECKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gecontroleerd"])},
      "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn gegevens bewerken"])},
      "MODE": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificatiemodus"])},
        "SMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
        "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
        "DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgeschakeld"])}
      }
    },
    "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn informatie"])}
  },
  "LOGIN_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw e-mail"])},
    "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw wachtwoord"])},
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De ingevoerde gegevens komen niet overeen met een account"])},
    "ATTEMPTS_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opeenvolgende onjuiste pogingen zullen uw account blokkeren."])},
    "LOCKED_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw account is geblokkeerd."])},
    "FORGOT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ben mijn wachtwoord vergeten"])},
    "TWO_FACTOR_AUTHENTICATION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tweestapsverificatie"])},
      "ALERT_MESSAGE_CHECKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze handeling zal uw oude tweestapsverificatiemethode overschrijven. Weet u zeker dat u wilt doorgaan?"])},
      "ALERT_MESSAGE_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze handeling zal tweestapsverificatie activeren."])},
      "ALERT_MESSAGE_DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hierdoor wordt tweefactorauthenticatie uitgeschakeld."])},
      "DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tweestapsverificatie uitschakelen"])},
      "DISABLE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt tweestapsverificatie uitschakelen, maar we raden aan deze functie ingeschakeld te laten om de beveiliging van uw account te verbeteren."])},
      "COMPANY_2FA_REQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertel ons welke authenticatiemethode u wilt gebruiken."])},
      "EMAIL_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een verificatiecode wordt naar uw e-mailadres gestuurd"])},
      "SMS_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een verificatiecode wordt per SMS naar u verzonden. Voer uw telefoonnummer in:"])},
      "TITLE_MODAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tweestapsverificatie methode"])},
      "MESSAGE_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft een bericht ontvangen code"])},
      "FINALISATION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer de ontvangen code in om tweestapsverificatie te voltooien"])}
    }
  },
  "PRIVATE_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privé bestand"])},
  "BUSINESS_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakelijk bestand"])},
  "OPEN_BANKING_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Banking bestand"])},
  "DOCUMENT_SYNTHESIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentensynthese"])},
  "FILE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestandsnaam"])},
  "SYNTESIS_DOWNLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synthese Downloaden"])},
  "DOWNLOADING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezig met downloaden..."])},
  "RESET_PASSWORD_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord opnieuw instellen"])},
    "INIT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord initialiseren"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jouw e-mail"])},
    "FORGOT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten"])},
    "NEW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw wachtwoord"])},
    "CONFIRM_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig nieuw wachtwoord"])},
    "RESET_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord opnieuw instellen"])},
    "SEND_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stuur wachtwoordreset e-mail"])},
    "SUCCESS_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een wachtwoordreset e-mail naar je verzonden."])},
    "EMAIL_NOT_SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail kon niet worden verzonden."])},
    "INVALID_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord moet minimaal 8 tekens lang zijn, één hoofdletter, één kleine letter, één nummer en één speciaal teken bevatten."])},
    "PASSWORD_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoorden komen niet overeen."])},
    "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je wachtwoord is succesvol opnieuw ingesteld."])},
    "PASSWORD_RESET_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden bij het opnieuw instellen van uw wachtwoord."])}
  },
  "CGS": {
    "BEGINNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door verder te gaan, accepteert u het"])},
    "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacybeleid"])},
    "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["van Meelo"])}
  },
  "IDENTITY": {
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "FIRSTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
    "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboortedatum"])},
    "PLACE_OF_BIRTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboorteplaats"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiel telefoonnummer"])},
    "LASTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
    "USAGE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiksnaam"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
  },
  "ADDRESS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "TITLE_POSTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postadres"])},
    "NUMBER_AND_STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huisnummer en straatnaam"])},
    "ZIP_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
    "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaats"])},
    "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])}
  },
  "COMPANY": {
    "NO_REPRESENTATIVES_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen bekende leiders en vertegenwoordigers"])},
    "NO_BUSINESS_OWNERS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen bekende aandeelhouders en eigenaars"])},
    "LIQUIDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidatie"])},
    "RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radiatie"])},
    "SIREN_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificatienummer"])},
    "ORIGIN": {
      "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frankrijk"])},
      "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["België"])},
      "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaans"])}
    },
    "REGISTRATION_NUMBER": {
      "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsidentificatienummer of SIREN-nummer"])},
      "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationaal registratienummer of BTW-nummer"])},
      "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiscaal identificatiecode"])},
      "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duitsland"])},
      "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denemarken"])},
      "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estland"])},
      "EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engeland"])},
      "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finland"])},
      "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verenigd Koninkrijk"])},
      "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ierland"])},
      "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italië"])},
      "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litouwen"])},
      "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letland"])},
      "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederland"])},
      "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noorwegen"])},
      "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polen"])},
      "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
      "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweden"])}
    },
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificatienummer"])},
    "SIRET_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET-nummer"])},
    "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activiteit"])},
    "MAIN_ACTIVITY_DECLARED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangegeven hoofdactiviteit"])},
    "ACTIVITY_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAF- of APE-code"])},
    "INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsinformatie"])},
    "CREATED_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprichtingsdatum"])},
    "CORPORATE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridische naam"])},
    "LEGAL_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridische vorm"])},
    "CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maatschappelijk kapitaal"])},
    "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsidentiteit"])},
    "BUSINESS_OWNERS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lijst van werkelijke begunstigden"])},
    "BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekende werkelijke begunstigden"])},
    "DIRECT_BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directe begunstigde"])},
    "INDIRECT_BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirecte begunstigde via een rechtspersoon"])},
    "IN_POSITION_SINCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In functie sinds"])},
    "MOTHBALLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de wachtstand"])},
    "CURRENT_PROCEDURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lopende procedures"])},
    "COLLECTIVE_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collectieve procedures"])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-reputatie"])},
    "GLOBAL_NOTE_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemene beoordeling"])},
    "ADVICE_NOTE_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal adviezen"])},
    "WORDING_CLOUD_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhaling van woorden in adviezen"])},
    "ADJECTIVES_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positieve adviezen / Negatieve adviezen"])},
    "WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
    "NUMBER_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal vestigingen"])},
    "ESTABLISHMENTS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lijst van vestigingen"])},
    "REPRESENTATIVES_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lijst van vertegenwoordigers"])},
    "REPRESENTATIVES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertegenwoordigers"])},
    "REPRESENTATIVES_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekende leiders en vertegenwoordigers"])},
    "BUSINESS_OWNERS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekende aandeelhouders en begunstigden"])},
    "RECOMMENDED_OUTSTANDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanbevolen uitstaande bedragen"])},
    "HEADQUARTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdkantoor"])},
    "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positie"])},
    "TOTAL_SHARE_HOLDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal aandelenbelang"])},
    "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land van herkomst van het bedrijf"])},
    "TYPE_PERSON": {
      "NATURAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natuurlijke persoon"])},
      "LEGAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtspersoon"])}
    },
    "FUNCTIONS": {
      "PRESIDENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorzitter"])},
      "CHAIRMAN_OF_THE_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorzitter van de Raad van Bestuur"])},
      "CHAIRMAN_AND_CHIEF_EXECUTIVE_OFFICER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorzitter en Chief Executive Officer"])},
      "MANAGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheerder"])},
      "NON_ASSOCIATE_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet-geassocieerde beheerder"])},
      "CO_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co-beheerder"])},
      "MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemeen directeur"])},
      "GENERAL_MANAGER_NOT_A_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemeen directeur geen bestuurder"])},
      "SOLE_MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enige algemeen directeur"])},
      "CHIEF_OPERATING_OFFICER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chief Operating Officer"])},
      "VICE_CHAIRMAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vice-voorzitter"])},
      "DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestuurder"])},
      "DEPUTY_ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjunct-bestuurder"])},
      "CHAIRMAN_OF_THE_EXECUTIVE_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorzitter van de uitvoerende bestuurders"])},
      "CHAIRMAN_OF_THE_SUPERVISORY_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorzitter van de Raad van Toezicht"])},
      "MANAGING_PARTNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beherend vennoot"])},
      "CONTROLLER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller"])},
      "STATUTORY_AUDITOR_TITULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wettelijke auditor - titularis"])},
      "STATUTORY_AUDITOR_DEPUTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wettelijke auditor - plaatsvervanger"])},
      "STATUTORY_AUDITOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wettelijke auditor"])},
      "LIQUIDATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidator"])},
      "PROVISIONAL_ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorlopig beheerder"])},
      "REPRESENTATIVE_IN_FRANCE_OF_A_FOREIGN_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertegenwoordiger in Frankrijk van een buitenlandse onderneming"])},
      "MEMBER_OF_THE_BOARD_OF_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lid van de Raad van Bestuur"])},
      "CHAIRMAN_OF_THE_BOARD_OF_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorzitter van de Raad van Bestuur"])}
    },
    "TYPE": {
      "SOCIETY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maatschappij"])},
      "COLLECTIVE_INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collectieve belegging"])},
      "GIE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep voor economisch belang"])}
    },
    "OF_SHARES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["van de aandelen"])},
    "OF_VOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["van de stemmen"])},
    "LEGAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridische informatie"])},
    "WORKFORCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personeelsbestand"])},
    "VAT_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTW-nummer"])},
    "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluitingsdatum"])},
    "ACTIVITY_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activiteitsstatus"])},
    "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
    "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactief"])},
    "UNVERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet-ingezetene"])},
    "RECOMMENDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanbeveling"])},
    "FIELD_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsgebied"])},
    "ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vestigingen"])},
    "OFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kantoor"])},
    "IN_OPERATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In bedrijf"])},
    "CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesloten"])},
    "FINANCES": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiële gegevens van het bedrijf"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen financiële gegevens beschikbaar"])},
      "RATIOS": {
        "grossMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brutomarge (€)"])},
        "netMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettomarge (€)"])},
        "valueAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegevoegde waarde (€)"])},
        "ebitda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBITDA (€)"])},
        "ebit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBIT (€)"])},
        "profiteBeforeTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winst voor belastingen (€)"])},
        "extraordinaryResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buitengewoon resultaat (€)"])},
        "netIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettoresultaat (€)"])},
        "shortTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kortlopende schulden (€)"])},
        "longMidTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lang- en middellanglopende schulden (€)"])},
        "turnover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omzet (€)"])},
        "balanceSheetTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balanstotaal (€)"])},
        "capitalAndReserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigen vermogen (€)"])},
        "workingCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkkapitaal (€)"])},
        "workingCapitalRequirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkkapitaalbehoefte (€)"])},
        "financialLiquidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiële liquiditeit (€)"])},
        "intangibleAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immateriële activa (€)"])},
        "tangibleFixedAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tastbare vaste activa (€)"])},
        "participatingInterestsAndRelatedDebtsReceivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deelnemingen en aanverwante vorderingen (€)"])},
        "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorraden (€)"])},
        "othersDebtsReceivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overige vorderingen (€)"])},
        "tradeDebtorsAndRelatedAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handelsvorderingen en aanverwante rekeningen (€)"])},
        "financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiën (€)"])},
        "chargesCa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten (€)"])},
        "appropriationsToDepreciationAndProvisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toewijzingen aan afschrijvingen en voorzieningen (€)"])},
        "totalExtraordinaryIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal buitengewone opbrengsten (€)"])},
        "totalExtraordinaryCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal buitengewone kosten (€)"])},
        "corporateTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vennootschapsbelasting (€)"])},
        "operatingLiabilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationele verplichtingen (€)"])},
        "capital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapitaal (€)"])},
        "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve (€)"])},
        "profitOrLossForTheFinancialYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winst of verlies voor het boekjaar (€)"])},
        "investmentGrantsTaxRegulatedProvisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investeringsaftrek en belastinggereguleerde voorzieningen (€)"])},
        "loansAndDebtsPayableToCreditInstitutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leningen en schulden aan kredietinstellingen op middellange en lange termijn (€)"])},
        "loansAndSundryFinancialDebtsPayable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leningen en diverse financiële schulden (€)"])},
        "changeInStocksIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overige opbrengsten (€)"])},
        "purchasesOfGoodsForResale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aankopen van goederen voor wederverkoop (€)"])},
        "changeInStocksCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijziging in voorraadkosten (€)"])},
        "otherExternalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere externe diensten (€)"])},
        "taxesLeviesAndSimilarPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingen, heffingen en vergelijkbare betalingen (€)"])},
        "personnelCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personeelskosten (€)"])},
        "otherCurrentOperatingCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere huidige operationele kosten (€)"])},
        "financialIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiële inkomsten (€)"])},
        "financialCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiële kosten (€)"])},
        "fpTb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigen vermogen als percentage van het totale vermogen (%)"])},
        "supplierDeadlines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverancierskrediettermijnen (dagen)"])},
        "customerDeadlines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantenkrediettermijnen (dagen)"])},
        "caf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cashflow uit operationele activiteiten (€)"])},
        "rnCa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettowinstmarge op omzet (%)"])},
        "etp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equivalent voltijds personeel"])},
        "dividends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividenden aan aandeelhouders uitgekeerd (€)"])},
        "soldeFinancier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiële saldi (€)"])},
        "salesGrowthRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omzetgroei (percentage)"])},
        "grossMarginRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brutomargepercentage (%)"])},
        "ebitdaMarginRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBITDA-margepercentage (%)"])},
        "operatingMarginRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsmargespercentage (%)"])}
      },
      "BLOCKS": {
        "intermediateOperationTotals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tussenresultaten"])},
        "debts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schulden"])},
        "balanceSheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balans"])},
        "fixedAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaste activa"])},
        "currentAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlottende activa"])},
        "profitAndLossAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winst- en verliesrekening"])},
        "balancePassive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passiefzijde van de balans"])},
        "capitalAndReserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigen vermogen"])},
        "operatingIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsopbrengsten"])},
        "operatingCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfskosten"])},
        "operatingResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsresultaat"])},
        "ratios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiële ratio's"])},
        "increase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groei"])}
      }
    },
    "LEGAL_DOCUMENT": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridische documenten"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen juridische documenten beschikbaar"])},
      "NO_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om het document te bekijken, gelieve het te downloaden."])}
    },
    "BODACC_ANNOUNCEMENT": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontwikkeling van het bedrijf"])},
      "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "NATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natuur"])},
      "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie"])},
      "DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])}
    },
    "ANNUAL_ACCOUNTS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaarrekeningen"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen jaarrekeningen beschikbaar"])},
      "COMPANY_FINANCIAL_STATEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsfinanciën"])}
    },
    "CHECKS": {
      "MORE_THAN_ONE_TAX_REPORT_WITH_SAME_SUBMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer dan één belastingaangifte ingediend op dezelfde datum"])},
      "LESS_THAN_X_YEARS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf jonger dan 3 jaar"])},
      "MANAGEMENT_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wisseling van management minder dan 3 jaar"])},
      "SHARE_CAPITAL_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijziging in het kapitaal binnen 3 jaar"])},
      "HEADQUARTER_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verplaatsing van het hoofdkantoor binnen 3 jaar"])},
      "ACTIVITY_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijziging in de activiteit binnen 3 jaar"])},
      "MULTI_MANAGEMENT_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer dan 2 wijzigingen in het management in de afgelopen 10 jaar"])},
      "HAS_CESSATION_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beëindiging van activiteiten"])},
      "HAS_ALL_ESTABLISHMENTS_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle vestigingen zijn gesloten"])},
      "HAS_ACTIVITY_CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer dan twee veranderingen in activiteit in de afgelopen 10 jaar"])},
      "HAS_HEADQUARTER_CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer dan twee verhuizingen van het hoofdkantoor in de afgelopen 10 jaar"])},
      "LOCATION_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risicovolle locatie"])},
      "DOMICILIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedomicilieerd bedrijf"])},
      "WEBSITE_DOMAIN_REGISTRATION_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registratiedatum van domeinnaam van de website"])},
      "EMAIL_DOMAIN_REGISTRATION_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registratiedatum van domeinnaam van e-mail"])},
      "EMAIL_DOMAIN_EQUALS_WEBSITE_DOMAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overeenkomst tussen e-maildomein en websitedomein"])},
      "RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straling"])},
      "COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collectieve procedures"])},
      "OTHER_COMPANIES_IN_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere bedrijven in collectieve procedures"])},
      "OTHER_COMPANIES_IN_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere bedrijven bestraald"])},
      "ACTIVITIES_CODE_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risicovolle activiteitensector"])},
      "HEADQUARTER_DISTANCE_TOO_HIGH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanzienlijke afstand tussen het adres van het hoofdkantoor en de woonplaats van de manager"])},
      "LEGAL_SITUATION_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheid van geschillen"])},
      "MORE_THAN_THREE_YEARS_WITHOUT_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode van meer dan 3 jaar zonder activiteit"])},
      "MULTI_HEADQUARTER_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer dan 2 hoofdkantoorwijzigingen in de afgelopen 10 jaar"])},
      "MULTI_ACTIVITY_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer dan 2 activiteitswijzigingen in de afgelopen 10 jaar"])},
      "CESSATION_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beëindiging van activiteit"])},
      "ALL_ESTABLISHMENTS_ARE_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle vestigingen zijn gesloten"])},
      "CONTACTABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactbaarheid van gegevens"])},
      "HEADQUARTER_ADDRESS_IN_WARNING_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraude geconstateerd op dit adres"])},
      "MULTI_REGISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer dan één registratie sinds de oprichting"])},
      "MORE_THAN_ONE_ESTABLISHMENT_DOMICILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minstens één vestiging is gevestigd"])},
      "MORE_THAN_ONE_TAX_REPORT_WITH_SUBMISSION_DATE_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer dan één belastingrapport met een gesloten indieningsdatum"])},
      "MORE_THAN_ONE_TAX_REPORT_WITH_SAME_SUBMISSION_DATE_AND_YEAR_DIFFERENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer dan één belastingrapport met dezelfde indieningsdatum en een ander jaar"])},
      "MORE_THAN_TEN_TAX_REPORTS_WITH_DIFFERENT_SUBMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer dan tien belastingrapporten met verschillende indieningsdatums"])},
      "RECENT_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recente collectieve procedures"])},
      "OTHER_COMPANIES_IN_SAME_ACTIVITY_SECTOR_IN_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere bedrijven in dezelfde sector in collectieve procedures"])},
      "ALREADY_SOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf reeds verkocht"])},
      "ALREADY_DEREGISTERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf reeds uitgeschreven"])}
    },
    "DOMICILIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domiciliëring"])},
    "BRANCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vestiging"])},
    "CHECK_EMPLOYER": {
      "NO_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De informatie kon niet worden geverifieerd"])},
      "FALSE_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het bedrijf is niet geschrapt"])},
      "TRUE_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het bedrijf is van de lijst gehaald"])},
      "COLLECTIVE_PROCEEDING": {
        "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen lopende collectieve procedures gedetecteerd"])},
        "MORE_THAN_ONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more pending collective proceedings detected"])},
        "RECENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more current collective proceedings detected, including at least one recent one (< 3 years)"])}
      }
    }
  },
  "ERRORS": {
    "MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oeps! Er lijkt een probleem te zijn. Ons team werkt al aan de oplossing. Probeer het later opnieuw."])},
    "NOT_FOUND_SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het ingevoerde identificatienummer bestaat niet."])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen informatie opgehaald"])},
    "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze tool voor belastingaangifte verwerkt geen documenten van vóór 2018."])},
    "TAX_REPORT_TOO_OLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te oude belastingaangifte"])},
    "DOCUMENT_PROCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden tijdens het lezen van het document"])},
    "COMPANY_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het ingevoerde identificatienummer is niet gekoppeld aan een actief bedrijf."])},
    "ENRICHMENT_PROCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen informatie gevonden."])},
    "ERROR_OCCURRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden, probeer het later opnieuw"])},
    "NO_DATA_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gegevens gevonden, start alstublieft een nieuwe analyse"])},
    "CALL_FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oproep voltooid, let op dat sommige gegevens niet toegankelijk zijn. Voor een volledig rapport, start alstublieft een nieuwe analyse"])},
    "SIREN_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse voltooid, geen gegevens gevonden met de opgegeven identificatienummer"])},
    "CREDIT_SAFE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gegevens opgehaald"])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gegevens beschikbaar"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden bij het invoeren van het telefoonnummer. Controleer de ingevoerde gegevens"])},
    "UNPROCESSABLE_CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden, probeer het opnieuw in een nieuwe map of neem contact op met de support"])},
    "CANNOT_ACCESS_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt geen toegang tot deze map."])}
  },
  "BUTTONS": {
    "VALIDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valideren"])},
    "VERIFY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifiëren"])},
    "CONNEXION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding"])},
    "VALIDATE_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie valideren"])},
    "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "VERIFY_IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentverificatie"])},
    "START_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Banking starten"])},
    "JOURNEY_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltooi de Open Banking-reis in de browser"])},
    "FINISH_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse voltooien"])},
    "NEW_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe analyse starten"])},
    "EXPORT_PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samenvatting downloaden"])},
    "PREVIOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige"])},
    "DETAILS_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysegegevens"])},
    "VERIFY_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres verifiëren"])},
    "SEND_TEXT_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS verzenden"])},
    "SEND_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail verzenden"])},
    "BACK_HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar de startpagina"])},
    "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML/CFT"])},
    "VALIDATE_AND_SEND_SMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valideren en SMS verzenden"])},
    "VALIDATE_AND_SEND_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valideren en e-mail verzenden"])},
    "IFRAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iframe"])},
    "RUN_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse uitvoeren"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN-analyse"])},
    "FRAUD_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "SCORE_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraudescores"])},
    "SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteren"])},
    "SELECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geselecteerd"])},
    "CONSULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raadplegen"])},
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiezen"])},
    "DEACTIVATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactiveren"])},
    "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
    "CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigen"])},
    "LINK_TEXT_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link via SMS"])},
    "LINK_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link via e-mail"])},
    "ENGLISH_VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engelse Versie"])},
    "FRENCH_VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Franse Versie"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])},
    "UPLOAD_HERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier uploaden"])},
    "MY_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn bedrijf"])},
    "MY_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn gebruikers"])},
    "CONSUMPTION_MONITORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbruiksmonitoring"])},
    "EXTRACT_MEMORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geheugen extraheren"])},
    "RULES_ENGINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regelengine"])},
    "BATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch"])},
    "MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn account"])},
    "CREATE_A_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker aanmaken"])},
    "UNBLOCK_A_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een gebruiker deblokkeren"])},
    "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken"])},
    "ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen"])},
    "CONFIRM_AND_CONTINUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigen en doorgaan"])},
    "RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetten"])},
    "MODIFY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigen"])},
    "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord resetten"])},
    "ACTIVATE_DEACTIVATE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account activeren / deactiveren"])},
    "RESETTING_API_KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstellen van API-sleutel"])},
    "DELETE_THE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het account verwijderen"])},
    "RETURN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terugkeer"])},
    "NEW_REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe aanvraag"])},
    "RESTART_REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De aanvraag opnieuw starten"])},
    "LINK_BROWSER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browserlink"])},
    "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
    "CONSULT_FINOVOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raadpleeg de Finovox-analyse"])},
    "LINKEDIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar LinkedIn"])},
    "DEPLOY_EVERYTHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles implementeren"])},
    "REDUCE_EVERYTHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles inklappen"])},
    "SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzenden"])},
    "SCORE_SMB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identiteitsscore"])},
    "SCORE_COMPANY_FRAUD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsfraudescore"])},
    "CLOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])},
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende"])},
    "SELECT_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles selecteren"])},
    "MY_TEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn team"])},
    "DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen"])},
    "COMPLETE_UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload voltooien"])},
    "LIVE_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opheffing van twijfels"])}
  },
  "APPLICANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanvrager"])},
  "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoon"])},
  "VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereikbaar"])},
  "LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekoppeld"])},
  "MAIL_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailaccounts gekoppeld aan telefoonnummer"])},
  "PHONE_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonaccounts gekoppeld aan e-mailadres"])},
  "APPLICANT_RESULTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultaten van de aanvrager"])},
  "CONTACT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactgegevens"])},
  "CLIENT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantgegevens"])},
  "VERIFICATION_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentverificatie"])},
  "VERIFICATION_TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificatie van belastingaangifte"])},
  "CONFIDENCE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraudescore Identiteit"])},
  "SMB_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraudescore Identiteit Zakelijk"])},
  "CONFIDENCE_INDEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraudescore Identiteit"])},
  "DETAILED_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details van de score"])},
  "CONFIDENCE_EXPLICATIONS": {
    "UNDER_TWENTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstrekte gegevens niet gerelateerd aan de persoon"])},
    "BETWEEN_TWENTY_AND_FIFTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twijfelachtige gegevens, aanbevolen identiteitsverificatie"])},
    "ABOVE_FIFTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestaande en gerelateerde gegevens verstrekt"])}
  },
  "DOCUMENT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentanalyse"])},
  "ANALYSIS_RESULT_RETRIEVAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ophalen van lopende analyse resultaten ..."])},
  "UPLOAD_FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentupload voltooid, u kunt dit venster sluiten."])},
  "FACE_MATCHING_LINK_CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een link naar je verzonden via SMS, controleer je telefoon"])},
  "LINK_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koppeling met het bedrijf"])},
  "IDENTITY_CONSISTENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identiteitsconsistentie"])},
  "COMPANY_CONSISTENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsconsistentie"])},
  "OTHER_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere persoon"])},
  "SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situatie"])},
  "NOT_FOUND": {
    "NO_BUSINESS_OWNERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen bekende uiteindelijke begunstigden."])},
    "NO_REPRESENTATIVES_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen bekende vertegenwoordigers."])},
    "NO_LEADERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen leiders gevonden..."])}
  },
  "OPEN_BANKING": {
    "END_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gegevens zijn succesvol verzonden. U kunt deze pagina sluiten om het resultaat van de analyse te bekijken."])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking"])},
    "RESULT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse van OpenBanking"])},
    "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedetailleerde analyse van OpenBanking"])},
    "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "INCOMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkomsten"])},
    "OUTCOMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgaven"])},
    "HOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houder"])},
    "ACCOUNT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekeningnaam"])},
    "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld maandelijks salaris"])},
    "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde maandelijkse huur"])},
    "BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidig saldo"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve uw klant te vragen zijn smartphone en bankgegevens bij de hand te hebben."])},
    "CATEGORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
    "FINANCIAL_ANALYSIS_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode van financiële analyse"])},
    "ANALYSIS_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geanalyseerde periode"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tot"])},
    "CATEGORIES": {
      "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximaal transactiebedrag"])},
      "MONTH_MAX_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum van maximale transactie"])},
      "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld totaal maandelijks bedrag"])},
      "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld maandelijks bedrag - Salarissen"])},
      "MONTH_SALARIES_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde maandelijkse datum - Salarissen"])},
      "MONTH_INTERESTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld maandelijks bedrag - Rente"])},
      "MONTH_TRANSFERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld maandelijks bedrag - Overboekingen"])},
      "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld maandelijks bedrag - Huur"])},
      "MONTH_WITHDRAWALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld maandelijks bedrag - Geldopnames"])},
      "MONTH_INSURANCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld maandelijks bedrag - Verzekeringen"])},
      "MONTH_UTILITIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld maandelijks bedrag - Nutsvoorzieningen"])},
      "MONTH_GROCERIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld maandelijks bedrag - Levensmiddeleninkopen"])},
      "MONTH_FOOD_CONSUMPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld maandelijks bedrag - Voedselconsumptie"])},
      "MONTH_SHOPPINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld maandelijks bedrag - Winkelen"])},
      "MONTH_HEALTH_WELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld maandelijks bedrag - Gezondheid/welzijn"])},
      "MONTH_TRANSPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld maandelijks bedrag - Vervoer"])},
      "MONTH_TELECOMMUNICATION_SUBSCRIPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld maandelijks bedrag - Abonnementen voor telecommunicatiediensten"])},
      "MONTH_AUDIO_VISUAL_SUBSCRIPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld maandelijks bedrag - Abonnementen voor audiovisuele diensten"])},
      "MONTH_INCOMES_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijks gemiddelde van inkomsten"])},
      "MONTH_INCOMES_SALARIES_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijks gemiddelde van salarissen"])},
      "MONTH_INCOMES_ALLOWANCES_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijks gemiddelde van toelagen"])},
      "MONTH_INCOMES_PENSIONS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijks gemiddelde van pensioenen"])},
      "MONTH_INCOMES_AMOUNT_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoogste gemiddelde maandelijkse bedrag"])},
      "MONTH_INCOMES_AMOUNT_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laagste gemiddelde maandelijkse bedrag"])},
      "MONTH_OUTCOMES_RENTS_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde maandelijkse huur"])},
      "MONTH_OUTCOMES_CREDITS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde maandelijkse kredieten"])},
      "MONTH_OUTCOMES_CREDITS_DATE_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde datum voor kredietafschrijvingen"])},
      "MONTH_OUTCOMES_PENSIONS_DATE_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum van uitbetaling van een pensioen"])},
      "RECOMMENDED_DEBIT_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanbevolen incassodatum"])},
      "RECOMMENDED_DEBIT_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanbevolen incassobedrag"])},
      "MONTHLY_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijks bedrag"])},
      "MONTH_OUTCOMES_RENTS_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimale huurbedrag"])},
      "MONTH_OUTCOMES_RENTS_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale huurbedrag"])},
      "MONTH_OUTCOMES_UTILITIES_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimale kosten voor nutsvoorzieningen"])},
      "MONTH_OUTCOMES_UTILITIES_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale kosten voor nutsvoorzieningen"])},
      "MONTH_OUTCOMES_CREDITS_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimale kredietbedrag"])},
      "MONTH_OUTCOMES_CREDITS_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale kredietbedrag"])},
      "MONTH_OUTCOMES_CREDITS_COUNT_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimaal aantal afschrijvingen per maand"])},
      "MONTH_OUTCOMES_CREDITS_COUNT_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximaal aantal afschrijvingen per maand"])},
      "BANK_CARD_AND_DEBITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankkaart en incasso"])},
      "DEBITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incasso"])},
      "BANK_CARD_PRESENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheid van bankkaart(en)"])},
      "BANK_CARD_QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal kaarten met uitgaande transacties"])},
      "BANK_CARD_NUMBERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geïdentificeerde kaartnummers voor uitgaande transacties"])},
      "MONTH_NEGATIVE_BALANCE_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vroegst mogelijke datum"])},
      "MONTH_NEGATIVE_BALANCE_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatst mogelijke datum"])},
      "MAX_BALANCE_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vroegst mogelijke datum van maximale maandelijkse saldo"])},
      "LAST_SALARY_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum van laatste salaris"])},
      "MONTH_END_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deel van uitgaven ten opzichte van inkomsten"])},
      "MONTH_NEGATIVE_BALANCE_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde datum van negatief saldo gedurende de maand"])},
      "AVERAGE_MONTHLY_ENTRIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde maandelijkse inschrijvingen"])},
      "TOTAL_FOR_THE_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal over de periode"])},
      "AVERAGE_MONTHLY_TAX_REVENUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde maandelijkse belastinginkomsten"])},
      "AVERAGE_MONTHLY_EXITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde maandelijkse uitgangen"])},
      "ANNUAL_NET_INCOME_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag van het jaarlijks netto inkomen"])},
      "ANNUAL_REVENUE_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag van de jaarlijkse omzet"])},
      "MONTHLY_TAX_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijks belastingbedrag"])},
      "AVERAGE_MONTHLY_PAYROLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijks gemiddelde loonsom"])},
      "OTHER_ACCOUNTS": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere rekeningen"])},
        "MONTH_INCOMES_SAVINGS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld maandelijks spaarinkomen"])},
        "MONTH_OUTCOMES_SAVINGS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld maandelijks spaaruitkomst"])},
        "MONTH_INCOMES_TRANSFERS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld maandelijks binnenkomende transfers"])},
        "MONTH_OUTCOMES_TRANSFERS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld maandelijks uitgaande transfers"])}
      }
    },
    "DEBT_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schuldratio"])},
    "MONTH_OUTCOMES_CREDITS_COUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal lopende kredieten"])},
    "REMAINS_TO_LIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overblijvend te leven"])},
    "START_STUDY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum van de financiële analyseperiode"])},
    "END_STUDY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einddatum van de financiële analyseperiode"])},
    "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijks saldo"])},
    "TOP_JOINT_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Gezamenlijke Rekening"])},
    "TOP_RECOMMENDED_DATE_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanbevolen incassodatum"])},
    "TOP_BANKING_SUPERVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banktoezicht"])},
    "GLOBAL_TRANSACTIONS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal transacties"])},
    "MOVEMENT_SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht van bewegingen"])},
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
    "ACCOUNT_STATEMENT": {
      "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekeningoverzicht bekijken"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTA(S) EXTRACTO(S)"])},
      "ACCOUNT": {
        "CHECKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BETAALREKENING"])},
        "SAVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPAARREKENING"])}
      },
      "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag"])},
      "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lopende transactie"])},
      "NO_DATA_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gegevens gevonden, start een nieuwe zoekopdracht"])},
      "PRIVACY_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om redenen van veiligheid en gegevensbescherming is het OpenBanking rekeningoverzicht na een bepaalde periode niet meer beschikbaar."])},
      "CATEGORY": {
        "INCOME": {
          "ADVERTISINGPAYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertentiebetaling"])},
          "ALLOWANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toelage"])},
          "ALLOWANCE:FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familietoelage"])},
          "ALLOWANCE:UNEMPLOYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkloosheidstoeslag"])},
          "ALLOWANCE:OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Toelage"])},
          "AUDIOVISUALSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audiovisueel Abonnement"])},
          "AUTOMOBILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autokosten"])},
          "AUTOMOBILE:MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-onderhoud"])},
          "AUTOMOBILE:PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Parkeren"])},
          "AUTOTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Overdracht"])},
          "BANKINGFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankkosten"])},
          "BANKINGFEE:CONTRACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten met betrekking tot het bankcontract"])},
          "BANKINGFEE:USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten met betrekking tot het gebruik van een bankproduct"])},
          "CASHCOLLECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldcollectie"])},
          "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheque"])},
          "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krediet"])},
          "CREDIT:CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumentenkrediet"])},
          "CREDIT:CONSUMER:SPLIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesplitste consumentenkrediet"])},
          "CREDIT:HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypotheekkrediet"])},
          "CRYPTOCURRENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cryptocurrency"])},
          "CULTUREEVENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cultureel Evenement"])},
          "DATAPURCHASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegevensaankoop"])},
          "DEPOSIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storting"])},
          "DONATIONANDCHARITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donatie en Liefdadigheid"])},
          "EDUCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educatie"])},
          "EDUCATION:DRIVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rijopleiding"])},
          "EDUCATION:SCHOOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schoolonderwijs"])},
          "FINEFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boetekosten"])},
          "FITNESSSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitnessabonnement"])},
          "FOODCONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voedselconsumptie"])},
          "FOODCONSUMPTION:DELIVERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voedselconsumptie via bezorging"])},
          "FRAUDULENTTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frauduleuze Overdracht"])},
          "GAMBLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gokken"])},
          "GROCERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kruidenierswaren"])},
          "GROCERY:DRIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boodschappen via drive-in"])},
          "HEALTHWELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gezondheid en Welzijn"])},
          "HEALTHWELLNESS:BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten voor lichaamsverzorging"])},
          "HEALTHWELLNESS:CONSULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten voor medische consulten"])},
          "HEALTHWELLNESS:SHOP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten voor medische aankopen"])},
          "HOSTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosting"])},
          "INDEMNITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schadeloosstelling"])},
          "INSURANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzekering"])},
          "INTEREST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rente"])},
          "INTERNATIONALTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internationale Overdracht"])},
          "INTERVENTIONFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interventiekosten"])},
          "INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investering"])},
          "LEGALFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridische Kosten"])},
          "LEISURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrije Tijd"])},
          "PENSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensioen"])},
          "PETCARE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huisdierenverzorging"])},
          "READINGSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leesabonnement"])},
          "REIMBURSEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergoeding"])},
          "REJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afwijzen"])},
          "REJECT:CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krediet Afwijzen"])},
          "REJECT:OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Afwijzing"])},
          "RENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huur"])},
          "RENT:CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumentenhuur"])},
          "RENT:HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huurwoning"])},
          "SALARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaris"])},
          "SALARY:REGULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regulier Salaris"])},
          "SALARY:TEMPORARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdelijk Salaris"])},
          "SALARY:ADVANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorschot op salaris"])},
          "SALARY:BONUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonussen en incentives"])},
          "SALARY:FEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onkostennota's"])},
          "SAVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sparen"])},
          "SECURITYSERVICESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beveiligingsserviceabonnement"])},
          "SEIZUREFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbeslagnamekosten"])},
          "SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
          "SHOPPING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winkelen"])},
          "SHOPPING:TOBACCOBAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aankopen in bars en tabakszaken"])},
          "SOCIALFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten met betrekking tot sociale lasten"])},
          "SOFTWARESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Softwareabonnement"])},
          "STOCKMARKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectenbeurs"])},
          "SYNDICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syndicaat"])},
          "TAXE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belasting"])},
          "TELECOMSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telecomabonnement"])},
          "TELECOMSUB:BOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten voor telecommunicatie-abonnementen voor internet"])},
          "TELECOMSUB:PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten voor telecommunicatie-abonnementen voor telefonie"])},
          "TRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdracht"])},
          "TRANSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervoer"])},
          "TRANSPORT:ENERGY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten voor brandstofvoorziening"])},
          "TRANSPORT:MOBILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiliteitskosten voor transport"])},
          "TRANSPORT:PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkeerkosten"])},
          "TRANSPORT:TOLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tolheffingen"])},
          "UNPAID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbetaald"])},
          "UTILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienst"])},
          "UTILITY:ENERGY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energie Dienst"])},
          "UTILITY:WATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water Dienst"])},
          "UTILITY:OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Dienst"])},
          "VIDEOGAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videospel"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])}
        },
        "OUTCOME": {
          "ADVERTISINGPAYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertentiebetaling"])},
          "ALLOWANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toelage"])},
          "ALLOWANCE:FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familietoelage"])},
          "ALLOWANCE:UNEMPLOYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkloosheidstoeslag"])},
          "ALLOWANCE:OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Toelage"])},
          "AUDIOVISUALSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audiovisueel Abonnement"])},
          "AUTOMOBILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autokosten"])},
          "AUTOMOBILE:MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-onderhoud"])},
          "AUTOMOBILE:PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Parkeren"])},
          "AUTOTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Overdracht"])},
          "BANKINGFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankkosten"])},
          "BANKINGFEE:CONTRACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten met betrekking tot het bankcontract"])},
          "BANKINGFEE:USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten met betrekking tot het gebruik van een bankproduct"])},
          "CASHCOLLECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldcollectie"])},
          "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheque"])},
          "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krediet"])},
          "CREDIT:CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumentenkrediet"])},
          "CREDIT:CONSUMER:SPLIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesplitste consumentenkrediet"])},
          "CREDIT:HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypotheekkrediet"])},
          "CRYPTOCURRENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cryptocurrency"])},
          "CULTUREEVENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cultureel Evenement"])},
          "DATAPURCHASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegevensaankoop"])},
          "DONATIONANDCHARITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donatie en Liefdadigheid"])},
          "EDUCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educatie"])},
          "EDUCATION:DRIVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rijopleiding"])},
          "EDUCATION:SCHOOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schoolonderwijs"])},
          "FINEFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boetekosten"])},
          "FITNESSSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitnessabonnement"])},
          "FOODCONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voedselconsumptie"])},
          "FOODCONSUMPTION:DELIVERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voedselconsumptie via bezorging"])},
          "FRAUDULENTTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frauduleuze Overdracht"])},
          "GAMBLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gokken"])},
          "GROCERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kruidenierswaren"])},
          "GROCERY:DRIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boodschappen via drive-in"])},
          "HEALTHWELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gezondheid en Welzijn"])},
          "HEALTHWELLNESS:BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten voor lichaamsverzorging"])},
          "HEALTHWELLNESS:CONSULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten voor medische consulten"])},
          "HEALTHWELLNESS:SHOP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten voor medische aankopen"])},
          "HOSTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosting"])},
          "INDEMNITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schadeloosstelling"])},
          "INSURANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzekering"])},
          "INTEREST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rente"])},
          "INTERNATIONALTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internationale Overdracht"])},
          "INTERVENTIONFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interventiekosten"])},
          "INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investering"])},
          "LEGALFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridische Kosten"])},
          "LEISURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrije Tijd"])},
          "PENSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensioen"])},
          "PETCARE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huisdierenverzorging"])},
          "READINGSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leesabonnement"])},
          "REIMBURSEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergoeding"])},
          "REJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afwijzen"])},
          "REJECT:CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krediet Afwijzen"])},
          "REJECT:OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Afwijzing"])},
          "RENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huur"])},
          "RENT:CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumentenhuur"])},
          "RENT:HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huurwoning"])},
          "SALARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaris"])},
          "SALARY:REGULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regulier Salaris"])},
          "SALARY:TEMPORARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdelijk Salaris"])},
          "SALARY:ADVANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorschot op salaris"])},
          "SALARY:BONUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonussen en incentives"])},
          "SALARY:FEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onkostennota's"])},
          "SAVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sparen"])},
          "SECURITYSERVICESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beveiligingsserviceabonnement"])},
          "SEIZUREFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbeslagnamekosten"])},
          "SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
          "SHOPPING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winkelen"])},
          "SHOPPING:TOBACCOBAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aankopen in bars en tabakszaken"])},
          "SOCIALFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten met betrekking tot sociale lasten"])},
          "SOFTWARESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Softwareabonnement"])},
          "STOCKMARKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectenbeurs"])},
          "SYNDICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syndicaat"])},
          "TAXE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belasting"])},
          "TELECOMSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telecomabonnement"])},
          "TELECOMSUB:BOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten voor telecommunicatie-abonnementen voor internet"])},
          "TELECOMSUB:PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten voor telecommunicatie-abonnementen voor telefonie"])},
          "TRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdracht"])},
          "TRANSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervoer"])},
          "TRANSPORT:ENERGY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten voor brandstofvoorziening"])},
          "TRANSPORT:MOBILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiliteitskosten voor transport"])},
          "TRANSPORT:PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkeerkosten"])},
          "TRANSPORT:TOLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tolheffingen"])},
          "UNPAID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbetaald"])},
          "UTILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienst"])},
          "UTILITY:ENERGY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energie Dienst"])},
          "UTILITY:WATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water Dienst"])},
          "UTILITY:OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Dienst"])},
          "VIDEOGAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videospel"])},
          "WITHDRAWAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prelievo"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])}
        }
      }
    },
    "RECOMMENDATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanbevelingen"])},
    "APPROVALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goedkeuringen"])},
    "INDEBTEDNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schulden"])},
    "HABITAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woonomgeving"])},
    "EXISTING_CREDIT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse bestaande leningen"])},
    "ALERTS": {
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "CAUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oorzaak"])},
      "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag"])},
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen waarschuwingen gedetecteerd"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarschuwingen"])}
    },
    "SERVICES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diensten"])},
    "WATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["water"])},
    "GAS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gas"])},
    "ELECTRICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elektriciteit"])},
    "BANK_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankkaarten"])},
    "DEFERRED_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detectie van uitgestelde afschrijvingen"])},
    "GLOBAL_TRANSACTION_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal aantal transacties"])},
    "MONTH_NEGATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatief saldo in de loop van de maand"])},
    "MAIN_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdrekening"])},
    "NO_TRANSACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn geen inkomstransacties gedetecteerd."])},
    "ALERT": {
      "ABSENCE_OF_INCOME": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afwezigheid van inkomsten"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn geen inkomstransacties gedetecteerd"])}
      },
      "PRESENCE_OF_REJECTS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheid van afgewezen transacties"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is ten minste 1 transactie met afgewezen betalingen gedetecteerd"])}
      },
      "PRESENCE_OF_UNPAIDS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheid van onbetaalde transacties"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is ten minste 1 transactie met onbetaalde betalingen gedetecteerd"])}
      },
      "PRESENCE_OF_PENSIONS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheid van pensioenen"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is ten minste 1 transactie met pensioenbetalingen gedetecteerd"])}
      },
      "PRESENCE_OF_GAMBLINGS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheid van goktransacties"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minstens 10% van de totale uitgaven wordt gebruikt voor gokken"])}
      },
      "PRESENCE_OF_ALLOWANCES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheid van toelagen"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is ten minste 1 transactie met toelagen gedetecteerd"])}
      },
      "PRESENCE_OF_SEIZURE_FEES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheid van beslagleggingskosten"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is ten minste 1 transactie met beslagleggingskosten gedetecteerd"])}
      },
      "MONTH_NEGATIVE_BALANCE": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatief saldo van de maand"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We hebben 1 of meer dagen met een negatief saldo gedetecteerd"])}
      },
      "BANK_WITH_EXTREME_RISK_LEVEL": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank met een extreem risiconiveau"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We hebben 1 of meer rekeningen bij een bank met een extreem risiconiveau gedetecteerd"])}
      },
      "BANK_WITH_HIGH_RISK_LEVEL": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank met een hoog risiconiveau"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We hebben 1 of meer rekeningen bij een bank met een hoog risiconiveau gedetecteerd"])}
      },
      "TRANSACTION_HISTORY_DATE": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactiehistoriedatum"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is minder dan 2 maanden aan transactiegeschiedenis verstrekt voor deze analyse"])}
      },
      "TRANSACTION_HISTORY_DATE_ONE_MONTH": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum transactiegeschiedenis"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minder dan 1 maand aan transactiegeschiedenis is verzonden voor deze analyse"])}
      },
      "PRESENCE_OF_CASH_COLLECTIONS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheid van incassobureaus"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minstens 1 transactie met een incassobureau gedetecteerd"])}
      },
      "PRESENCE_OF_CRYPTOCURRENCIES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheid van cryptocurrencies"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minstens 10% van de totale uitgaven wordt gebruikt voor cryptocurrencies"])}
      },
      "TRANSACTION_HISTORY_COUNT": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal transacties in de transactiegeschiedenis"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn minder dan 10 transacties verstrekt voor deze analyse"])}
      },
      "PRESENCE_OF_CREDITS_PER_MONTH": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheid van leningen per maand"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn ten minste 3 krediettransacties per maand gedetecteerd"])}
      },
      "PRESENCE_OF_INTERVENTION_FEES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheid van interventiekosten"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is ten minste 1 transactie met interventiekosten gedetecteerd"])}
      },
      "PRESENCE_OF_WITHDRAWALS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheid van opnames per maand"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minstens 25% van de totale uitgaven wordt gebruikt voor opnames"])}
      },
      "PRESENCE_OF_INTERNATIONALS_TRANSFERS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheid van internationale overschrijvingen"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is ten minste 1 transactie met internationale overschrijvingen gedetecteerd"])}
      },
      "TRANSACTION_HISTORY_FOR_ANALYSIS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactiegeschiedenis voor analyse"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het aantal verstrekte transacties is onvoldoende voor tijdige analyse."])}
      },
      "ABSENCE_OF_TAXE": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afwezigheid van belasting"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen belastingtransactie is gedetecteerd"])}
      },
      "LOSS_OF_SALARY": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlies van salaris"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen salarisbetaling gedetecteerd"])}
      },
      "ABSENCE_OF_SOCIAL_FEES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afwezigheid van sociale bijdragen"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn geen transacties met sociale bijdragen gedetecteerd"])}
      },
      "PRESENCE_OF_AUTOTRANSFERS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheid van automatische overboekingen"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minstens 50% van de totale inkomsten of uitgaven wordt gebruikt voor automatische overboekingen"])}
      },
      "PRESENCE_OF_UNEMPLOYMENT_ALLOWANCES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheid van werkloosheidsuitkeringen"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is minstens één transactie met werkloosheidsuitkeringen gedetecteerd"])}
      },
      "ACCOUNT_WITH_BANKING_SUPERVISION": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekening met banktoezicht"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We hebben één of meer rekeningen met banktoezicht gedetecteerd"])}
      },
      "ACCOUNT_NEWLY_CREATED": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account nieuw aangemaakt"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We hebben één of meer nieuw aangemaakte accounts gedetecteerd"])}
      },
      "PRESENCE_OF_FRAUDULENT_TRANSFERS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheid van frauduleuze overboekingen"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is minstens 1 transactie met frauduleuze overboekingen gedetecteerd"])}
      }
    },
    "NET_INCOME_AND_ANNUAL_TURNOVER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netto-inkomen en jaaromzet"])},
    "TAXES_AND_PAYROLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingen & Loonsom"])},
    "DAILY_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dagelijks saldo"])},
    "MONTHLY_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijks saldo"])},
    "DAILY_AND_MONTHLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dagelijks en maandelijks balans"])},
    "WRONG_ANALYSIS_B2C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op, de analyse is uitgevoerd op een persoonlijk account en niet op een zakelijk account"])},
    "WRONG_ANALYSIS_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op, de analyse is uitgevoerd op een zakelijk account en niet op een persoonlijk account"])},
    "PROVIDER_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden tijdens het gegevensherstel. Start het bestand opnieuw."])},
    "UNDEFINED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niet gedefinieerd"])},
    "AVERAGE_NUMBER_OF_TRANSACTIONS_BY_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld aantal transacties / maand"])},
    "MONTHLY_AVERAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijks gemiddelde"])},
    "SUM_OVER_THE_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Som over de periode"])},
    "ANNUAL_ESTIMATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaarlijkse schatting"])},
    "SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salarissen"])},
    "ALLOWANCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toelagen"])},
    "PENSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensioenen"])},
    "TOTAL_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal inkomsten"])},
    "RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huren"])},
    "LOANS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leningen"])},
    "TOTAL_OUTCOMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal uitgaven"])},
    "INSTITUTION_ASSOCIATED_WITH_LOAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instelling(en) gekoppeld aan een krediet"])},
    "REVENUE_INCL_VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omzet inclusief BTW"])},
    "OUTGOING_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgaande salarissen"])},
    "OUTGOING_SOCIAL_CHARGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgaande sociale lasten"])},
    "OTHER_EXPENSES_INCL_VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overige kosten incl. BTW"])},
    "CREDITS_INCL_VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredieten incl. BTW"])},
    "TAXES_INCL_VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingen incl. BTW"])},
    "INTRA_COMPANY_TRANSFERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne bedrijfsstromen (zelfoverboekingen)"])}
  },
  "SOCIAL_FACE_MATCHING": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeeldingen zoeken"])},
    "CATCHED_IMAGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gevonden afbeeldingen"])},
    "NO_IMAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen afbeelding gevonden"])}
  },
  "AMLCFT": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestrijding van witwassen en terrorismefinanciering"])},
    "FREEZING_ASSETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevriezing van activa"])},
    "PPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politiek prominente persoon"])},
    "PPE_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze persoon wordt geïdentificeerd als politiek prominent"])},
    "ADVERSE_MEDIA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
    "ADVERSE_MEDIA_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn meerdere nieuwsartikelen over de aanvrager."])},
    "ADVERSE_MEDIA_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soort media"])},
    "COLUMN_TABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCBFT"])},
    "SANCTIONED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onder sancties"])},
    "SANCTIONED_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze persoon wordt geïdentificeerd als gesanctioneerd."])},
    "SANCTIONED_EXPLANATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit kan betrekking hebben op het bevriezen van activa, betrokkenheid bij terrorisme en/of witwassen."])},
    "CLASS_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staatsleiders, leden van de nationale uitvoerende macht, leden van nationale wetgevende lichamen, hoge functionarissen van het leger en de wetshandhaving, hoge functionarissen van nationale agentschappen, leden van de raden van bestuur van centrale banken, hoge functionarissen in de rechterlijke macht (nationaal niveau), topfunctionarissen van politieke partijen"])},
    "CLASS_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leden van de raden van bestuur van internationale organisaties en internationale sportfederaties, ambassadeurs, hoge commissarissen en andere hoge diplomatieke functies, leden van de regionale uitvoerende macht, leden van regionale wetgevende lichamen, hoge functionarissen in de rechterlijke macht (regionaal niveau)"])},
    "CLASS_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemeen management en raad van bestuur van bedrijven en openbare organisaties"])},
    "CLASS_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burgemeesters en leden van lokale overheden (subregionaal niveau)"])},
    "LEVEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "ADVERSE_MEDIA_DETAILS": {
      "financial-crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiële criminaliteit"])},
      "violent-crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewelddadige misdaad"])},
      "sexual-crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seksuele misdaad"])},
      "terrorism": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terrorisme"])},
      "fraud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraude"])},
      "narcotics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Narcotica"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemeen"])},
      "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigendom"])},
      "financial-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Financieel"])},
      "fraud-linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekoppeld aan fraude"])},
      "narcotics-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Narcotica"])},
      "violence-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Geweld"])},
      "cybercrime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cybercriminaliteit"])},
      "general-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Algemeen"])},
      "regulatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regelgevend"])},
      "financial-difficulty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiële moeilijkheden"])},
      "violence-non-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet AML CFT Geweld"])},
      "other-financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere financiële"])},
      "other-serious": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere ernstige"])},
      "other-minor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere minderjarige"])}
    },
    "LINKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
    "NO_RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze persoon wordt niet geïdentificeerd als gesanctioneerd of politiek prominent."])}
  },
  "TOOLTIP": {
    "CURRENT_PROCEDURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf met lopende juridische procedures."])},
    "MOTHBALLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactief gemaakte en opnieuw geactiveerde onderneming."])},
    "MOBILE_PHONE_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificatie van mobiele telefoon."])},
    "SOCIAL_FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeeldingen gevonden op Google in verband met de ingevoerde informatie."])},
    "EMAIL_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailverificatie."])},
    "AVAILABLES_PICTURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeeldingen beschikbaar op sociale media in verhouding tot de aanvragergegevens."])},
    "NUMBER_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal vestigingen dat eigendom is van het bedrijf"])},
    "FREEZING_ASSETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, als er momenteel activa zijn bevroren."])},
    "SCORE_WEB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraudescore voor het bestaan en de betrouwbaarheid van contactgegevens van de aanvrager."])},
    "SCORE_SECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risicoscore voor financiële analyse en solvabiliteit van het bedrijf."])},
    "DEFAULT_PROBABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maatstaf voor het risico op wanbetaling van de entiteit."])},
    "SCORE_LAST_UPDATE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores bijgewerkt op"])},
    "SCORE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De score evalueert de financiële gezondheid van het bedrijf op basis van de bedrijfstak en financiële overzichten."])},
    "SCORE_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risicoscore voor de financiële analyse van de bankrekening."])},
    "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld maandelijks saldo gedurende de bestudeerde periode."])},
    "EMAIL_DISPOSABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wegwerpemail"])},
    "EMAIL_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riskante e-mail"])},
    "MOBILE_PHONE_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekoppeld telefoonnummer"])},
    "EMAIL_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekoppelde e-mail"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doorgaan met het invoeren van het dossier"])},
    "VIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier bekijken"])},
    "DUPLICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dubbele map"])},
    "DELETE_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map verwijderen"])},
    "BLOCK_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het is onmogelijk om een afgerond dossier te heropenen."])}
  },
  "EMAIL_DISPOSABLE": {
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het verstrekte e-mailadres is gekoppeld aan een wegwerp domein."])}
  },
  "DOCUMENT_CONTROL": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenten controleren"])},
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een documenttype"])},
    "ID_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identiteitskaart"])},
    "ID_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identiteitskaart"])},
    "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identiteitskaart"])},
    "DRIVING_LICENSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rijbewijs"])},
    "PASSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paspoort"])},
    "RESIDENCE_PERMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verblijfsvergunning"])},
    "KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis - Bedrijf of situatie"])},
    "INDIVIDUAL_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis - Individueel"])},
    "COMPANY_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis - Bedrijf"])},
    "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticiteit - Analyse van digitale niet-verandering"])},
    "AUTHENTICITY_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticiteitsdetails"])},
    "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingaangifte"])},
    "TAX_REPORT_S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eenvoudige belastingaangifte"])},
    "TAX_REPORT_C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gecombineerde belastingaangifte"])},
    "TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingaanslag - Inkomsten of onroerend goed"])},
    "INCOME_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkomstenbelasting"])},
    "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ander type"])},
    "PROPERTY_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onroerendezaakbelasting"])},
    "BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankgegevens"])},
    "BANK_STATEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankafschrift"])},
    "BLOC_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentcontrole"])},
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het controleren van het document is mislukt"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve het uploaden van uw document in het linker venster te voltooien om de documentanalyse te kunnen bekijken."])},
    "PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loonstrook"])},
    "PAYSLIP_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loonstrook"])},
    "RIB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIB"])},
    "KBIS_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS"])},
    "TAX_NOTICE_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingaanslag"])},
    "OTHER_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ander document"])},
    "NEW_DOCUMENT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseer een nieuw document"])},
    "NEW_FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biometrische controle"])},
    "FACE_MATCHING_DESC_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilt u een nieuwe identiteitsanalyse uitvoeren met biometrische controle?"])},
    "FACE_MATCHING_DESC_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer alstublieft het telefoonnummer van de klant in, zodat hij via sms de link kan ontvangen om zijn analyse uit te voeren."])},
    "ID_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identiteitsanalyse"])},
    "ID_ANALYSIS_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kunt u een documentanalyse starten voor identiteitsdocumenten zoals nationale identiteitskaarten, paspoorten, verblijfsvergunningen en rijbewijzen."])},
    "DOCUMENT_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentanalyse"])},
    "DOCUMENT_ANALYSIS_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kunt u een documentanalyse starten voor een belastingaangifte, KBIS, loonstrook, belastingaanslag of elk ander PDF-document."])},
    "SELECT_DOCUMENT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een of meer soorten documenten"])},
    "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbekend"])},
    "UNKNOWN_DOC_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het door u doorgegeven document kan niet als identiteitsbewijs worden geïdentificeerd. Om dit te controleren, vergeet niet om de voor- en achterkant van het document te uploaden of het documentanalysevenster te gebruiken."])},
    "NO_DOCUMENT_UPLOADED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen document geüpload."])},
    "FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biometrische controle"])},
    "SELFIE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfie"])},
    "TABLE": {
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
      "COMPLIANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naleving"])},
      "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticiteit"])},
      "ID_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe identiteitsanalyse"])},
      "DOCUMENT_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe documentanalyse"])},
      "RESULT": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details van de analyse"])},
        "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controle"])},
        "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultaten"])},
        "WAITING_CONFIRMATION_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse wacht op uw bevestiging"])},
        "IN_PROGRESS_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse in behandeling"])},
        "LIVE_CHECK": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativação da verificação de dúvidas"])},
          "DESC_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você está prestes a ativar a verificação de dúvidas do documento <", _interpolate(_named("documentName")), ">."])},
          "DESC_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A verificação humana está disponível apenas durante os primeiros 10 minutos após o upload do documento."])},
          "DESC_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O processamento da análise pode levar algum tempo; você receberá uma notificação assim que a análise for concluída."])},
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Verificação de dúvidas permite solicitar uma verificação manual do documento por um operador humano em menos de quinze minutos. Atenção, está disponível apenas durante os 10 minutos após o upload do documento."])},
          "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação de dúvidas em andamento"])},
          "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação de dúvidas disponível"])},
          "IN_PROGRESS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação de dúvidas em andamento."])},
          "IN_PROGRESS_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os controles abaixo serão atualizados assim que a verificação manual for concluída. Por favor, aguarde."])},
          "FAILED_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise da verificação de dúvidas do documento não disponível."])},
          "FAILED_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocorreu um erro técnico ao acionar a análise da verificação de dúvidas do documento."])},
          "SUCCESS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise da verificação de dúvidas do documento concluída."])},
          "ANALYSIS_RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado da análise"])},
          "ANALYST_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensagem do analista"])}
        },
        "CHECKS": {
          "EMISSION_COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistentie nationaliteit van het document"])},
          "EMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistentie documenttype / uitgiftedatum"])},
          "EXPIRATION_DATE_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldigheid van de vervaldatum"])},
          "VALIDITY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document is geldig"])},
          "MRZ_FIELDS_SYNTAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRZ-controle"])},
          "MRZ_CHECKSUMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRZ-controle"])},
          "MRZ_EXPECTED_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRZ-controle"])},
          "MRZ_ALIGNEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRZ-controle"])},
          "MRZ_CLASSIFIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRZ-controle"])},
          "FIRST_NAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistentie persoonsidentiteit / MRZ"])},
          "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistentie persoonsidentiteit / MRZ"])},
          "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistentie persoonsidentiteit / MRZ"])},
          "DOCUMENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentnummer in overeenstemming met MRZ"])},
          "EXPIRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervaldatum in overeenstemming met MRZ"])},
          "OCR_EMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgiftedatum in overeenstemming met MRZ"])},
          "PERSONAL_NUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoonlijk identificatienummer in overeenstemming met MRZ"])},
          "PHOTO_CONFORMITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotoanalyse"])},
          "PHOTO_LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotoanalyse"])},
          "VISUAL_SECURITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naleving van documentbeveiligingspunten"])},
          "PHOTOCOPY_DETECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detectie van kopie"])},
          "SCREENSHOT_DETECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detectie van schermafbeelding"])},
          "OLD_DRIVING_LICENSE_DOCUMENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud rijbewijsdocumentnummer"])},
          "DRIVING_LICENSE_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldigheid van het rijbewijs"])},
          "DRIVING_LICENSE_DIGITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldigheid van het rijbewijs"])},
          "AGE_AT_CERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leeftijd bij verkrijging consistent"])},
          "MRZ_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRZ-controle"])},
          "MRZ_IDENTITY_COHERENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistentie persoonsidentiteit"])},
          "PICTURE_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotoanalyse"])},
          "BARCODE_EXPECTED_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barcode gevonden"])},
          "BARCODE_FIELDS_SYNTAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correct gecodeerde gegevens van de barcode"])},
          "BARCODE_SIGNATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handtekening van de barcode geldig"])},
          "TYPE_RECOGNIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het documenttype is herkend"])},
          "NOT_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervaldatum"])},
          "MRZ_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De machineleesbare zone (MRZ) van het document is geldig"])},
          "VALIDITY_PERIOD_MODEL_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het geldigheidsperiode-model komt overeen"])},
          "OCR_ANALYSIS_MRZ_FIRST_NAMES_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De voornamen in de MRZ komen overeen met de OCR-analyse"])},
          "OCR_ANALYSIS_MRZ_DOCUMENT_NUMBER_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het documentnummer in de MRZ komt overeen met de OCR-analyse"])},
          "DOCUMENT_ASPECT_GENERAL_APPEARANCE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het algemene uiterlijk van het document komt overeen met de verwachtingen"])},
          "SECURITY_ELEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beveiligingselementen aanwezig in het document"])},
          "QUALITY_ABOVE_MINIMUM_THRESHOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De kwaliteit van het document is boven de minimumdrempel"])},
          "NOT_SPECIMEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het document is geen specimen"])},
          "FRONT_SIDE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De voorkant van het document is gevonden"])},
          "OCR_ANALYSIS_MRZ_BIRTH_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De geboortenaam in de MRZ komt overeen met de OCR-analyse"])},
          "OCR_ANALYSIS_MRZ_BIRTH_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De geboortedatum in de MRZ komt overeen met de OCR-analyse"])},
          "OCR_ANALYSIS_FAITHFUL_FONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De OCR-analyse geeft aan dat er een betrouwbaar lettertype wordt gebruikt in het document"])},
          "DOCUMENT_ASPECT_PICTURE_PRESENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een afbeelding aanwezig op het document"])},
          "OCR_ANALYSIS_EXPIRATION_DATE_READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De vervaldatum is succesvol gelezen via OCR-analyse"])},
          "OCR_ANALYSIS_ISSUANCE_DATE_READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De uitgiftedatum is succesvol gelezen via OCR-analyse"])},
          "ISSUANCE_AND_EXPIRATION_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De uitgiftedatum en vervaldatum komen overeen"])},
          "NO_TAMPERING_SIGN_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen teken van knoeien werd gevonden op het document"])},
          "NO_ANNOTATION_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen annotatie werd gevonden op het document"])},
          "BACK_SIDE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De achterkant van het document is gevonden"])},
          "OCR_ANALYSIS_MRZ_EXPIRATION_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De vervaldatum in de MRZ komt overeen met de OCR-analyse"])},
          "OCR_ANALYSIS_MRZ_ISSUANCE_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De uitgiftedatum in de MRZ komt overeen met de OCR-analyse"])},
          "OCR_ANALYSIS_MRZ_LAST_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De achternaam in de MRZ komt overeen met de OCR-analyse"])},
          "DOC_2D_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2D-doc geldig"])},
          "DOC_2D_AND_DOCUMENT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2D-doc overeenkomstig met documentinformatie"])},
          "WORKABLE_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De opname kan worden verwerkt voor verificatie"])},
          "WORKABLE_ELEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het element kan worden verwerkt voor verificatie"])},
          "STATUS_ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geaccepteerd dossier"])},
          "FRAUD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frauduleus document"])},
          "DOCUMENT_INSUFFICIENT_QUALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De kwaliteit van de documentopname staat geen menselijke controle toe"])},
          "DOCUMENT_INCORRECTLY_FRAMED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het document is niet correct ingekaderd in de afbeelding"])},
          "DOCUMENT_SIDE_MISSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slechts één zijde van het document is zichtbaar (zowel voor- als achterkant vereist)"])},
          "DOCUMENT_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document verlopen"])},
          "DOCUMENT_UNSUPPORTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenttype wordt niet ondersteund"])},
          "DOCUMENT_COUNTRY_UNAUTHORIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land van uitgifte van het document wordt niet ondersteund"])},
          "DOCUMENT_UNSUPPORTED_RECEIPT_ACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenttype wordt niet ondersteund"])},
          "BIOMETRY_INSUFFICIENT_QUALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De kwaliteit van de biometrische gegevens staat geen menselijke controle toe"])},
          "LIVE_CHECK_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technische fout opgetreden bij de provider"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere reden, zie details hieronder"])},
          "ID_PHOTO_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistentie tussen de selfie van de gebruiker en het document"])},
          "PASSIVE_LIVENESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorwaarden voor het maken van de selfie nageleefd"])},
          "IS_ALIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegingen gedetecteerd tijdens het maken van de selfie"])},
          "SEQUENCE_CONSISTENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistente bewegingen tijdens de uitvoering"])},
          "ORIGINAL_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origineel document gepresenteerd"])},
          "PICTURE_NOT_TAMPERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongemodificeerd identiteitsfoto"])},
          "DOCUMENT_NUMBER_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldigheid van het documentnummer"])},
          "MULTI_REQUESTS": {
            "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het document is niet onderworpen aan meerdere aanvragen"])},
            "KO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het document is meerdere keren ingediend (meerdere aanvragen)"])},
            "WARNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het document is meerdere keren ingediend (meerdere aanvragen)"])}
          },
          "MULTI_REQUESTS_INCONSISTENT": {
            "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het document bevat geen inconsistente gegevens met betrekking tot meerdere aanvragen"])},
            "KO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het document is meerdere keren ingediend met verschillende gegevens"])},
            "WARNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het document is meerdere keren ingediend met verschillende gegevens"])}
          },
          "MULTI_REQUESTS_FRAUD": {
            "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het document is niet gedetecteerd als fraude met betrekking tot meerdere aanvragen"])},
            "KO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het document is meerdere keren ingediend en als fraude gedetecteerd"])},
            "WARNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het document is meerdere keren ingediend en als fraude gedetecteerd"])}
          }
        },
        "KBIS": {
          "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificatienummer"])},
          "SIRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET"])},
          "REGISTRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registratiedatum"])},
          "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsnaam"])},
          "HEAD_QUARTER_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres hoofdkantoor"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
          "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activiteit"])},
          "PUBLICATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicatiedatum KBIS"])},
          "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identiteit van het lid"])},
          "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboortedatum"])},
          "IDENTIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificatie"])},
          "MAIN_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belangrijkste activiteit"])},
          "MEMBERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leden"])},
          "NATURAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natuurlijke persoon(en)"])},
          "LEGAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridische persoon(en)"])},
          "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positie"])},
          "BIRTH_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboortenaam"])},
          "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
          "FIRST_NAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
          "LEGAL_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridische vorm"])},
          "COMPANY_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsbeschrijving"])},
          "ESTABLISHMENT_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving van de vestiging"])}
        },
        "TAX_REPORT": {
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsinformatie"])},
          "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificatienummer"])},
          "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afsluitingsdatum van het boekjaar"])},
          "OPENING_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openingdatum van het boekjaar"])},
          "CONFIDENTIALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrouwelijk document"])},
          "FISCAL_PERIOD_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duur van de fiscale periode"])},
          "RATIOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiële ratio's"])},
          "MONTH": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " maand"]), _normalize([_interpolate(_named("n")), " maanden"])])}
        },
        "TAX_NOTICE": {
          "TAX_ADRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiscaal adres"])},
          "BANKID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankidentificatie"])},
          "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekeningnummer"])},
          "REMAINING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resterend saldo"])},
          "BIRTH_LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboortenaam"])},
          "NUMBER_PARTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal delen"])},
          "FAMILY_SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gezinssituatie"])},
          "CHECKS": {
            "FOUND_2D_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2D-DOC geïdentificeerd"])},
            "NAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naamconsistentie"])},
            "ASSESSMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum van oprichting consistentie"])},
            "REFERENCE_TAX_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkomen referentie consistentie"])},
            "TAX_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiscaal nummer consistentie"])},
            "REFERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referentie consistentie"])},
            "REFERENCE_YEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaar van referentie consistentie"])},
            "NUMBER_OF_PARTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal delen consistentie"])},
            "NUMBER_OF_REGISTRANTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal aangevers consistentie"])}
          }
        },
        "AUTHENTICITY": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticiteit"])},
          "DETAILED_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedetailleerde analyse"])},
          "NO_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn geen waarschuwingen uit de documentanalyse gekomen."])},
          "FAILED_TASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden, gelieve de controle opnieuw te starten."])},
          "BINARY_MODIFICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document gewijzigd sinds de creatie"])},
          "COHERENCE_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het document heeft geen samenhang wat betreft de gegevens die wij verzamelen."])},
          "AUTOGENERATED_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit document is afkomstig van software voor het genereren van valse documenten"])},
          "METADATA_MODIFICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse van metadata onthult een wijziging in het document"])},
          "PIXEL_MODIFICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een deel van de afbeelding is gewijzigd"])},
          "SOFTWARE_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De laatste software is een foto-bewerkingssoftware (zoals Photoshop), die kan worden gebruikt om de afbeelding te wijzigen (zodat deze authentiek lijkt) of om de afbeelding aan te passen."])},
          "REPORT_FINOVOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgebreid Finovox-rapport (PDF)"])},
          "ANALYSIS_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De analyses van authenticiteit kunnen enkele minuten in beslag nemen."])},
          "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden tijdens het OCR-proces. Controleer de bestandsextensie (pdf, jpg, jpeg, png toegestaan) voordat u het opnieuw probeert. Als het probleem aanhoudt, neem dan contact op met de ondersteuning."])}
        },
        "PROPERTY_TAX": {
          "PAYMENT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsinformatie"])},
          "LEGAL_DEBTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wettelijke schuldenaars"])},
          "PROPERTIES_AND_BUILDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigendommen en gebouwen"])},
          "PAYMENT_DEADLINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uiterste betalingsdatum"])},
          "REMAINING_AMOUNT_TO_PAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resterend te betalen bedrag"])},
          "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekening"])},
          "BANK_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankidentificatie"])},
          "CREDITOR_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van de schuldeiser"])},
          "TAX_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingbedrag"])},
          "IDENTIFIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificatie"])},
          "RIGHT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recht"])},
          "DESIGNATION_AND_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benaming en adres"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
          "PAYMENT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsinformatie"])},
          "REMAINING_AMOUNT_TO_REPAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug te betalen restbedrag"])},
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie over belastingaanslag"])},
          "ADDRESSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressen"])},
          "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrant"])},
          "TAX_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiscaal nummer"])},
          "REF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referentie van de aanslag"])},
          "ROLE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolnummer"])},
          "TAX_DEPARTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingdienst"])},
          "OWNER_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenaarsnummer"])},
          "TAX_COMMUNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastinggemeente"])},
          "SERVICE_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienst-ID"])},
          "ISSUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum van uitgifte"])},
          "ASSESSMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum van vaststelling"])}
        },
        "INCOME_TAX": {
          "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie over de belastingaanslag"])},
          "ISSUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgave"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
          "FIP_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIP-nummer"])},
          "SERVICE_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID van de dienst"])},
          "TAX_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiscaal identificatienummer"])},
          "REFERENCE_TAX_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referentie-inkomen fiscaal"])},
          "ASSESSMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeling"])},
          "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangever"])},
          "ROLE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolnummer"])},
          "TAX_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiscaal adres"])},
          "PAYMENT_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsinformatie"])},
          "FAMILY_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gezinsgegevens"])},
          "REMAINING_AMOUNT_TO_PAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resterend te betalen bedrag"])},
          "BIRTH_LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboortenaam"])},
          "NUMBER_OF_PARTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal delen"])},
          "FAMILY_SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gezinssituatie"])},
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie over de belastingaanslag"])},
          "PAYMENT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsinformatie"])},
          "MANDATE_UNIQUE_REFERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unieke mandaatreferentie"])},
          "REMAINING_AMOUNT_TO_REPAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resterend terug te betalen bedrag"])},
          "CREDITOR_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van de schuldeiser"])},
          "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekening"])},
          "BANK_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankidentificatie"])},
          "REF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanslagreferentie"])},
          "OWNER_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenaarsnummer"])},
          "REFERENCE_YEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referentiebelastingjaar"])}
        },
        "BANK_DETAILS": {
          "BANK_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankcode"])},
          "BRANCH_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vestigingscode"])},
          "ACCOUNT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekeningnummer"])},
          "KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIB-sleutel"])},
          "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
          "BIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
          "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van de bank"])},
          "BANK_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres van de bank"])},
          "IBAN_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldigheid van IBAN"])},
          "ACCOUNT_NUMBER_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldigheid van rekeningnummer"])},
          "DOCUMENT_ANALYZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geanalyseerd document"])},
          "DIRECT_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domiciliëring"])},
          "CHECKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controles"])}
        },
        "PAYSLIP": {
          "PAYSLIP_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie over Loonstrook"])},
          "PAYMENT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsinformatie"])},
          "HIRE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum van Aanwerving"])},
          "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
          "SIRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET"])},
          "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
          "NET_PAYABLE_BEFORE_INCOME_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netto Te Betalen Voor Belasting"])},
          "NET_PAYABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netto Te Betalen Na Belasting"])},
          "PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsperiode"])}
        },
        "FACE_MATCHING": {
          "IN_PROGRESS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biometrische controle is in uitvoering."])},
          "IN_PROGRESS_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zodra de analyse door de klant is voltooid, worden de onderstaande controles weergegeven. Gelieve te wachten."])},
          "FAILED_OR_CANCELLED_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biometrische controle niet beschikbaar."])},
          "FAILED_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een technische fout opgetreden tijdens de biometrische controle."])},
          "CANCELLED_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gebruiker heeft de biometrische controle geannuleerd."])}
        }
      }
    },
    "NO_PERSON_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen persoon gevonden"])},
    "ERROR_MESSAGE_API": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een probleem opgetreden"])},
    "FAILED_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een probleem opgetreden bij het analyseren van het document. Probeer het opnieuw."])},
    "SOCIAL_SECURITY_CERTIFICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socialezekerheidscertificaat"])},
    "DISABLED_WORKER_CERTIFICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificaat voor gehandicapte werknemer"])},
    "PROOF_OF_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewijs van adres"])},
    "FINALIZE_MY_DOCUMENT_SUBMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn documentverzending voltooien"])}
  },
  "SCORE": {
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "WEB_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web"])},
    "SECTOR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sector"])},
    "COMPANY_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf"])},
    "DEFAULT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaard"])},
    "REQUEST_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanvraag ID"])},
    "HELPER_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij raden aan om extra controles uit te voeren."])},
    "LOCAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokale score"])},
    "INTERNATIONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internationale score"])}
  },
  "FINANCIAL_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiële analyse van het bedrijf"])},
  "TAX_REPORTS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingaangiften"])},
    "FISCAL_PERIOD_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiscale periode duur"])},
    "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afsluitdatum"])},
    "TURNOVER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omzet"])},
    "NET_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netto inkomen"])},
    "AUTO_FINANCE_CAPACITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigen financieringscapaciteit"])},
    "NET_MARGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netto marge"])},
    "FB_TB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiële structuur"])},
    "FINANCIAL_LIQUIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiële liquiditeit"])},
    "WORKING_CAPITAL_REQUIREMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behoefte aan werkkapitaal"])},
    "WORKING_CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkkapitaal"])},
    "FINANCIAL_SOLDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financieel saldo"])},
    "DEBTS_SHORT_TERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korte termijn schulden / balans"])},
    "DEBTS_MIDDLE_LONG_TERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middellange en lange termijn schulden / balans"])},
    "NOT_AVAILABLE_NEED_UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingaangiften niet beschikbaar. U kunt er een uploaden om de analyse te verkrijgen."])}
  },
  "FINANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiën"])},
  "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschikbaar"])},
  "UNAVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet beschikbaar"])},
  "NO_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet beschikbaar"])},
  "ENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entiteit"])},
  "ENTITIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entiteiten"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee"])},
  "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maand"])},
  "OF_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["van de maand"])},
  "EFFECTIVE_RECIPIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectieve ontvanger"])},
  "EMPLOYEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werknemer"])},
  "REPRESENTATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertegenwoordiger"])},
  "WELCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom"])},
  "LAST_UPDATE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores bijgewerkt op"])},
  "VERIFICATION": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestaand"])},
    "TOOLTIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificatie van het bestaan van de informatie bij de aanvrager"])}
  },
  "RELATED": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voldoende verband"])},
    "TOOLTIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificatie van de associatie van de informatie bij de aanvrager"])}
  },
  "FINISH": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier sluiten"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilt u de samenvatting in PDF-formaat ophalen voordat u de pagina sluit?"])}
  },
  "FUNCTION": {
    "MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemeen directeur"])}
  },
  "SOCIAL_NETWORKS": {
    "MAIL_LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekoppeld e-mailadres"])},
    "PHONE_LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekoppeld telefoonnummer"])},
    "ADDRESS_LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekoppeld adres"])},
    "MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekoppeld aan e-mail"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekoppeld aan telefoon"])},
    "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sociale netwerken"])},
    "INFORMATIONS": {
      "AGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leeftijd"])},
      "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stad"])},
      "STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staat"])},
      "GENDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslacht"])},
      "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
      "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])}
    }
  },
  "TELEPHONE": {
    "LINETYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lijntype"])},
    "TIMEZONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdzone"])},
    "CARRIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidige provider"])},
    "ORIGINAL_CARRIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oorspronkelijke provider"])},
    "GEOLOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geolocatie"])},
    "OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenaar"])},
    "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])}
  },
  "HISTORIC_PAGE": {
    "HISTORIC_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossierhistorie"])},
    "B2B_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsanalyse"])},
    "B2C_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particuliere analyse"])},
    "TODAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vandaag"])},
    "LAST_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste 7 dagen"])},
    "ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles"])}
  },
  "TABLE": {
    "PER_PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per pagina"])},
    "FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
    "SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samenvatting"])},
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende"])},
    "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige"])},
    "MARKET_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markttype"])},
    "JOURNEY_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journey ID"])},
    "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "NUMBER_OF_TAX_NOTICES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal belastingaanslagen"])},
    "NUMBER_OF_TAX_REPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal belastingaangiften"])},
    "NUMBER_OF_ID_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal identiteitsdocumenten"])},
    "NUMBER_OF_PASSPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal paspoorten"])},
    "NUMBER_OF_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal Kbis-documenten"])},
    "NUMBER_OF_BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal bankgegevens"])},
    "NUMBER_OF_DRIVING_LICENSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal rijbewijzen"])},
    "NUMBER_OF_RESIDENCE_PERMITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal verblijfsvergunningen"])},
    "NUMBER_OF_PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal loonstroken"])},
    "HOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uur"])},
    "MAIL_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
    "POSTAL_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postadres"])},
    "TELEPHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "SCORE_SMB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraudescore"])},
    "SCORE_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsscore"])},
    "TAG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
    "STATUS_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status van de map"])},
    "DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
    "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
    "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
    "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsnaam"])},
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificatienummer"])},
    "OPERATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator"])},
    "SYNTHESIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samenvatting"])},
    "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acties"])},
    "FILE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiernummer"])},
    "DATE_AND_TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum en tijd"])},
    "DOCUMENT_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenttype"])},
    "DOCUMENT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentnaam"])}
  },
  "REGEX": {
    "NOT_NULL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag niet leeg zijn."])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het telefoonnummer is niet geldig."])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De e-mail is niet geldig."])},
    "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het formaat moet DD/MM/JJJJ zijn."])},
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moet 9 cijfers bevatten."])},
    "NATIONAL_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moet 10 cijfers bevatten."])},
    "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige datum."])},
    "LETTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moet letters bevatten."])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moet in IBAN-formaat zijn."])},
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag alleen letters bevatten."])},
    "LINKEDIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De link is ongeldig."])},
    "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moet alleen cijfers bevatten"])},
    "UPPER_CASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag alleen hoofdletters, cijfers en underscores bevatten."])},
    "FILE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag alleen letters, cijfers en de symbolen _+%- bevatten"])}
  },
  "USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker"])},
  "FIRST_SEEN_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sporen van webgebruik"])},
  "NUMBER_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsidentificatienummer"])},
  "EMPLOYER_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkgeversverificatie"])},
  "PROCESS_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proces voor open banking-lancering"])},
  "INFORMATION_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie over het bedrijf"])},
  "SCORING_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrouwensscore van het bedrijf"])},
  "EMAIL_DOMAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-maildomein"])},
  "EMAIL_DOMAIN_CREATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creatiedatum van het e-maildomein"])},
  "EMAIL_DOMAIN_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koppeling met het e-maildomein"])},
  "EMAIL_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koppeling met e-mail"])},
  "OTHER_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezit van een ander bedrijf"])},
  "IS_RECENTLY_ACQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent overgenomen"])},
  "SCORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiële scores"])},
  "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "EMAIL_CORRELATION_WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overeenkomst tussen e-maildomein en website"])},
  "CHECK_IBAN": {
    "CONTAINER": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN-verificatie"])},
      "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsnaam"])}
    },
    "RESULT": {
      "OK": {
        "IBAN_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De IBAN bestaat"])},
        "OWNER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het eigenaarstype is correct"])},
        "REGISTRATION_NUMBER_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het registratienummer komt overeen met de IBAN"])},
        "BIRTHDATE_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De geboortedatum komt overeen met de IBAN"])},
        "NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De naam komt overeen"])},
        "BANK_CONNECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankverbinding geslaagd"])},
        "IBAN_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldige IBAN"])},
        "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekend bedrijf"])},
        "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekend bedrijf"])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overeenkomst tussen naam en IBAN"])},
        "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldige datum"])},
        "SEPAMAIL_BANK_ELIGIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank in aanmerking komend voor SEPAMAIL"])},
        "REGISTRATION_NUMBER_ENROLLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf ingeschreven op het platform"])},
        "ANALYSIS_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De analyse van de betalingsgeschiedenis maakt het mogelijk om het bedrijf aan de IBAN te koppelen en een gemiddelde betalingsfrequentie te bepalen"])},
        "ANALYSIS_COMMUNITY_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De analyse van de betalingsgeschiedenis van de gemeenschap maakt het mogelijk het bedrijf en de bankgegevens te koppelen"])},
        "FACTORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bankgegevens behoren niet tot een factoringbedrijf"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbekend"])},
        "BANK_ACCOUNT_LINKED_TO_COMPANY_FROM_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De analyse van de betalingsgeschiedenis maakt het mogelijk om het bedrijf en de bankgegevens te koppelen"])},
        "COMPANY_OR_ESTABLISHMENT_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het bedrijf is actief"])},
        "COUNTRY_COMPANY_COHERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De koppeling tussen het land van het bedrijf en het land van de bankgegevens is consistent"])},
        "FRAUDULENT_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bankgegevens zijn niet als frauduleus geïdentificeerd"])},
        "OTHER_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De andere namen en voornamen komen overeen"])},
        "PAYMENT_DEFAULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen betalingsstop gemeld voor dit bedrijf"])},
        "REGISTRATION_NUMBER_MATCHES_TVA_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het btw-nummer komt overeen met de IBAN"])},
        "SUSPICIOUS_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bankgegevens zijn niet geïdentificeerd als potentieel frauduleus"])},
        "TOO_MANY_COMPANIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een bedrijf is gevonden voor deze IBAN"])},
        "UNKNOWN_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekend bedrijf"])},
        "VIRTUAL_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bankgegevens komen niet overeen met een virtuele IBAN"])},
        "BANK_ACCOUNT_LINKED_TO_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overeenkomst geverifieerd: De bankgegevens behoren tot het gecontroleerde bedrijf"])}
      },
      "KO": {
        "IBAN_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De IBAN bestaat niet"])},
        "OWNER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het eigenaarstype is onjuist"])},
        "REGISTRATION_NUMBER_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het registratienummer komt niet overeen met de IBAN"])},
        "BIRTHDATE_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De geboortedatum komt niet overeen met de IBAN"])},
        "NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De naam komt niet overeen"])},
        "BANK_CONNECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankverbinding mislukt"])},
        "IBAN_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige IBAN"])},
        "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbekend bedrijf"])},
        "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbekend bedrijf"])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen overeenkomst tussen naam en IBAN"])},
        "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige datum"])},
        "SEPAMAIL_BANK_ELIGIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank niet geschikt voor SEPAMAIL"])},
        "REGISTRATION_NUMBER_ENROLLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf niet ingeschreven op het platform"])},
        "ANALYSIS_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De analyse van de betalingsgeschiedenis maakt het niet mogelijk om het bedrijf aan de IBAN te koppelen"])},
        "ANALYSIS_COMMUNITY_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De analyse van de betalingsgeschiedenis van de gemeenschap maakt het niet mogelijk om het bedrijf en de bankgegevens te koppelen"])},
        "FACTORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bankgegevens behoren waarschijnlijk tot een factoringbedrijf of worden hiervoor gebruikt"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbekend"])},
        "BANK_ACCOUNT_LINKED_TO_COMPANY_FROM_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De analyse van de betalingsgeschiedenis kon niet worden voltooid omdat de bankgegevens onbekend zijn"])},
        "COMPANY_OR_ESTABLISHMENT_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het bedrijf is gesloten"])},
        "COUNTRY_COMPANY_COHERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De koppeling is niet mogelijk, het land van het bedrijf en het land van de bankgegevens zijn verschillend"])},
        "FRAUDULENT_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bankgegevens zijn frauduleus"])},
        "BANK_ACCOUNT_CLOSED_LESS_THAN_13_MONTHS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bankrekening is minder dan 13 maanden geleden gesloten"])},
        "BANK_ACCOUNT_CLOSED_MORE_THAN_13_MONTHS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bankrekening is meer dan 13 maanden geleden gesloten"])},
        "OTHER_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De andere namen en voornamen komen niet overeen"])},
        "OWNER_TYPE_CORRECT_WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificatie van de correspondentie tussen de bankgegevens en het bedrijf is bezig"])},
        "PAYMENT_DEFAULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsstop gemeld voor dit bedrijf"])},
        "REGISTRATION_NUMBER_MATCHES_TVA_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het btw-nummer komt niet overeen met de IBAN"])},
        "SEPAMAIL_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEPAMAIL DIAMOND is niet in staat de verificatie uit te voeren"])},
        "SUSPICIOUS_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bankgegevens zijn mogelijk frauduleus"])},
        "TECHNICAL_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een technische fout opgetreden"])},
        "TOO_MANY_COMPANIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te veel bedrijven gevonden voor deze IBAN"])},
        "UNKNOWN_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbekend bedrijf"])},
        "VIRTUAL_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bankgegevens komen overeen met een virtuele IBAN"])},
        "BANK_ACCOUNT_LINKED_TO_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overeenkomst ongeldig: De bankgegevens behoren niet tot het gecontroleerde bedrijf"])}
      },
      "WARNING": {
        "IBAN_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN-controle niet mogelijk"])},
        "OWNER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controle van het eigenaarstype niet mogelijk"])},
        "REGISTRATION_NUMBER_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controle van overeenkomst tussen registratienummer en IBAN niet mogelijk"])},
        "BIRTHDATE_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificatie van geboortedatum niet mogelijk"])},
        "NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De naam komt niet helemaal overeen"])},
        "BANK_CONNECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankverbinding mislukt"])},
        "IBAN_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactiveerde IBAN (bijv. gesloten rekening)"])},
        "ICQX_ORDERING_PARTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICQX van de opdrachtgever niet doorgegeven (ICQX is de unieke identificatie van een juridische schuldeiser in het SEPAmail-netwerk)"])},
        "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf niet gevonden"])},
        "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf niet gevonden"])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overeenkomst tussen naam en IBAN niet gecontroleerd"])},
        "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum niet gecontroleerd"])},
        "SEPAMAIL_BANK_ELIGIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank niet geschikt voor SEPAMAIL"])},
        "REGISTRATION_NUMBER_ENROLLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf niet ingeschreven op het platform"])},
        "ANALYSIS_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De analyse van de betalingsgeschiedenis koppelt het bedrijf niet aan de IBAN en/of detecteert een lage betalingsfrequentie"])},
        "ANALYSIS_COMMUNITY_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De analyse van de betalingsgeschiedenis van de gemeenschap kon niet worden voltooid omdat de bankgegevens onbekend zijn"])},
        "FACTORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bankgegevens behoren mogelijk tot een factoringbedrijf of worden daarvoor gebruikt"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbekend"])},
        "BANK_ACCOUNT_LINKED_TO_COMPANY_FROM_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De analyse van de betalingsgeschiedenis toont aan dat de bankgegevens bekend zijn maar gekoppeld zijn aan een ander bedrijf, of dat de betalingsgeschiedenis te zwak is om de koppeling tussen het bedrijf en de bankgegevens te bevestigen"])},
        "COMPANY_OR_ESTABLISHMENT_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze vestiging is gesloten, maar het moederbedrijf is actief"])},
        "COUNTRY_COMPANY_COHERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De koppeling is niet mogelijk, het land van het bedrijf en het land van de bankgegevens zijn verschillend"])},
        "FRAUDULENT_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bankgegevens zijn frauduleus"])},
        "BANK_ACCOUNT_CLOSED_LESS_THAN_13_MONTHS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bankrekening is minder dan 13 maanden geleden gesloten"])},
        "BANK_ACCOUNT_CLOSED_MORE_THAN_13_MONTHS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bankrekening is meer dan 13 maanden geleden gesloten"])},
        "OTHER_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De andere namen en voornamen komen niet volledig overeen"])},
        "OWNER_TYPE_CORRECT_WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificatie van de correspondentie tussen de bankgegevens en het bedrijf is bezig"])},
        "PAYMENT_DEFAULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsstop gemeld voor dit bedrijf"])},
        "REGISTRATION_NUMBER_MATCHES_TVA_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificatie tussen het btw-nummer en de IBAN niet mogelijk"])},
        "SEPAMAIL_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEPAMAIL DIAMOND is niet in staat de verificatie uit te voeren"])},
        "SUSPICIOUS_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bankgegevens zijn mogelijk frauduleus"])},
        "TECHNICAL_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een technische fout opgetreden"])},
        "TOO_MANY_COMPANIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te veel bedrijven gevonden voor deze IBAN"])},
        "UNKNOWN_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbekend bedrijf"])},
        "VIRTUAL_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bankgegevens komen overeen met een virtuele IBAN"])},
        "BANK_ACCOUNT_LINKED_TO_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De overeenkomst tussen de bankgegevens en het bedrijf is niet verifieerbaar of de informatie is niet geschikt voor controle"])}
      },
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN-analyse"])},
      "VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificaties"])},
      "BANK_CONNECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding met de bank"])},
      "typeTitulaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type houder"])},
      "dateNaissance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboortedatum"])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "autreNom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere naam"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De verificatieaanvraag kon niet worden uitgevoerd vanwege een technisch probleem"])},
      "IBAN_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De IBAN bestaat"])},
      "IBAN_NOT_EXISTS_ANYMORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De IBAN bestaat niet meer en de rekening is in de afgelopen 13 maanden gesloten"])},
      "IBAN_NOT_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De IBAN bestaat niet of de rekening is langer dan 13 maanden geleden gesloten"])},
      "IBAN_CAN_BE_VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De IBAN kan niet worden geverifieerd: De gebruiker heeft aangegeven dat zijn rekening niet kan worden gecontroleerd met DIAMOND of de rekening is in vreemde valuta of de rekening is niet geschikt voor incasso- en overschrijvingsactiviteiten en valt daarom buiten het bereik, ..."])},
      "HOLDER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het ingevoerde type houder is correct"])},
      "HOLDER_TYPE_INCORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het ingevoerde type houder is onjuist"])},
      "SIREN_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het ingevoerde identificatienummer komt overeen met de gecontroleerde IBAN"])},
      "SIREN_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het ingevoerde identificatienummer komt niet overeen met de gecontroleerde IBAN"])},
      "SIREN_CANNOT_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bank kan het identificatienummer niet controleren (bijvoorbeeld omdat ze het niet kent)"])},
      "BIRTHDATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De ingevoerde geboortedatum komt overeen met de gecontroleerde IBAN"])},
      "BIRTHDATE_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De ingevoerde geboortedatum komt niet overeen met de gecontroleerde IBAN"])},
      "BIRTHDATE_CANNOT_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bank kan de ingevoerde geboortedatum niet controleren (bijvoorbeeld omdat ze deze niet kent)"])},
      "OWNER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het type eigenaar is correct"])},
      "REGISTRATION_NUMBER_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het registratienummer komt overeen met de IBAN"])},
      "BIRTHDATE_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De geboortedatum komt overeen met de IBAN"])},
      "NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De naam komt overeen"])},
      "IBAN_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige IBAN"])},
      "ICQX_ORDERING_PARTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICQX van de opdrachtgever"])},
      "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIREN"])},
      "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registratienummer"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige datum"])},
      "SEPAMAIL_BANK_ELIGIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank geschikt voor SEPAMAIL"])},
      "REGISTRATION_NUMBER_ENROLLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registratienummer ingeschreven"])},
      "ANALYSIS_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse van de betalingsgeschiedenis"])},
      "ANALYSIS_COMMUNITY_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse van de gemeenschappelijke betalingsgeschiedenis"])},
      "FACTORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factoring"])},
      "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbekend"])},
      "REASON_CODE": {
        "IncorrectAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige IBAN"])},
        "UnrecognisedInitiatingParty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICQX van de Opdrachtgever"])},
        "InvalidIdentificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificatienummer"])},
        "MissingName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
        "InvalidData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige gegevens"])},
        "BICInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank niet geschikt voor SEPAMAIL-controle"])}
      }
    },
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het lijkt erop dat de bevraagde bank een verbindingsprobleem heeft met SepaMail. Dank u voor uw begrip."])},
    "MEELO_RECOMMENDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo Aanbeveling"])},
    "RECOMMENDATION": {
      "DECISION": {
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conform"])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waakzaamheid"])},
        "risky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alarm"])}
      },
      "MESSAGE": {
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De IBAN-analyse bevat geen waarschuwingen."])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De IBAN-analyse bevat enkele waarschuwingen."])},
        "risky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De IBAN-analyse bevat ten minste één ernstige waarschuwing."])}
      }
    }
  },
  "INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informaties"])},
  "SYNTHESIS": {
    "FOLDER_STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status van het dossier"])},
    "ANALYST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyst"])},
    "LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekoppeld"])},
    "VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geverifieerd"])},
    "JOURNEY_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unieke identificatie"])},
    "PHONE_AND_SOCIAL_NETWORKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoon en sociale netwerken"])},
    "LINKED_TO_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekoppeld aan e-mail"])},
    "LINKED_TO_PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekoppeld aan telefoon"])},
    "BUSINESS_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsnummer"])},
    "VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificatie"])}
  },
  "WARNING_LIST": {
    "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsnaam"])},
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificatienummer"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoon"])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen waarschuwingen gevonden tijdens onze analyse"])},
    "MESSAGE_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op, de volgende parameter(s) is/zijn geïdentificeerd tijdens een eerdere fraudezaak"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarschuwingslijst Meelo"])},
    "MESSAGE_ALERT_SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op, de ingevoerde parameter is geïdentificeerd in een eerdere fraudezaak."])},
    "MESSAGE_NO_ALERT_SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen waarschuwingen gevonden tijdens onze analyse."])}
  },
  "SELECT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer de analyses die u wilt uitvoeren"])},
  "RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultaten"])},
  "PHONE_INDICATION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een indicator en voer een nummer in zonder spaties of tekens tussen de cijfers."])},
  "BIRTHDATE_INDICATION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sterk aanbevolen om het klantecosysteem weer te geven."])},
  "EXAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorbeeld"])},
  "FROM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["van"])},
  "DOMAIN_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie over het domein"])},
  "DOMAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein"])},
  "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldingsplichtige"])},
  "REGISTRAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host"])},
  "ADDITIONAL_CONTROLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanvullende controles"])},
  "COMPANY_DOMICILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf gevestigd"])},
  "EMAIL_DOMAIN_CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creatiedatum van het e-maildomein"])},
  "WEBSITE_DOMAIN_CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creatiedatum van het website-domein"])},
  "MORE_THAN_THREE_YEARS_WITHOUT_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode van inactiviteit langer dan 3 jaar"])},
  "LOCATION_AT_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie met risico"])},
  "OPERATOR_AT_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator met risico"])},
  "INITIAL_RISKY_OPERATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiële risicovolle operator"])},
  "NO_WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor meer informatie, voer de scores uit"])},
  "HAS_OTHER_COMPANIES_IN_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere bedrijven in collectieve procedures"])},
  "HAS_OTHER_COMPANIES_IN_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere bedrijven uitgeschreven"])},
  "HAS_OTHER_COMPANIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ander bedrijf"])},
  "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "SUPPORT_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een probleem? Neem gerust contact met ons op."])},
  "SIDEBAR": {
    "KIT_B2C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particulier Analyse"])},
    "KIT_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf Analyse"])},
    "KIT_OB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking"])},
    "KIT_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Analyse"])},
    "HISTORICAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historische gegevens"])},
    "MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn account"])},
    "BATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch"])},
    "HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hulp"])},
    "MEELO_KIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo Kit"])},
    "KIT_RECOVERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstel"])},
    "BACKOFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achterkantoor"])},
    "PORTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portaal"])},
    "MEELO_ACADEMY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo Academie"])},
    "DOCUMENT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentenanalyse"])},
    "USERS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn gebruikers"])},
      "PROFILE_AND_ACCESS_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel- en toegangsbeheer"])},
      "USER_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersbeheer"])},
      "GROUP_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepsbeheer"])},
      "LOCATION_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaatsbeheer"])}
    },
    "LIST_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lijstbeheer"])},
    "HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startpagina"])},
    "MONITORING": {
      "MY_USAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn verbruik"])},
      "COMPANY_USAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsverbruik"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbruiksmonitoring"])}
    },
    "RULES_ENGINE": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regelengine"])},
      "COMPANY_RULES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsregels"])},
      "SETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regelsets"])}
    },
    "COMPANY": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn bedrijf"])},
      "MANAGE_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer van lijsten"])}
    }
  },
  "BREADCRUMBS": {
    "COMPANY_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsinformatie"])},
    "APPLICANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanvrager"])},
    "DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beslissing"])},
    "JUSTIFICATORIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ondersteunende documenten"])},
    "SCORE_CHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekeuze"])},
    "RECEIVER_CHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvangerkeuze"])},
    "UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "BATCH_RUN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batchuitvoering"])}
  },
  "PROJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
  "PRODUCT": {
    "VEHICLES_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarde incl. BTW van voertuigen"])},
    "CONTRIBUTION_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijdragebedrag"])}
  },
  "CGI": {
    "DECISION": {
      "SUCCESS": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste stap!"])},
        "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doorgaan met het verzamelen van bewijsstukken"])},
        "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan en Doorgaan"])}
      },
      "FAILED": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De analyse is voltooid!"])},
        "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatief advies"])},
        "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afronden"])}
      }
    },
    "DOCUMENTS": {
      "BLOCK": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload van bewijsstukken"])}
      },
      "ID_CARD": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identiteitsbewijs"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor- en achterkant van uw identiteitsbewijs"])}
      },
      "BANK_DETAILS": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankafschrift"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankafschrift van uw hoofdrekening"])}
      },
      "KBIS": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uittreksel Handelsregister"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uittreksel Handelsregister afgegeven in de laatste 3 maanden"])}
      },
      "TAX_REPORT": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingaangifte"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste belastingaangifte met minstens 2 jaar geschiedenis"])}
      }
    },
    "CONGRATULATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goed gedaan"])},
    "END_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je hebt het dossier op het Meelo-portaal voltooid."])}
  },
  "TITLE_ACTION_DOCUMENT_BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisitiemodus"])},
  "ONLY_PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleen PDF"])},
  "MULTI_REQUESTS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-verzoeken"])},
    "DESCRIPTION_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de afgelopen 30 dagen zijn een of meer dossiers aangemaakt door"])},
    "DESCRIPTION_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["met vergelijkbare en/of identieke informatie."])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen vergelijkbare of identieke dossiers ingevoerd in de afgelopen 30 dagen."])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal dossiers met hetzelfde e-mailadres"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal dossiers met hetzelfde telefoonnummer"])},
    "REGISTRATIONNUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal dossiers met hetzelfde registratienummer"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal dossiers met dezelfde IBAN"])}
  },
  "RECOVERY": {
    "CLIENT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant-ID"])},
    "REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzoek"])},
    "FINANCIAL_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiële analyse"])},
    "ANALYSIS_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseerdatum"])},
    "BANK_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankinformatie"])},
    "CUSTOMER_RELIABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantbetrouwbaarheid"])},
    "REJECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afwijzing"])},
    "ON_AVERAGE_PER_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gemiddeld per maand"])},
    "INTERVENTION_FEES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interventiekosten"])},
    "THIRD_PARTY_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melding aan derden"])},
    "GAMBLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gokspelen"])},
    "FINANCIAL_SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiële situatie"])},
    "AVERAGE_DISPOSABLE_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld beschikbaar inkomen"])},
    "LOWEST_DISPOSABLE_INCOME_OVER_THE_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laagste beschikbare inkomen over de periode"])},
    "LOWEST_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laagste inkomen"])},
    "LACK_OF_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebrek aan inkomen"])},
    "AVERAGE_MONTHLY_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld maandelijks inkomen"])},
    "FINANCIAL_PRESSURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiële druk"])},
    "CREDITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredieten"])},
    "DEBT_COLLECTION_AGENCIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incassobureaus"])},
    "RISK_LEVEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risiconiveau"])},
    "LOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laag"])},
    "MODERATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld"])},
    "RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riskant"])},
    "LACK_OF_INCOME_SOURCES_DETECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een of meerdere inkomstenbronnen zijn gedetecteerd"])},
    "LACK_OF_INCOME_SOURCES_NOT_DETECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen inkomen gedetecteerd"])}
  },
  "BATCH": {
    "CLICK_ON_SCORES_BELOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik op één of meer score(s) hieronder om de scores te selecteren die via de batch moeten worden uitgevoerd."])},
    "MANUAL_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiksaanwijzing"])},
    "MANUAL_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor optimaal gebruik van de batch raden we aan om het sjabloon hierboven te downloaden. Het zal bestaan uit de ideale structuur aangepast voor alle eerder geselecteerde scores. U kunt vervolgens het Excel-bestand invullen met uw gegevens en het iets lager op de pagina uploaden."])},
    "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch annulering succesvol"])},
    "DOWNLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download het batchresultaat"])},
    "STARTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batchstart bezig..."])},
    "RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft zojuist een e-mail ontvangen met het batchresultaat"])},
    "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start het batchproces"])},
    "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch annuleren"])},
    "USAGE_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiksinformatie"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving"])},
    "DOWNLOAD_TEMPLATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download het sjabloon"])},
    "LIMIT_PER_DAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["regels / bestand"])},
    "FILE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewenste uitvoerbestandsnaam"])},
    "SCORE": {
      "REQUEST_ID": {
        "WEB_SCORE_B2C_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identiteitsfraudescore"])},
        "SMB_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score voor Professionele Identiteitsfraude"])},
        "WEB_SCORE_B2B_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrouwensscore Bedrijf"])},
        "ENERGY_SCORE_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingsscore Risico Energie Sector"])},
        "E_REPUTATION_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Reputation Score"])},
        "E_PAYMENT_RISK_SCORE_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risico Score"])},
        "ALTARES_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altares score"])},
        "ELLISPHERE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellisphere Score"])},
        "CREDIT_SAFE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CreditSafe Score"])}
      },
      "DESCRIPTION": {
        "WEB_SCORE_B2C_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Identiteitsfraude Score is een model dat het risico op identiteitsfraude bij een klant beoordeelt. Het is gebaseerd op de evaluatie van verschillende criteria: naam, voornaam, e-mail, telefoonnummer, evenals postadres of geboortedatum."])},
        "SMB_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Professionele Identiteitsfraude Score is een model dat het risico op identiteitsfraude bij een zakelijke klant (in een B2B-context) beoordeelt. Het is gebaseerd op de evaluatie van verschillende criteria: naam, voornaam, e-mail, telefoonnummer, evenals postadres of geboortedatum."])},
        "WEB_SCORE_B2B_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 'Score Confiance Entreprise' scoringservice is een hulpmiddel voor het beoordelen van het bedrijf en de relatie tussen de aanvrager (natuurlijke persoon) en het bedrijf (rechtspersoon)."])},
        "ENERGY_SCORE_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 'Energie Risicoscore' is een model dat speciaal is ontworpen voor de energiesector en dat het risico op wanbetaling van de klant evalueert. Het is gebaseerd op de beoordeling van verschillende criteria: de telefoonoperator / de bank / IRIS-gegevens over het postadres / het maandelijkse bedrag (exact of geschat)."])},
        "E_REPUTATION_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De E-Reputation Score is een model ontworpen om de tevredenheid en het vertrouwen van consumenten in het bedrijf te beoordelen op basis van de verschillende beoordelingen die zij hebben achtergelaten."])},
        "E_PAYMENT_RISK_SCORE_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Risico Score is een model ontworpen om de kredietwaardigheid van een persoon te beoordelen op basis van hun persoonlijke informatie en betalingsinformatie."])},
        "ALTARES_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Altares Score is een model dat is ontworpen om het solvabiliteitsrisico van een bedrijf te beoordelen op basis van de resultaten die zijn verstrekt door het financiële beoordelingsbedrijf Altares."])},
        "ELLISPHERE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Ellisphere Score is een model dat is ontworpen om het solvabiliteitsrisico van een bedrijf te beoordelen op basis van de resultaten die zijn verstrekt door het financiële beoordelingsbedrijf Ellisphere."])},
        "CREDIT_SAFE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De CreditSafe Score is een model dat is ontworpen om het solvabiliteitsrisico van een bedrijf te beoordelen op basis van de resultaten die zijn verstrekt door het financiële beoordelingsbedrijf CreditSafe."])}
      }
    }
  },
  "FILE": {
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een bestand"])}
  },
  "IMPORTANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belangrijk"])},
  "RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riskant"])},
  "BACKOFFICE": {
    "GROUPS": {
      "COMPANY_GROUPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepsbeheer"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving"])},
      "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acties"])},
      "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep bewerken"])},
      "CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep aanmaken"])},
      "DELETE_WARN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze groep wordt door gebruikers gebruikt, weet u zeker dat u deze wilt verwijderen?"])}
    },
    "PLACES": {
      "COMPANY_PLACES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaatsbeheer"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving"])},
      "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acties"])},
      "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaats bewerken"])},
      "CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaats aanmaken"])},
      "DELETE_WARN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze plaats wordt door gebruikers gebruikt, weet u zeker dat u deze wilt verwijderen?"])}
    },
    "USERS": {
      "SHORTCUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De snelkoppelingen"])},
      "COMPANY_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsgebruikers"])},
      "GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep"])},
      "PLACE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaats"])},
      "PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel"])},
      "MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
      "USER_MODIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikerswijziging"])},
      "PROFILE_ASSIGNMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profieltoewijzingen"])},
      "USER_DELETION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker verwijderen"])},
      "DELETE": {
        "MESSAGE_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U staat op het punt de gebruiker te verwijderen"])},
        "OF_THE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["van het bedrijf"])},
        "MESSAGE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gebruiker zal niet langer toegang hebben tot zijn account."])},
        "ACTION_IRREVERSIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze actie is onomkeerbaar."])},
        "QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u deze gebruiker wilt verwijderen?"])}
      },
      "USER_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersprofiel"])},
      "TECHNICAL_ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technische handelingen"])},
      "SUPERVISOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toezichthouder"])},
      "USER_ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker toevoegen"])},
      "ACTIVE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief account"])},
      "UPDATE_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De status van de account is succesvol gewijzigd."])},
      "RESET_PASSWORD": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een wachtwoord opnieuw instellen"])},
        "MESSAGE_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U staat op het punt een e-mail voor het opnieuw instellen van het wachtwoord naar de gebruiker te sturen"])},
        "MESSAGE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["naar het e-mailadres"])},
        "SENDING_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail voor wachtwoordherstel verzonden"])}
      },
      "RESET_API_KEY": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een API-sleutel opnieuw instellen"])},
        "CONFIRM_RESET_USER": {
          "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U staat op het punt een e-mail voor het opnieuw instellen van de API-sleutel naar de gebruiker te verzenden"])},
          "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["naar het e-mailadres"])},
          "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belangrijk: De link om de API-sleutel op te halen is slechts 30 minuten geldig."])}
        },
        "CONFIRM_RESET_US": {
          "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U staat op het punt uw API-sleutel opnieuw in te stellen."])},
          "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uit veiligheidsoverwegingen kan de waarde van uw nieuwe sleutel slechts één keer worden opgevraagd."])},
          "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze nieuwe sleutel wordt direct naar uw digitale klembord gekopieerd."])},
          "FOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij adviseren u deze direct op een veilige plaats te bewaren, zodat u deze later opnieuw kunt gebruiken."])}
        },
        "SENDING_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail voor het opnieuw instellen van de API-sleutel verzonden"])},
        "NEW_API_KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw nieuwe API-sleutel is naar uw klembord gekopieerd"])}
      }
    },
    "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf"])},
    "FUNCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functie"])},
    "ADVANCED_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gevorderde gebruiker"])},
    "ADVANCED_USER_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan andere geavanceerde en/of basisgebruikers maken en toegang krijgen tot bepaalde instellingen."])},
    "PROFILE_AND_ACCESS": {
      "COMPANY_PROFILES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsprofielen"])},
      "PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestemmingen"])},
      "MESSAGE_PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer hierboven een profiel om de lijst met bijbehorende machtigingen te bekijken."])},
      "CREATED_BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemaakt door"])},
      "KIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit"])},
      "MODULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module"])},
      "RIGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechten"])},
      "ADDING_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel toevoegen"])},
      "PROFILE_MODIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel wijzigen"])},
      "SAVE_PERMISSION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn wijzigingen gedetecteerd, vergeet niet om ze op te slaan met behulp van de bovenstaande knop."])},
      "DESCRIPTION_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profielbeschrijving"])},
      "NO_PROFILE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen profiel gevonden"])}
    },
    "PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestemmingen"])},
    "MONITORING": {
      "DOWNLOAD_BALANCE_SHEET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download de balans"])},
      "TOTAL_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaalverbruik"])},
      "MONTHLY_EVOLUTION_CURVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijkse evolutiecurve"])},
      "TODAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vandaag"])},
      "THIS_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze week"])},
      "LAST_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige week"])},
      "THIS_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze maand"])},
      "LAST_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige maand"])},
      "GENERAL_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemene consumptie"])},
      "COMPANY_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbruik voor het bedrijf"])},
      "DATA_ON_THE_WAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw gegevens zijn onderweg..."])},
      "CONSUMPTION": {
        "OCR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identiteitsdocumentanalyse"])},
        "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentanalyse"])},
        "CHECK_VALUE_COMPANY_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN B2B-controle"])},
        "CHECK_VALUE_PERSONAL_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN B2C-controle"])},
        "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsanalyse"])},
        "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCB-FT-analyse"])},
        "GET_LINKEDIN_PROFILE_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn-verrijking"])},
        "OPENBANKING_MEELO_OPENBANKING_PERSONAL_SCORING_MODEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking B2C"])},
        "OPENBANKING_MEELO_OPENBANKING_PROFESSIONAL_SCORING_MODEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking B2B"])},
        "SCORING_ELLISPHERE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellisphère-score"])},
        "SCORING_CREDIT_SAFE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CreditSafe-score"])},
        "SCORING_WEB_SCORE_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrouwensscore bedrijf"])},
        "SCORING_WEB_SCORE_B2C_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identiteitsscore particulier"])},
        "SCORING_E_REPUTATION_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Reputatiescore"])},
        "SCORING_ALTARES_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altare-score"])},
        "SCORING_SMB_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identiteitsscore professional"])},
        "SELECTED_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbruik van geselecteerde gebruikers"])},
        "OF_THE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsverbruik"])},
        "OF_MY_TEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbruik van mijn team"])},
        "MY_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn verbruik"])}
      },
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gegevens gevonden. Voer een eerste analyse uit om je verbruik te kunnen raadplegen."])}
    },
    "RULES_ENGINE": {
      "RULES": {
        "FOR_API_IMPLEMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor API-implementatie"])},
        "EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier vindt u de lijst met goedgekeurde regels voor uw bedrijf."])},
        "RULES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regels"])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
        "CONTAINED_IN_A_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevat in een set"])},
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "GENERIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generiek"])},
        "SPECIFIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifiek"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving"])},
        "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie"])},
        "MODIFY_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regel aanpassen"])},
        "RULE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regelnaam"])},
        "RULE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regelbeschrijving"])},
        "VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarde"])},
        "DEFAULT_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaardwaarde"])},
        "PARAMETERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])},
        "ARGUMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argumenten"])},
        "EDIT_PARAMETER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter bewerken"])},
        "PARAMETER_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter naam"])},
        "PARAMETER_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameterbeschrijving"])},
        "FLOAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimaal"])},
        "INTEGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geheel getal"])},
        "STRING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst"])},
        "BOOLEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boolean"])},
        "LIST_STRING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lijst van teksten"])},
        "RULE_PRESENT_IN_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regel aanwezig in de volgende sets"])},
        "RULE_V1_NOT_EDITABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze regel is een V1-regel en kan niet worden aangepast. Als je het wilt wijzigen, neem dan contact op met Meelo."])}
      },
      "SETS": {
        "SETS_RULES_EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om de regels van een set te bekijken, selecteert u de overeenkomstige rij in het linker tabelgebied."])},
        "NO_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen regelset voor uw bedrijf. Neem contact op met Meelo om er een te maken."])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
        "SETS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lijst van sets"])},
        "SETS_RULES_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lijst van regels van de set"])},
        "EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier vindt u de lijst van gecreëerde regelsets voor uw bedrijf."])},
        "ADD_OR_DELETE_RULE_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor eventuele wijzigingen met betrekking tot het toevoegen of verwijderen van een regel in een set, neem contact op met Meelo."])}
      }
    },
    "MY_COMPANY": {
      "USEFUL_CONTACTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuttige contacten"])},
      "KITS_AND_MODULES_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kits en modules beschikbaar"])},
      "ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheerder"])},
      "SUPPORT_MEELO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ondersteuning Meelo"])}
    }
  },
  "RESEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onderzoek"])},
  "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
  "PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina"])},
  "ON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["op"])},
  "RESULTS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultaten gevonden"])},
  "NUMBER_OF_ITEMS_PER_PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal items per pagina"])},
  "SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samenvatting"])},
  "DATA_CONTACTABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacteerbaarheid van gegevens"])},
  "AND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])},
  "UNREACHABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niet bereikbaar"])},
  "LOCALE": {
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duits"])},
    "EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engels"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaans"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frans"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiaans"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlands"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugees"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["België"])}
  },
  "ADVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beoordeling"])},
  "E_REPUTATION": {
    "AVERAGE_GRADE_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde score niet gevonden"])},
    "LIST_TEN_LAST_REVIEWS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lijst van de laatste 10 beoordelingen"])},
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo E-Reputatiescore"])},
    "NEGATIVE_REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatieve beoordeling"])},
    "REVIEW_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingscommentaar"])}
  },
  "NO_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen opmerkingen"])},
  "PRINT": {
    "FOLDER_INFO": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossierinformatie"])},
      "CREATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmaakdatum"])},
      "FOLDER_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossierstatus"])},
      "ANALYST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyst"])},
      "UNIQ_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unieke identificatie"])}
    },
    "APPLICANT_OB": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanvrager"])},
      "OB_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking Analyse"])},
      "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
      "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
      "BIRTH_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboortedatum"])},
      "BIRTH_PLACE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboorteplaats"])},
      "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiele Telefoon"])},
      "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postadres"])},
      "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
      "HOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houder"])},
      "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekeningnaam"])},
      "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "MAIN_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdrekening"])},
      "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld Maandelijks Salaris"])},
      "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde Maandelijkse Huur"])},
      "MONTH_END_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verhouding van Uitgaven tot Inkomen"])},
      "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijks Saldo"])},
      "BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidig Saldo"])},
      "TOP_BANKING_SUPERVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banktoezicht"])},
      "TOP_DEFERRED_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detectie Uitgestelde Debet"])},
      "GLOBAL_TRANSACTIONS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal Transacties"])}
    },
    "DOCUMENT_ANALYSIS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentanalyse"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "COMPLIANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naleving"])},
      "DOCUMENT_CONTROL": {
        "ID_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identiteitskaart"])},
        "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identiteitskaart"])},
        "DRIVING_LICENSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rijbewijs"])},
        "PASSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paspoort"])},
        "RESIDENCE_PERMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verblijfsvergunning"])},
        "KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS - Bedrijf of Situatie"])},
        "INDIVIDUAL_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS Situatiebericht"])},
        "COMPANY_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uittreksel KBIS"])},
        "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticiteit - Analyse van niet-digitale wijziging"])},
        "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingaangifte"])},
        "TAX_REPORT_S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereenvoudigde belastingaangifte"])},
        "TAX_REPORT_C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complex belastingaangifte"])},
        "TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingkennisgeving - Inkomen of Eigendom"])},
        "INCOME_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkomstenbelasting"])},
        "PROPERTY_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onroerendezaakbelasting"])},
        "BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankgegevens"])},
        "PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loonstrookje"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbekend"])},
        "FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biometrische controle"])},
        "BANK_STATEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankafschrift"])}
      }
    },
    "SCORE_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Details"])},
    "CHECK_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN Analyse"])},
    "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestrijding van Witwassen en de Financiering van Terrorisme"])},
    "OB_ANALYSIS_DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedetailleerde OpenBanking Analyse"])},
    "ANALYSIS_B2B": {
      "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
      "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
      "COMPANY_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsinformatie"])},
      "LEGAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridische informatie"])},
      "CLIENT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantinformatie"])},
      "CORPORATE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsnaam"])},
      "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registratienummer"])},
      "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
      "WORKFORCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werknemers"])},
      "CREATED_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum van oprichting"])},
      "VAT_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTW-nummer"])},
      "ACTIVITY_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activiteitscode"])},
      "LEGAL_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridische vorm"])},
      "CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapitaal"])},
      "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activiteit"])},
      "FIELD_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkterrein"])},
      "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluitingsdatum"])},
      "DOMICILIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domiciliëring"])},
      "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declarant"])},
      "WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
      "REGISTRAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar"])},
      "BUSINESS_OWNERS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekende aandeelhouders en begunstigden"])},
      "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboortedatum"])},
      "NATIONALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationaliteit"])},
      "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positie"])},
      "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificatienummer"])},
      "WARNING_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarschuwingslijst Meelo"])},
      "BUSINESS_REPRESENTS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekende managers en vertegenwoordigers"])},
      "SCORE_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score details"])},
      "COMPANY_SCORE_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsvertrouwensscore"])},
      "COMPANY_FINANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsfinanciën"])},
      "COMPANY_LEGAL_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridische documenten"])},
      "ANNUAL_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaarrekeningen"])},
      "ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vestigingen"])},
      "COMPANY_EVOLUTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsevolutie"])},
      "SCORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiële scores"])},
      "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Reputatie"])},
      "OB_ANALYSIS_DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedetailleerde analyse van OpenBanking"])},
      "ALERTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarschuwingen"])},
      "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN-analyse"])},
      "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestrijding van witwassen en terrorismefinanciering"])}
    },
    "COVER_PAGE": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport over uitgevoerde controles"])},
      "CREATED_BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestand gemaakt door"])},
      "CREATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestand gemaakt op"])},
      "DOC_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsanalyse"])},
      "DOC_B2C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoonlijke Analyse"])},
      "DOC_OB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open-Banking Analyse"])},
      "DOC_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentanalyse"])},
      "DOC_SYNTHESIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentensynthese"])}
    }
  },
  "ENRICHMENT": {
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om een zoekopdracht naar coördinaten te starten, gelieve hieronder de URL van het LinkedIn-profiel van uw contact te plakken."])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dataverrijking"])},
    "NOPROFIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen LinkedIn-profiel gevonden."])},
    "NOCORDINATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen coördinaten gevonden."])},
    "TITLE_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coördinaten gevonden :"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail(s)"])},
    "EMAIL_PRO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakelijke e-mail(s) :"])},
    "EMAIL_PERSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoonlijke e-mail(s) :"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoon(toestel)(len):"])},
    "NO_PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen telefoon gevonden"])},
    "NO_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen e-mail gevonden"])},
    "MATCH": {
      "EMAIL_PERSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overeenstemming met persoonlijke e-mail ingevoerd"])},
      "EMAIL_PRO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komt overeen met opgegeven professionele e-mail"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komt overeen met opgegeven telefoon"])}
    }
  },
  "NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIEUWIGHEID"])},
  "DECLARATION": {
    "FRAUD": {
      "BUTTON_SIDEBAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["een fraude melden"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraudeverklaring"])},
      "EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve het onderstaande formulier in te vullen voor gevallen van identiteitsdiefstal of bevestigde fraude."])},
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Datum van ontvangst van het frauduleuze verzoek"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Soort zaak"])},
      "MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Verklaard e-mailadres"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Verklaard telefoonnummer"])},
      "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschat bedrag van fraude"])},
      "URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL van het dossier"])},
      "AVOID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is de fraude voorkomen ?"])},
      "TOOLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met welk(e) gereedschap(pen)"])},
      "COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welke elementen doen u vermoeden dat het om fraude gaat ?"])},
      "CONDITION_FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" * Ik accepteer de "])},
      "CONDITION_SECOND_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["algemene gebruiksvoorwaarden"])},
      "CONDITION_THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" van Meelo met betrekking tot handmatige fraudemelding"])},
      "PROFESIONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professioneel "])},
      "INDIVIDUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particulier"])}
    }
  },
  "RECOMMENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionele informatie, maar sterk aanbevolen"])},
  "THANKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DANK JE"])},
  "CONFIRM_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw verklaring is succesvol verzonden"])},
  "MODULE": {
    "ALTARES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altare Score"])},
    "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCBFT"])},
    "BUSINESS_OWNERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectieve Bedrijfseigenaren"])},
    "CHECK_IBAN_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN B2B Verificatie"])},
    "COMPANY_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfswaarschuwingen"])},
    "COMPANY_ANNUAL_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaarrekeningen van Bedrijven"])},
    "COMPANY_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsvestigingen"])},
    "COMPANY_EVOLUTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsontwikkeling"])},
    "COMPANY_FINANCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsfinanciën"])},
    "COMPANY_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsinformatie"])},
    "COMPANY_LEGAL_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridische Documenten van Bedrijven"])},
    "COMPANY_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsvertrouwensscore"])},
    "CREDIT_SAFE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Safe Score"])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Reputatie"])},
    "OB_ALERTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Banking Waarschuwingen"])},
    "LITIGATION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridische status - Geschillen"])},
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeeld op "])},
      "DEMAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vraag: "])},
      "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie: "])},
      "MAIN_HEARING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main hearing"])},
      "SUMMARY_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary procedure"])},
      "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbekend"])}
    },
    "FINANCIAL_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sectie financiële risicoanalyse"])}
  },
  "CARTOGRAPHY": {
    "COMPANY_COLLECTIVE_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf in Collectieve Procedure"])},
    "BUSINESS_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsnaam"])},
    "CARTOGRAPHY_OF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartografie van"])},
    "STUDIED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf geanalyseerd"])},
    "ACTIVE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief Bedrijf"])},
    "COLLECTIVE_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf onder Collectieve Procedure"])},
    "COMPANY_CARTOGRAPHY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfscartografie"])},
    "DEREGISTERED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gederegistreerd Bedrijf"])},
    "INDIVIDUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individueel"])},
    "COMPANY_ACTIVITIES_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsactiviteitscode"])},
    "COMPANY_ACTIVITIES_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van de bedrijfsactiviteit"])},
    "RECENTLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recentelijk"])},
    "UNVERIFIED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet-geverifieerd bedrijf"])},
    "OPEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergroten"])}
  },
  "MENU": {
    "MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn account"])},
    "MY_CONSUPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn verbruik"])},
    "PORTAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portaalinformatie"])},
    "LOG_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen als"])},
    "BACK_OFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backoffice"])},
    "LOGOUT_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen van het klantaccount"])},
    "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])}
  },
  "CONNECT_AS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen als "])},
    "PARAGRAPH": {
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U staat op het punt om in te loggen als"])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["van het bedrijf"])},
      "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U ziet daarom het portaal op basis van de rechten die aan deze gebruiker zijn verleend."])},
      "FOURTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle uw acties worden bijgehouden onder de naam van de klant en de uwe."])},
      "FIFTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve"])},
      "SIXTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voorzichtig te zijn"])},
      "SEVENTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bij het gebruik van het portaal als klant, en"])},
      "EIGHTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["respecteer de vertrouwelijkheid"])},
      "NINTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["van de gegevens van onze klanten."])},
      "TENTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergeet niet om"])},
      "ELEVENTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uit te loggen bij het klantaccount"])},
      "TWELFTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nadat uw acties zijn voltooid."])}
    },
    "TOAST": {
      "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent niet langer ingelogd als"])},
      "LOG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent nu ingelogd als"])}
    },
    "BADGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ingelogd als"])},
    "MODAL": {
      "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf"])},
      "USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker"])}
    }
  },
  "PORTAL_INFORMATION": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie over het MEELO-portaal"])},
    "SUBTITLE": {
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het Meelo-portaal"])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" : Een aanbeveling in één oogopslag"])}
    },
    "DESCRIPTION": {
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Meelo-producten binnen handbereik, zodat u binnen enkele minuten uw beslissing kunt nemen."])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via ons beslissingsondersteuningsportaal kunt u de Meelo-producten gebruiken die nodig zijn voor de juiste verificatie van de klant die met u in contact komt."])},
      "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraudescore, identiteitsverificatie, Open Banking, documentverificatie… Meelo-producten binnen handbereik met minimale technische integratie."])}
    },
    "VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versie van uw portal"])},
    "ENVIRONMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omgeving"])},
    "PRODUCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productie"])},
    "DEVELOPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontwikkeling"])},
    "STAGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enscenering"])},
    "GDPR": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVG"])},
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij hechten veel belang aan de bescherming van uw persoonsgegevens. In overeenstemming met de Algemene Verordening Gegevensbescherming (AVG) verzamelen wij alleen noodzakelijke informatie, verkrijgen wij uw uitdrukkelijke toestemming, gebruiken wij de gegevens van u en uw klanten op legitieme wijze, garanderen wij de veiligheid ervan, beperken wij de bewaring ervan en respecteren wij uw rechten."])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u vragen of opmerkingen heeft over dit privacybeleid, kunt u onze raadplegen"])},
      "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" of neem contact op met onze DPO, via "])}
    },
    "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["speciale pagina"])}
  },
  "MAINTENANCE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De volgende services kunnen problemen ondervinden: "])},
    "FINISHED_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergeet niet je hek te verversen voor optimaal gebruik."])},
    "ONGOING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lopend onderhoud"])},
    "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afgerond onderhoud"])},
    "SERVICE": {
      "AML_CFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienst voor het Bestrijden van Witwassen en de Financiering van Terrorisme"])},
      "CHECKOUT_PROXY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronische Handtekeningdienst"])},
      "DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Upload Dienst"])},
      "DOCUMENT_PROXY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Controle Dienst"])},
      "DOCUMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe Documentatie Dienst"])},
      "IAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticatie Dienst"])},
      "OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Banking Dienst"])},
      "PORTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo Portaal"])},
      "RULES_ENGINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regels Motor"])},
      "SCORING_PROXY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoringsdienst"])},
      "TASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne Taakbeheer Dienst"])},
      "TAX_NOTICE_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingkennisgeving Analyse Dienst"])},
      "TAX_REPORT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingaangifte Analyse Dienst"])},
      "TRANSLATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne Vertaaldienst"])},
      "SHORTENER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne URL-verkorting Dienst"])},
      "CHECK_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsverrijking Dienst"])},
      "UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne Document Upload Dienst"])},
      "TRACKETIO_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo Traceringselement Analyse Dienst"])},
      "STORAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne Document Opslag Dienst"])},
      "ALERTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarschuwingsdienst"])},
      "TRACKETIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikerstracking Dienst in Browser"])},
      "OPEN_REVENUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Inkomstendienst"])},
      "ENRICHMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegevensverrijking Dienst"])},
      "FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beeld Zoek- en Overeenkomst Dienst"])},
      "CHECK_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN Controle Dienst"])}
    }
  },
  "FOLDER": {
    "WORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["map"])},
    "STATE": {
      "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezig"])},
      "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geaccepteerd"])},
      "REFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geweigerd"])},
      "CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemaakt"])},
      "WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachten"])},
      "CANCELED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geannuleerd"])},
      "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderd"])},
      "TODO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te behandelen"])},
      "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])}
    },
    "DELETION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijdering van de map"])},
      "CONTENT": {
        "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U staat op het punt om de"])},
        "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en de gegevens die het vormen"])},
        "QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet je zeker dat je deze map wilt verwijderen?"])},
        "QUESTION_MOTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u dit motief wilt verwijderen?"])}
      }
    },
    "EDITION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijwerken van de dossierstatus"])},
      "CONTENT": {
        "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U staat op het punt om"])},
        "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt niet langer gegevens invoeren in dit dossier"])},
        "QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u de status van het dossier wilt wijzigen?"])}
      },
      "STATE": {
        "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["starten"])},
        "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accepteren"])},
        "REFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weigeren"])},
        "WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wachten"])},
        "CANCELED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annuleren"])},
        "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verwijderen"])}
      }
    },
    "UPDATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status van het dossier bijgewerkt"])}
  },
  "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nr."])},
  "NOTIFICATIONS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldingen"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen meldingen."])},
    "NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw "])},
    "READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lezen "])},
    "MARK_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALLES ALS GELEZEN MARKEEREN"])},
    "DELETE_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALLES VERWIJDEREN"])},
    "DATE": {
      "TODAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VANDAAG"])},
      "YESTERDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GISTEREN"])},
      "OLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUD"])}
    },
    "TIMESTAMP": {
      "NOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zojuist"])},
      "PREFIX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geleden"])},
      "SUFFIX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "MINUTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minuut"])},
      "MINUTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minuten"])},
      "HOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uur"])},
      "HOURS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uren"])}
    },
    "ACTION": {
      "CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigen"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])}
    },
    "SERVICES": {
      "OPEN_BANKING": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking-analyse"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking Analyse - Analyse mislukt"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De analyse van de bankgegevens van"])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is voltooid."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is voltooid. Klik op de melding om het resultaat te bekijken."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een probleem opgetreden bij de OpenBanking analyse. Klik op de melding om het bestand te bekijken."])}
      },
      "LIVE_CHECK": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twijfelopheffing - Analyse voltooid"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twijfelopheffing - Analyse mislukt"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De twijfelopheffing van het dossier "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is voltooid."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is voltooid. Klik op de melding om het resultaat te bekijken."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een probleem opgetreden bij de twijfelopheffing. Klik op de melding om het dossier te bekijken."])}
      },
      "BATCH_CHECK_COMPANY": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsanalyse - Batch voltooid"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsanalyse - Batch mislukt"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bedrijfsanalyse batch "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is voltooid."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is voltooid."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bedrijfsanalyse batch heeft een probleem ondervonden."])}
      },
      "BATCH_CHECK_IBAN": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN-analyse - Batch voltooid"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN-analyse - Batch mislukt"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De IBAN-analyse batch "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is voltooid."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is voltooid."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De IBAN-analyse batch heeft een probleem ondervonden."])}
      },
      "BATCH_SCORING_PROXY": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring - Batch voltooid"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring - Batch mislukt"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De scoringanalyse batch "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is voltooid."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is voltooid."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De scoringanalyse batch heeft een probleem ondervonden."])}
      },
      "FACE_MATCHING": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biometrische controle - Analyse voltooid"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biometrische controle - Analyse mislukt"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De biometrische controle van het dossier "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is voltooid."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is voltooid. Klik op de melding om het resultaat te bekijken."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De biometrische controle heeft een probleem ondervonden. Klik op de melding om het dossier te bekijken."])}
      }
    }
  },
  "STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staat"])},
  "OF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["van"])},
  "THE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
  "SCORE_CREDIT_SAFE": {
    "RISK_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financieel risico"])},
    "PAYMENT_TREND_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingstrend"])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen data gevonden."])},
    "OTHER_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere aanvullende informatie"])},
    "PRESENCEOFJUDGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheid van oordeel"])},
    "PRESENCEOFPRIVILEGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheid van privilege"])},
    "PRESENCEOFSANCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheid van de DGCCRF-sanctie"])},
    "HEADBAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om meer te weten te komen over de financiële gezondheid van dit bedrijf, activeert u uw CreditSafe-abonnement"])},
    "RISK": {
      "WEAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laag financieel risico"])},
      "MODERATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gematigd financieel risico"])},
      "HIGH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoog financieel risico"])},
      "NOT_SCORED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet verifieerbaar"])}
    },
    "PAYMENT_TREND": {
      "WORSENING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verslechterende betalingstrend"])},
      "IMPROVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaaltrend verbetert"])},
      "STABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stabiele betalingstrend"])},
      "NOT_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingstrend niet beschikbaar"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet-verifieerbare betalingstrend"])}
    }
  },
  "MALICIOUS_PAYLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige invoergegevens"])},
  "RESET_API_KEY": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw API-sleutel"])},
    "WARNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt uw nieuwe API-sleutel slechts één keer bekijken"])},
    "CANNOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt uw API-sleutel niet meer bekijken. Neem contact op met uw referent of de ondersteuning van Meelo"])},
    "COPY_YOUR_KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw sleutel kopiëren"])}
  },
  "DQE_CONTROL": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overeenstemming van de telefoonlijn - Mobiele ID"])},
    "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden, de zoekopdracht kon niet worden voltooid."])},
    "NO_SEARCH_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit telefoonnummer kan momenteel niet door de operator worden geverifieerd."])},
    "MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overeenstemming"])},
    "KEY": {
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
      "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboortedatum"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postadres"])}
    },
    "LAUNCH_MATCHING_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De matching-zoekopdracht starten"])},
    "NO_GLOBAL_DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De controle is uitgevoerd, maar kan niet worden voltooid."])}
  },
  "MOTIVE": {
    "THIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motief"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer motief"])},
    "REASON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motief reden"])},
    "TABLE": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
      "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acties"])},
      "NODATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen bestaande reden."])}
    },
    "MODAL": {
      "CREATE_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reden toevoegen"])},
      "UPDATE_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reden bewerken"])},
      "DELETE_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reden verwijderen"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
      "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
      "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactief"])},
      "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])}
    },
    "TOAST": {
      "SUCCESS": {
        "CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patroon succesvol aangemaakt"])},
        "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patroon succesvol bewerkt"])},
        "DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patroon succesvol verwijderd"])}
      },
      "FAILED": {
        "CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij het aanmaken van het patroon"])},
        "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij het bewerken van het patroon"])}
      }
    }
  },
  "CHECK_LIST": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globale Synthese"])},
    "MEELO_RECOMMENDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo-aanbeveling"])},
    "RULES": {
      "credit-safe-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiële Score"])},
      "web-score-b2b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsvertrouwen Score"])},
      "smb-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identiteitsvertrouwen Score"])},
      "company-radiation-and-collective-proceedings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geregistreerd Bedrijf of in Collectieve Procedure"])},
      "id-document-analysis-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identiteitsdocumenten"])},
      "document-authenticity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticiteit van het Document"])},
      "check-iban-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "phone-carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risicotelefoon"])},
      "warning-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarschuwingslijst"])},
      "web-score-b2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identiteitsvertrouwen Score"])},
      "ob-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking-score"])}
    },
    "RECOMMENDATION": {
      "DECISION": {
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goed"])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld"])},
        "risky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risicovol"])}
      },
      "MESSAGE": {
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het dossier bevat geen waarschuwingen; de controles van de elementen van het dossier zijn uitgevoerd, en het is mogelijk om verder te gaan."])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het dossier bevat enkele waarschuwingen; wij raden aan voorzichtig te zijn en een versterkte controle uit te voeren (zoals terugbellen naar de werkgever, boekhouder)."])},
        "risky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het dossier bevat een belangrijke waarschuwing; het wordt sterk aanbevolen om een versterkte controle uit te voeren voordat u positief verder gaat met dit dossier."])}
      }
    },
    "NUMBER_OF_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de verificações realizadas"])}
  },
  "CLIENT_CONTEXT": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Context"])},
    "COMPANIES": {
      "MEELO": {
        "INIT": {
          "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
          "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
          "maidenName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meisjesnaam"])},
          "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanhef"])},
          "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboortedatum"])},
          "socialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socialezekerheidsnummer"])},
          "familySituation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familiale situatie"])},
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
          "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
          "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer en straat"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stad"])},
          "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
          "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
          "disabledWorker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehandicapte werker"])}
        },
        "CONSENT": {
          "CONSENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestemming"])}
        },
        "CLIENT": {
          "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
          "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
          "maidenName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meisjesnaam"])},
          "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanhef"])},
          "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboortedatum"])},
          "socialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socialezekerheidsnummer"])},
          "familySituation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familiale situatie"])},
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
          "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
          "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer en straat"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stad"])},
          "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
          "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
          "disabledWorker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehandicapte werker"])}
        }
      },
      "VORWERK": {
        "INIT": {
          "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
          "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
          "maidenName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meisjesnaam"])},
          "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanhef"])},
          "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboortedatum"])},
          "socialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socialezekerheidsnummer"])},
          "familySituation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familiale situatie"])},
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
          "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
          "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer en straat"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stad"])},
          "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
          "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
          "disabledWorker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehandicapte werker"])}
        },
        "CLIENT": {
          "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
          "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
          "maidenName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meisjesnaam"])},
          "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanhef"])},
          "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboortedatum"])},
          "socialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socialezekerheidsnummer"])},
          "familySituation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familiale situatie"])},
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
          "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
          "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer en straat"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stad"])},
          "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
          "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
          "disabledWorker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehandicapte werker"])}
        },
        "CONSENT": {
          "CONSENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestemming"])}
        }
      }
    }
  }
}