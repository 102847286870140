interface QueryParams {
  [key: string]: string | string[] | number | boolean | undefined;
}

export const createApiUrlWithParams = (baseUrl: string, params: QueryParams): string => {
  const queryString = Object.keys(params)
    .filter(key => params[key] !== undefined && params[key] !== null)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`)
    .join('&');
  return `${baseUrl}?${queryString}`;
};


