<template>
  <tracked-component>
    <div
      class="App"
      v-if="!isAuthUrl"
      :style="{
        '--sidebar-width': sidebarWidth + 'px',
      }"
    >
      <template v-if="!!kits">
        <Sidebar class="no-print" />
        <div :class="rightContentClassName">
          <InfoBar
            class="no-print"
            :is-maintenance="isMaintenance"
            :was-maintenance="wasMaintenance"
            :data="downServices"
            :key="maintenanceKey"
          />
          <HeaderBar
            :route="$route.path"
            class="logout no-print"
          />
          <RouterView />
        </div>
      </template>
    </div>
    <!-- LOGIN -->
    <router-view v-else />
  </tracked-component>
</template>

<script lang="ts">
import { jwtDecode } from 'jwt-decode';
import { defineComponent } from 'vue';
import { RouteRecordName } from 'vue-router';
import { mapActions, mapGetters } from 'vuex';
import connectAsApi from './apiCalls/connectAsApi';
import { isNull } from 'lodash';

import { getTokenFromUrlParams } from '@/utils/getTokenFromUrlParams';

import HeaderBar from '@/components/header/HeaderBar.vue';
import {
  default as MeeloHeader,
  default as Sidebar
} from '@/components/sidebar/Sidebar.vue';
import TrackedComponent from '@/components/tracking/TrackedComponent.vue';
import InfoBar from '@/components/bar/InfoBar.vue';
import { possibleServices } from '@/utils/services';
import { getAllAlerts } from './apiCalls/notificationApi';
import moment from 'moment';
import { INTERVAL } from './utils/notifications';
import { Alert } from './types/Alert';
import { Notification as NotificationInterface } from '@/utils/notifications';

import { ACCESS_TOKEN, handleUserLogout, setHttpCookie } from '@/utils/authUtils';

export default defineComponent({
  components: { InfoBar, Sidebar, MeeloHeader, HeaderBar, TrackedComponent },
  computed: {
    rightContentClassName(): string {
      return this.$route.path.startsWith('/back-office') &&
      (this.isMaintenance || this.wasMaintenance)
        ? 'right-content right-content-bar backoffice__right-content'
        : this.$route.path.startsWith('/back-office')
          ? 'right-content backoffice__right-content'
          : this.isMaintenance
            ? 'right-content right-content-bar'
            : this.wasMaintenance
              ? 'right-content right-content-bar'
              : 'right-content';
    },
    routeName(): RouteRecordName | undefined | null {
      return this.$route.name;
    },
    isAuthUrl(): boolean {
      return (
        this.$route.path.startsWith('/login') ||
        this.$route.path.startsWith('/reset-password') ||
        this.$route.path.startsWith('/forgot-password')
      );
    },
    ...mapGetters(['user', 'kits', 'permissionsSorted', 'maintenacesSevices'])
  },
  data() {
    return {
      sidebarWidth: 250,
      isMaintenance: false,
      wasMaintenance: false,
      downServices: [] as any[],
      downMessages: [] as string[],
      maintenanceTimeout: null as any,
      maintenanceInterval: null as any,
      maintenanceKey: null as any,
      notificationData: [] as NotificationInterface[]
    };
  },
  methods: {
    ...mapActions([
      'fetchUser',
      'clearResponseStatus',
      'setMaintenacesSevices'
    ]),
    async fetchAlerts() {
      if (['/reset-password', '/forgot-password', '/login', '/login/2fa', '/logout'].includes(window.location.pathname)) return;

      const alerts = await getAllAlerts();
      const validServices = new Set(possibleServices);

      // MAINTENANCE
      const currentServices = alerts?.data?.filter((alert: Alert) => {
        return (
          alert.type === 'MAINTENANCE' &&
          alert.service &&
          validServices.has(alert.service)
        );
      });
      const services = currentServices.map((alert: Alert) => alert);
      this.downServices = services.filter((service: Alert) =>
        validServices.has(service.service)
      );
      this.isMaintenance = services.length > 0;
    },
    getTokenFromUrlParams
  },
  created: async function() {
    document.title = 'Portail Meelo';
    const accessToken = getTokenFromUrlParams('access_token');
    const resetToken = getTokenFromUrlParams('reset_token');
    if (!resetToken) {
      if (!accessToken) {
        if (!['/reset-password', '/forgot-password', '/login', '/logout'].includes(window.location.pathname)) {
          await this.fetchUser();
          if (this.user) {
            if (this.$route.path.startsWith('/back-office') && !['COMPANY_MANAGER', 'COMPANY_ADMIN'].some((elt) => this.user.roles.includes(elt))) {
              if (!isNull(this.user.realUser)) await connectAsApi.logoutConnectAs();
              await handleUserLogout();
            }
          }

          this.maintenanceInterval = setInterval(async () => {
            await this.fetchAlerts();
          }, INTERVAL);
        }
        const connexionDate: Date = new Date(this.$cookies.get('connexion_date'));
        const today: Date = new Date();
        const diffMs: number = today.getTime() - connexionDate.getTime();

        const diffDays: number = Math.floor(diffMs / (1000 * 60 * 60 * 24)); // days
        const diffHrs: number = Math.floor(
          (diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ); // hours

        if (diffDays > 0 || diffHrs >= 2) {
          await handleUserLogout();
        }
      } else {
        await setHttpCookie(ACCESS_TOKEN, accessToken);
        await this.$router.push({ query: { ...this.$route.query, access_token: undefined } });
        await this.fetchUser();
      }
    }
  },
  async mounted() {
    const observer = new MutationObserver(() => {
      const elements = document.getElementsByClassName('sidebar');
      if (elements.length === 0) return;
      const sidebar = elements[0] as HTMLElement;
      this.sidebarWidth = sidebar.offsetWidth;
    });
    observer.observe(document, {
      attributes: true,
      childList: true,
      characterData: true,
      subtree: true
    });
    await this.fetchAlerts();
  },
  beforeUnmount() {
    clearInterval(this.maintenanceInterval);
    clearTimeout(this.maintenanceTimeout);
  },
  watch: {
    routeName(newVal) {
      if (!newVal) document.title = 'Portail Meelo';
      else document.title = `Portail Meelo - ${newVal}`;
    },
    '$route.query.journeyId': function() {
      this.clearResponseStatus();
    },
    isMaintenance(newVal, oldVal) {
      if (newVal) {
        this.wasMaintenance = true;
      } else if (!newVal && oldVal) {
        this.wasMaintenance = true;
        this.maintenanceTimeout = setTimeout(() => {
          this.wasMaintenance = false;
        }, 15000);
      } else {
        this.wasMaintenance = false;
      }
    },
    maintenacesSevices(services) {
      this.maintenanceKey = new Date().getTime();

      if (services && services.length > 0) {
        this.downServices = services;
        this.isMaintenance = true;
      } else {
        this.downServices = [];
        this.isMaintenance = false;
      }
    }
  }
});
</script>
<style lang="scss">
@import "@/assets/scss/style.scss";
@import "@/assets/scss/component/sidebar";

#app {
  font-family: $fontFamily;
  color: $blueMeelo;
  min-height: 100vh;
}

.App {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    overflow-y: scroll;
    z-index: 1;
  }

  .right-content {
    padding-top: 1.5rem;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: var(--sidebar-width);
    overflow-y: scroll;
  }

  .right-content-bar {
    padding-top: 0;
  }
}
</style>
