import * as _ from 'lodash';
import { KeyValueType } from '@/utils/sortArrayObjectKeys';

const createObjectFrom = ({
                            keys,
                            initialObject,
                            data,
                            keyFormatter
                          }: {
  keys: string[];
  initialObject: KeyValueType;
  data: KeyValueType;
  keyFormatter?: Function;
}) => {
  return keys.reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: keyFormatter ? data[keyFormatter(curr)] : data[curr]
    };
  }, initialObject);
};

const driving_license_checks = [
  'EMISSION_COUNTRY',
  'EMISSION_DATE',
  'VALIDITY_PERIOD',
  'MRZ_FIELDS_SYNTAX',
  'MRZ_CHECKSUMS',
  'MRZ_EXPECTED_FOUND',
  'MRZ_CLASSIFIER',
  'LAST_NAME',
  'DOCUMENT_NUMBER',
  'EXPIRATION_DATE',
  'OCR_EMISSION_DATE',
  'PERSONAL_NUM',
  'PHOTO_CONFORMITY',
  'PHOTO_LOCATION',
  'VISUAL_SECURITY',
  'OLD_DRIVING_LICENSE_DOCUMENT_NUMBER',
  'DRIVING_LICENSE_VALIDITY',
  'DRIVING_LICENSE_DIGITS',
  'AGE_AT_CERT',
  'PHOTOCOPY_DETECTION',
  'SCREENSHOT_DETECTION',
  'EXPIRATION_DATE_VALIDITY',
  'REFUSE_REASON'
];
const id_card_checks = [
  'EMISSION_COUNTRY',
  'EMISSION_DATE',
  'VALIDITY_PERIOD',
  'MRZ_FIELDS_SYNTAX',
  'MRZ_CHECKSUMS',
  'MRZ_EXPECTED_FOUND',
  'MRZ_ALIGNEMENT',
  'MRZ_CLASSIFIER',
  'FIRST_NAMES',
  'LAST_NAME',
  'BIRTHDATE',
  'DOCUMENT_NUMBER',
  'EXPIRATION_DATE',
  'OCR_EMISSION_DATE',
  'PERSONAL_NUM',
  'PHOTO_CONFORMITY',
  'PHOTO_LOCATION',
  'VISUAL_SECURITY',
  'BARCODE_EXPECTED_FOUND',
  'BARCODE_FIELDS_SYNTAX',
  'BARCODE_SIGNATURE',
  'PHOTOCOPY_DETECTION',
  'SCREENSHOT_DETECTION',
  'EXPIRATION_DATE_VALIDITY',
  'REFUSE_REASON'
];
const passport_checks = [
  'EMISSION_COUNTRY',
  'EMISSION_DATE',
  'VALIDITY_PERIOD',
  'MRZ_FIELDS_SYNTAX',
  'MRZ_CHECKSUMS',
  'MRZ_EXPECTED_FOUND',
  'MRZ_ALIGNEMENT',
  'MRZ_CLASSIFIER',
  'FIRST_NAMES',
  'LAST_NAME',
  'BIRTHDATE',
  'DOCUMENT_NUMBER',
  'EXPIRATION_DATE',
  'OCR_EMISSION_DATE',
  'PERSONAL_NUM',
  'PHOTO_CONFORMITY',
  'PHOTO_LOCATION',
  'VISUAL_SECURITY',
  'PHOTOCOPY_DETECTION',
  'SCREENSHOT_DETECTION',
  'EXPIRATION_DATE_VALIDITY',
  'REFUSE_REASON'
];
const resident_permit_checks = [
  'EMISSION_COUNTRY',
  'EMISSION_DATE',
  'VALIDITY_PERIOD',
  'MRZ_FIELDS_SYNTAX',
  'MRZ_CHECKSUMS',
  'MRZ_EXPECTED_FOUND',
  'MRZ_ALIGNEMENT',
  'MRZ_CLASSIFIER',
  'FIRST_NAMES',
  'LAST_NAME',
  'BIRTHDATE',
  'DOCUMENT_NUMBER',
  'EXPIRATION_DATE',
  'OCR_EMISSION_DATE',
  'PERSONAL_NUM',
  'PHOTO_CONFORMITY',
  'PHOTO_LOCATION',
  'VISUAL_SECURITY',
  'PHOTOCOPY_DETECTION',
  'SCREENSHOT_DETECTION',
  'EXPIRATION_DATE_VALIDITY',
  'REFUSE_REASON'
];
const RIB_checks = [
  'DOC_TYPE',
  'BANK_CODE',
  'BRANCH_CODE',
  'ACCOUNT_NUMBER',
  'IBAN',
  'BIC',
  'BANK_NAME',
  'BANK_ADDRESS',
  'KEY'
];
const KBIS_checks = [
  'IDENTIFICATION',
  'MAIN_ACTIVITY',
  'MEMBERS',
  'DOCUMENT_KBIS'
];
const KBIS_INDIVIDUAL_checks = [
  'COMPANY_DESCRIPTION',
  'ESTABLISHMENT_DESCRIPTION',
  'DOCUMENT_KBIS'
];
const tax_report_checks = ['INFO'];
const property_tax = [
  'INFO',
  'LEGAL_DEBTORS',
  'PAYMENT_INFO',
  'PROPERTIES_AND_BUILDINGS'
];
const income_tax = [
  'FAMILY_DETAILS',
  'FINANCIAL_DETAILS',
  'INFO',
  'PAYMENT_INFO'
];
const payslip_checks = ['IDENTITY', 'COMPANY_INFO', 'PAYMENT_INFO', 'AUTHENTICITY_STATUS'];

export const getKbisCheck = async ({
                                     initialChecks,
                                     data,
                                     documentId,
                                     journeyId
                                   }: {
  initialChecks: any;
  data: any;
  documentId: string;
  journeyId: string;
}) => {
  let checks = Object.assign({}, initialChecks);
  const { subDocType } = data.data.result;
  checks['DOC_TYPE'] = subDocType;

  checks = createObjectFrom({
    keys:
      subDocType === 'INDIVIDUAL_KBIS' ? KBIS_INDIVIDUAL_checks : KBIS_checks,
    data: data.data.result,
    initialObject: checks,
    keyFormatter: _.camelCase
  });

  checks['AUTHENTICITY_STATUS'] = data.data.authenticityStatus;

  return checks;
};

export const getPersonalDocCheck = (initialChecks: any, data: any) => {
  let checks = Object.assign({}, initialChecks);
  const docType = data.data.docType;
  checks['DOC_TYPE'] = docType;
  for (let i = 0; i < data.checks.length; i++) {
    if (data.checks[i].check === 'REFUSE_REASON') {
      checks['LIVE_CHECK_REFUSE_MESSAGE'] = data.checks[i].check.message;
    }

    if (
      docType === 'DRIVING_LICENSE' &&
      driving_license_checks.includes(data.checks[i].check)
    ) {
      checks[`${data.checks[i].check}`] = `${data.checks[i].status}`;
    } else if (
      docType === 'ID_CARD' &&
      id_card_checks.includes(data.checks[i].check)
    ) {
      checks[`${data.checks[i].check}`] = `${data.checks[i].status}`;
    } else if (
      docType === 'PASSPORT' &&
      passport_checks.includes(data.checks[i].check)
    ) {
      checks[`${data.checks[i].check}`] = `${data.checks[i].status}`;
    } else if (
      docType === 'RESIDENCE_PERMIT' &&
      resident_permit_checks.includes(data.checks[i].check)
    ) {
      checks[`${data.checks[i].check}`] = `${data.checks[i].status}`;
    }
  }
  return checks;
};

export const getTaxReportCheck = (initialChecks: any, data: any) => {
  let checks = Object.assign({}, initialChecks);
  checks['DOC_TYPE'] = _.toUpper(
    `${data.data.docType}_${data.data.result.info.taxReportType}`
  );
  checks = createObjectFrom({
    keys: tax_report_checks,
    initialObject: checks,
    data: data.data.result,
    keyFormatter: _.camelCase
  });

  checks['AUTHENTICITY_STATUS'] = data.data.authenticityStatus;

  return checks;
};

export const getTaxNoticeCheck = (initialChecks: any, data: any) => {
  let checks = Object.assign({}, initialChecks);
  if (data.data.result.subDocType === 'PROPERTY_TAX') {
    checks['DOC_TYPE'] = data.data.result.subDocType;
    checks = createObjectFrom({
      keys: property_tax,
      initialObject: checks,
      data: data.data.result,
      keyFormatter: _.camelCase
    });
  }
  if (data.data.result.subDocType === 'INCOME_TAX') {
    checks['DOC_TYPE'] = data.data.result.subDocType;
    checks = createObjectFrom({
      keys: income_tax,
      initialObject: checks,
      data: data.data.result,
      keyFormatter: _.camelCase
    });
  }

  checks['AUTHENTICITY_STATUS'] = data.data.authenticityStatus;

  return checks;
};

export const getBankDetailsCheck = (initialChecks: any, data: any) => {
  let checks = Object.assign({}, initialChecks);
  const _bankDetailsKeyFormatter = (key: string) => {
    return key === 'IBAN' || key === 'BIC' ? key : _.camelCase(key);
  };

  checks = createObjectFrom({
    keys: RIB_checks,
    data: data.data,
    initialObject: checks,
    keyFormatter: _bankDetailsKeyFormatter
  });

  checks['CHECKS'] = data.checks;
  checks['AUTHENTICITY_STATUS'] = data.data.authenticityStatus;

  return checks;
};

export const getPayslipCheck = (initialChecks: any, data: any) => {
  let checks = Object.assign({}, initialChecks);
  checks['DOC_TYPE'] = data.data.docType;
  checks = createObjectFrom({
    keys: payslip_checks,
    initialObject: checks,
    data: data.data.result,
    keyFormatter: _.camelCase
  });

  checks['AUTHENTICITY_STATUS'] = data.data.authenticityStatus;

  return checks;
};

export const displayAuthenticity = (authenticity: string) => {
  if (authenticity === 'FALSIFIED') {
    return 'failed-icon.png';
  } else if (authenticity === 'CONFIDENT') {
    return 'success-icon.png';
  } else if (authenticity === 'SUSPECT') {
    return 'warning-icon.png';
  } else {
    return 'null-icon.png';
  }
};

export const getAuthenticityCheck = (initialChecks: any, data: any) => {
  let checks = Object.assign({}, initialChecks);
  checks['AUTHENTICITY'] = displayAuthenticity(data?.authenticity);
  checks['REASONS'] = data?.reasons;
  checks['ENGLISH_REPORT'] = data?.pdfReport?.en;
  checks['FRENCH_REPORT'] = data?.pdfReport?.fr;
  checks['REDIRECT_URL'] = data?.redirectUrl;
  checks['REASONS_DETAILS'] = data?.reasonsDetails;
  checks['STATUS'] = data?.status;
  return checks;
};
