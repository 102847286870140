export enum TextInputType {
  TEXT,
  NOT_NULL,
  PHONE_NUMBER,
  EMAIL,
  DATE,
  SIREN,
  NATIONAL_REGISTRATION_NUMBER,
  TAX_IDENTIFICATION_CODE,
  LETTERS,
  IBAN,
  NAME,
  NUMBER,
  UPPER_CASE,
  LINKEDIN,
  FILE_NAME,
  SYNTHESIS_FILE_NAME
}

export function getRegex(inputType: TextInputType) {
  switch (inputType) {
    case TextInputType.TEXT:
      return /^.*$/;
    case TextInputType.NAME:
      return /^[A-Za-zéèêëàâäôöùûüçñÑáíóúÁÍÓÚ\-_' ]+$/;
    case TextInputType.NOT_NULL:
      return /^\+(?:[\s\S]*\S)?$/;
    case TextInputType.PHONE_NUMBER:
      return /^(?:(?:\+|00)(?:\d{1,4})?|0)?(?:0|\d{2}[\s.\-_]?|\d{3}[\s.\-_]?|\d{4}[\s.\-_]?)(?:\d{2}[\s.\-_]?){3}\d{2}$/;
    case TextInputType.EMAIL:
      return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    case TextInputType.DATE:
      return /^(^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$|^$)$/;
    case TextInputType.SIREN:
      return /^([0-9][ ]?){9}$/;
    case TextInputType.NATIONAL_REGISTRATION_NUMBER:
      return /^[01](\d[ ]?){8,9}$/;
    case TextInputType.TAX_IDENTIFICATION_CODE:
      return /^(?:[0-9]{8}[A-Z]|[ABCDEFGHJUV][0-9]{8})$/
    case TextInputType.LETTERS:
      return /^[A-Za-zàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ\s'-]{2,50}$/;
    case TextInputType.IBAN:
      return /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/;
    case TextInputType.NUMBER:
      return /^\d+(?:[.,]\d*)?$/;
    case TextInputType.UPPER_CASE:
      return /^[A-Z0-9_]+$/;
    case TextInputType.LINKEDIN:
      return /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm;
    case TextInputType.FILE_NAME :
      return /^[a-zA-ZÀ-ÿ0-9_%+\-]+$/;
    case TextInputType.SYNTHESIS_FILE_NAME :
      return /^[a-zA-ZÀ-ÿ0-9_%+ \-]+$/;
  }
}
