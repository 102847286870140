<script setup lang="ts">
import {
  formatCategory,
  formatDate,
  formatIcon,
  formatLabel, formatNumber,
  isBooked,
  transColorCode
} from "@/utils/openBankingUtils";
import {translate} from "@/i18n";


const props = defineProps({
  transactions: {type: Object, required: true}
});

// define the TH titles array
const titles: string[] = [
  ' ',
  'OPEN_BANKING.ALERTS.DATE',
  'OPEN_BANKING.CATEGORY',
  'OPEN_BANKING.LABEL',
  'OPEN_BANKING.ALERTS.AMOUNT'
];

function handleImageError(event: Event) {
  const target = event.target as HTMLImageElement;
  target.src = '/images/open-banking/outcome-other.png';
}
</script>

<template>
  <div class="container-openbanking-bank-statement-tablebox">
    <table>
      <thead>
      <tr>
        <th v-for="colsTitle in titles">
          {{ translate(colsTitle) }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, i) in transactions.value " :key="i">
        <td v-if="!isBooked(item.status)">
          <img src="/images/open-banking/hourglass.png" class="sand-icon" alt="hourglass.png"
               :title="translate('OPEN_BANKING.ACCOUNT_STATEMENT.PENDING')"/>
        </td>
        <td v-else></td>
        <td> {{ formatDate(item.date) }}</td>
        <td>
          <img :src="`/images/open-banking/${formatIcon(item.meelo_category.label)}.png`" class="category-icon"
               :alt="`${formatIcon(item.meelo_category.label)}.png`"
               :title="formatCategory(item.meelo_category.label)"
               @error="handleImageError"/>
          {{ formatCategory(item.meelo_category.label) }}
        </td>
        <td> {{ formatLabel(item.label) }}</td>
        <td :class="`${transColorCode(item.meelo_category.label)}`">
          {{ `${formatNumber(item.amount.value)} ${item.amount.currency_code}` }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>