<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { TreeNodeType } from "@/types/Tree";
import { isEmpty } from "lodash";
import { translate } from "@/i18n";

const props = defineProps<{
  node: TreeNodeType;
  toggleExpanded: boolean;
}>();

const isExpanded = ref(props.toggleExpanded);

const toggleExpand = () => {
  isExpanded.value = !isExpanded.value;
};

watch(() => props.toggleExpanded, (newVal) => {
  isExpanded.value = newVal;
});

const key = computed(() => {
  return props.node.key === 'null' ? '' : props.node.key;
});
const value = computed(() => {
  return props.node.value === 'null' ? '-' :
      props.node.value === 'true' ? translate("YES") :
          props.node.value === 'false' ? translate("NO") :
              props.node.value;
});
</script>

<template>
  <div class="tree-node">
    <div class="tree-node-parent" @click="toggleExpand">
      <img v-if="!isEmpty(props.node.children)" class="expand-icon" :src="isExpanded ? '/images/expand-down.png' : '/images/expand-right.png'" alt="expand icon" />
      <span class="key">{{ key }}</span>
      <span class="value">{{ value }}</span>
    </div>
    <div class="tree-node-child" v-if="isExpanded">
      <div v-for="child in node.children" :key="child.id">
        <TreeNode :node="child" :toggleExpanded="props.toggleExpanded"/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.tree-node {
  .tree-node-parent {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: .1rem;
    border: .06rem solid transparent;
    border-radius: .3rem;
    transition: background-color 0.3s, border-color 0.3s;

    &:hover {
      background-color: #e0e0e0;
      border-color: #bdbdbd;
    }

    .expand-icon {
      width: 1rem;
      height: 1rem;
      margin-right: .5rem;
    }

    .key {
      font-weight: bold;
      margin-right: .5rem;
    }

    .value {
      color: #757575;
    }
  }

  .tree-node-child {
    margin-left: 1.5rem;
    border-left: .06rem solid #bdbdbd;
    padding-left: .5rem;
  }
}
</style>