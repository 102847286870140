import {useRoute} from "vue-router";
import {computed, ComputedRef, ref} from "vue";
import {getJourneyIdDetails} from "@/apiCalls/taskApi";
import {Details} from "@/types/JourneyId";

export function useJourneyId() {

  const route = useRoute();

  const details = ref<Details>();

  const getJourneyId = computed(() => {
    return route.query.journeyId as string;
  });

  const getDetailsForJourneyId = async (journeyId: string) => {
    const response = await getJourneyIdDetails(journeyId);
    if (response.status === 200) {
      details.value = response.data;
    }
  }

  return {getJourneyId, getDetailsForJourneyId, details};
}