export default {
  "ACCOUNT_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi cuenta"])},
    "GENERAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información general"])},
    "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa"])},
    "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de usuario"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "PHONE_NUMBER_NOT_SPECIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No especificado"])},
    "TWO_FACTOR_AUTHENTICATION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de autenticación de dos factores"])},
      "REQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requerido por la empresa"])},
      "CHECKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificado"])},
      "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar mis datos"])},
      "MODE": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo de autenticación"])},
        "SMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
        "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
        "DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivado"])}
      }
    },
    "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis datos"])}
  },
  "LOGIN_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu correo electrónico"])},
    "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu contraseña"])},
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las credenciales no coinciden con ninguna cuenta"])},
    "ATTEMPTS_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intentos consecutivos incorrectos bloquearán su cuenta."])},
    "LOCKED_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su cuenta ha sido bloqueada."])},
    "FORGOT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olvidé mi contraseña"])},
    "TWO_FACTOR_AUTHENTICATION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticación de dos factores"])},
      "ALERT_MESSAGE_CHECKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta acción reemplazará tu método de autenticación de dos factores anterior. ¿Estás seguro de que deseas continuar?"])},
      "ALERT_MESSAGE_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta acción activará la autenticación de dos factores."])},
      "ALERT_MESSAGE_DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto deshabilitará la autenticación de dos factores."])},
      "DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivar la autenticación de dos factores"])},
      "DISABLE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes desactivar la autenticación de dos factores. Sin embargo, recomendamos dejar esta función activada para aumentar la seguridad de tu cuenta."])},
      "COMPANY_2FA_REQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Díganos qué método de autenticación le gustaría utilizar."])},
      "EMAIL_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se te enviará un código de verificación a tu dirección de correo electrónico."])},
      "SMS_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se te enviará un código de verificación por SMS. Por favor, proporciona tu número de teléfono:"])},
      "TITLE_MODAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de autenticación de dos factores"])},
      "MESSAGE_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has recibido un código"])},
      "FINALIZATION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para completar la autenticación de dos factores, ingresa el código que se te ha enviado."])}
    }
  },
  "PRIVATE_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo privado"])},
  "BUSINESS_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo de empresa"])},
  "OPEN_BANKING_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo Open Banking"])},
  "DOCUMENT_SYNTHESIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Síntesis del documento"])},
  "FILE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de archivo"])},
  "SYNTESIS_DOWNLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarga de Síntesis"])},
  "DOWNLOADING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargando..."])},
  "RESET_PASSWORD_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer contraseña"])},
    "INIT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicializar contraseña"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu correo electrónico"])},
    "FORGOT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Olvidaste tu contraseña?"])},
    "NEW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña"])},
    "CONFIRM_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar nueva contraseña"])},
    "RESET_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer contraseña"])},
    "SEND_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar correo electrónico de restablecimiento"])},
    "SUCCESS_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha enviado un correo electrónico de restablecimiento de contraseña."])},
    "EMAIL_NOT_SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo enviar el correo electrónico."])},
    "INVALID_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña debe tener al menos 8 caracteres, contener una mayúscula, una minúscula, un número y un carácter especial."])},
    "PASSWORD_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las contraseñas no coinciden."])},
    "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu contraseña ha sido restablecida correctamente."])},
    "PASSWORD_RESET_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error al restablecer su contraseña."])}
  },
  "CGS": {
    "BEGINNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al continuar, aceptas la"])},
    "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["política de privacidad"])},
    "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de Meelo"])}
  },
  "IDENTITY": {
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "FIRSTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento"])},
    "PLACE_OF_BIRTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lugar de nacimiento"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono móvil"])},
    "LASTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "USAGE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de uso"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
  },
  "ADDRESS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "TITLE_POSTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección postal"])},
    "NUMBER_AND_STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número y nombre de calle"])},
    "ZIP_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
    "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad"])},
    "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])}
  },
  "COMPANY": {
    "NO_REPRESENTATIVES_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se conocen líderes y representantes"])},
    "NO_BUSINESS_OWNERS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se conocen accionistas y propietarios"])},
    "LIQUIDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidación"])},
    "RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radiación"])},
    "SIREN_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificación"])},
    "ORIGIN": {
      "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francia"])},
      "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bélgica"])},
      "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
      "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austria"])},
      "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alemania"])},
      "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinamarca"])},
      "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonia"])},
      "EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglaterra"])},
      "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finlandia"])},
      "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reino Unido"])},
      "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irlanda"])},
      "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italia"])},
      "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lituania"])},
      "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letonia"])},
      "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países Bajos"])},
      "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noruega"])},
      "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polonia"])},
      "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
      "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suecia"])}
    },
    "REGISTRATION_NUMBER": {
      "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificación de la empresa o número SIREN"])},
      "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de registro nacional o Número de IVA"])},
      "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de Identificación Fiscal"])}
    },
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificación"])},
    "SIRET_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número SIRET"])},
    "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
    "MAIN_ACTIVITY_DECLARED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad principal declarada"])},
    "ACTIVITY_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código NAF o APE"])},
    "INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de la empresa"])},
    "CREATED_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de creación"])},
    "CORPORATE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denominación social"])},
    "LEGAL_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma jurídica"])},
    "CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital social"])},
    "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identidad de la empresa"])},
    "BUSINESS_OWNERS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de beneficiarios efectivos"])},
    "BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiarios efectivos conocidos"])},
    "DIRECT_BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiario directo"])},
    "INDIRECT_BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiario indirecto a través de persona jurídica"])},
    "IN_POSITION_SINCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En posición desde el"])},
    "MOTHBALLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puesta en reposo"])},
    "CURRENT_PROCEDURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedimientos en curso"])},
    "COLLECTIVE_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedimientos colectivos"])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-reputación"])},
    "GLOBAL_NOTE_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota global"])},
    "ADVICE_NOTE_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de opiniones"])},
    "WORDING_CLOUD_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurrencia de palabras en opiniones"])},
    "ADJECTIVES_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opiniones positivas / Opiniones negativas en opiniones"])},
    "WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio web"])},
    "NUMBER_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de establecimientos"])},
    "ESTABLISHMENTS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de establecimientos"])},
    "REPRESENTATIVES_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de representantes"])},
    "REPRESENTATIVES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representantes"])},
    "REPRESENTATIVES_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directivos y representantes conocidos"])},
    "BUSINESS_OWNERS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accionistas y beneficiarios conocidos"])},
    "RECOMMENDED_OUTSTANDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo recomendado"])},
    "HEADQUARTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sede social"])},
    "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posición"])},
    "TOTAL_SHARE_HOLDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participación total en acciones"])},
    "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País de origen de la empresa"])},
    "TYPE_PERSON": {
      "NATURAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona física"])},
      "LEGAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona jurídica"])}
    },
    "FUNCTIONS": {
      "PRESIDENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presidente"])},
      "CHAIRMAN_OF_THE_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presidente del consejo de administración"])},
      "CHAIRMAN_AND_CHIEF_EXECUTIVE_OFFICER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presidente Director General"])},
      "MANAGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerente"])},
      "NON_ASSOCIATE_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerente no asociado"])},
      "CO_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co-gerente"])},
      "MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director general"])},
      "GENERAL_MANAGER_NOT_A_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director general no administrador"])},
      "SOLE_MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director general único"])},
      "CHIEF_OPERATING_OFFICER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director general delegado"])},
      "VICE_CHAIRMAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vicepresidente"])},
      "DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador"])},
      "DEPUTY_ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador delegado"])},
      "CHAIRMAN_OF_THE_EXECUTIVE_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presidente del directorio"])},
      "CHAIRMAN_OF_THE_SUPERVISORY_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presidente del consejo de vigilancia"])},
      "MANAGING_PARTNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socio-gerente"])},
      "CONTROLLER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlador de gestión"])},
      "STATUTORY_AUDITOR_TITULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisario de cuentas - titular"])},
      "STATUTORY_AUDITOR_DEPUTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisario de cuentas - suplente"])},
      "STATUTORY_AUDITOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisario de cuentas"])},
      "LIQUIDATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidador"])},
      "PROVISIONAL_ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador provisional"])},
      "REPRESENTATIVE_IN_FRANCE_OF_A_FOREIGN_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representante en Francia de una sociedad extranjera"])},
      "MEMBER_OF_THE_BOARD_OF_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miembro del comité de administración"])},
      "CHAIRMAN_OF_THE_BOARD_OF_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presidente del comité de administración"])}
    },
    "TYPE": {
      "SOCIETY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sociedad"])},
      "COLLECTIVE_INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inversión colectiva"])},
      "GIE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo de interés económico"])}
    },
    "OF_SHARES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de participaciones"])},
    "OF_VOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de votos"])},
    "LEGAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información legal"])},
    "WORKFORCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
    "VAT_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de IVA"])},
    "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de cierre"])},
    "ACTIVITY_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de actividad"])},
    "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
    "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivo"])},
    "UNVERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No domiciliado"])},
    "RECOMMENDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendación"])},
    "FIELD_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Área de actividad"])},
    "ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establecimientos"])},
    "OFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sede"])},
    "IN_OPERATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En funcionamiento"])},
    "CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrado"])},
    "FINANCES": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzas de la empresa"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay datos financieros disponibles"])},
      "RATIOS": {
        "grossMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margen bruto (€)"])},
        "netMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margen neto (€)"])},
        "valueAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor añadido (€)"])},
        "ebitda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado bruto de explotación (€)"])},
        "ebit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado de explotación (€)"])},
        "profitBeforeTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado ordinario antes de impuestos (€)"])},
        "extraordinaryResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado extraordinario (€)"])},
        "netIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado neto (€)"])},
        "shortTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deudas a corto plazo (€)"])},
        "longMidTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deudas a largo y medio plazo (€)"])},
        "turnover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturación (€)"])},
        "balanceSheetTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total del balance (€)"])},
        "capitalAndReserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital y reservas (€)"])},
        "workingCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital circulante (€)"])},
        "workingCapitalRequirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necesidad de capital circulante (€)"])},
        "financialLiquidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidez financiera (€)"])},
        "intangibleAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos intangibles (€)"])},
        "tangibleFixedAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos fijos tangibles (€)"])},
        "participatingInterestsAndRelatedDebtsReceivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participaciones e intereses relacionados y deudas por cobrar (€)"])},
        "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventario (€)"])},
        "othersDebtsReceivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otras cuentas por cobrar (€)"])},
        "tradeDebtorsAndRelatedAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuentas por cobrar comerciales y cuentas relacionadas (€)"])},
        "financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiero (€)"])},
        "chargesCa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargas operativas (€)"])},
        "appropriationsToDepreciationAndProvisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignaciones a depreciación y provisiones (€)"])},
        "totalExtraordinaryIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresos extraordinarios totales (€)"])},
        "totalExtraordinaryCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargas extraordinarias totales (€)"])},
        "corporateTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuesto de sociedades (€)"])},
        "operatingLiabilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasivos operativos (€)"])},
        "capital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital (€)"])},
        "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserva (€)"])},
        "profitOrLossForTheFinancialYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado del ejercicio (€)"])},
        "investmentGrantsTaxRegulatedProvisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subvenciones a la inversión y provisiones fiscales reguladas (€)"])},
        "loansAndDebtsPayableToCreditInstitutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préstamos y deudas pagaderos a instituciones de crédito a medio y largo plazo (€)"])},
        "loansAndSundryFinancialDebtsPayable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préstamos y otras deudas financieras pagaderas (€)"])},
        "changeInStocksIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio en ingresos por existencias (€)"])},
        "purchasesOfGoodsForResale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compras de bienes para la reventa (€)"])},
        "changeInStocksCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio en los costos de existencias (€)"])},
        "otherExternalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otros servicios externos (€)"])},
        "taxesLeviesAndSimilarPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuestos, tasas y pagos similares (€)"])},
        "personnelCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costos de personal (€)"])},
        "otherCurrentOperatingCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otros gastos operativos actuales (€)"])},
        "financialIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresos financieros (€)"])},
        "financialCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos financieros (€)"])},
        "fpTb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fondos propios sobre el total del balance (%)"])},
        "supplierDeadlines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plazos de proveedores (días)"])},
        "customerDeadlines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plazos de clientes (días)"])},
        "caf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacidad de autofinanciamiento (€)"])},
        "rnCa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margen neto sobre la facturación (%)"])},
        "etp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equivalente a tiempo completo"])},
        "dividends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividendos pagados a accionistas (€)"])},
        "financialBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo financiero (€)"])},
        "salesGrowthRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasa de crecimiento de las ventas (%)"])},
        "grossMarginRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasa de margen bruto (%)"])},
        "ebitdaMarginRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasa de margen EBITDA (%)"])},
        "operatingMarginRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasa de margen operativo (%)"])}
      },
      "BLOCKS": {
        "intermediateOperationTotals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totales intermedios de operación"])},
        "debts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deudas"])},
        "balanceSheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
        "fixedAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos fijos"])},
        "currentAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos corrientes"])},
        "profitAndLossAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de resultados"])},
        "balancePassive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasivo del balance"])},
        "capitalAndReserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital y reservas"])},
        "operatingIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresos de operación"])},
        "operatingCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costos de operación"])},
        "operatingResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado de operación"])},
        "ratios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratios financieros"])},
        "increase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crecimiento"])}
      }
    },
    "LEGAL_DOCUMENT": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentos legales"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay documentos legales disponibles"])},
      "NO_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para consultar el documento, por favor descárguelo."])}
    },
    "BODACC_ANNOUNCEMENT": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evolución de la empresa"])},
      "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
      "NATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naturaleza"])},
      "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información"])},
      "DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalle"])}
    },
    "ANNUAL_ACCOUNTS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuentas anuales"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay cuentas anuales disponibles"])},
      "COMPANY_FINANCIAL_STATEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuentas sociales"])}
    },
    "CHECKS": {
      "MORE_THAN_ONE_TAX_REPORT_WITH_SAME_SUBMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de un informe fiscal presentado en la misma fecha"])},
      "LESS_THAN_X_YEARS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa con menos de 3 años"])},
      "MANAGEMENT_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio de dirección menor de 3 años"])},
      "SHARE_CAPITAL_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio de capital en menos de 3 años"])},
      "HEADQUARTER_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traslado de sede en menos de 3 años"])},
      "ACTIVITY_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio de actividad en menos de 3 años"])},
      "MULTI_MANAGEMENT_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de 2 cambios en la gerencia en los últimos 10 años"])},
      "HAS_CESSATION_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cesación de actividad"])},
      "HAS_ALL_ESTABLISHMENTS_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los establecimientos están cerrados"])},
      "HAS_ACTIVITY_CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de dos cambios de actividad al menos en los últimos 10 años"])},
      "HAS_HEADQUARTER_CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de dos traslados de sede en los últimos 10 años"])},
      "LOCATION_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubicación de riesgo"])},
      "DOMICILIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa domiciliada"])},
      "WEBSITE_DOMAIN_REGISTRATION_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de registro de dominio del sitio web"])},
      "EMAIL_DOMAIN_REGISTRATION_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de registro de dominio de correo electrónico"])},
      "EMAIL_DOMAIN_EQUALS_WEBSITE_DOMAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coincidencia entre el dominio de correo electrónico y el dominio del sitio web"])},
      "RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radiación"])},
      "COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedimientos colectivos"])},
      "OTHER_COMPANIES_IN_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otra empresa en procedimientos colectivos"])},
      "OTHER_COMPANIES_IN_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otra empresa radiada"])},
      "ACTIVITIES_CODE_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sector de actividad riesgoso"])},
      "HEADQUARTER_DISTANCE_TOO_HIGH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia importante entre la dirección de la sede y el domicilio del gerente"])},
      "LEGAL_SITUATION_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presencia de litigios"])},
      "MORE_THAN_THREE_YEARS_WITHOUT_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de inactividad superior a 3 años"])},
      "MULTI_HEADQUARTER_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de 2 traslados de sede en los últimos 10 años"])},
      "MULTI_ACTIVITY_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de 2 cambios de actividad en los últimos 10 años"])},
      "CESSATION_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cese de actividad"])},
      "ALL_ESTABLISHMENTS_ARE_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los establecimientos están cerrados"])},
      "CONTACTABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactabilidad de datos"])},
      "HEADQUARTER_ADDRESS_IN_WARNING_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraude detectado en esta dirección"])},
      "MULTI_REGISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de un registro desde la creación"])},
      "MORE_THAN_ONE_ESTABLISHMENT_DOMICILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos un establecimiento está domiciliado"])},
      "MORE_THAN_ONE_TAX_REPORT_WITH_SUBMISSION_DATE_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de un informe fiscal con una fecha de presentación cerrada"])},
      "MORE_THAN_ONE_TAX_REPORT_WITH_SAME_SUBMISSION_DATE_AND_YEAR_DIFFERENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de un informe fiscal con la misma fecha de presentación y un año diferente"])},
      "MORE_THAN_TEN_TAX_REPORTS_WITH_DIFFERENT_SUBMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de diez informes fiscales con fechas de presentación diferentes"])},
      "RECENT_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedimientos colectivos recientes"])},
      "OTHER_COMPANIES_IN_SAME_ACTIVITY_SECTOR_IN_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otras empresas en el mismo sector de actividad en procedimientos colectivos"])},
      "ALREADY_SOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa ya vendida"])},
      "ALREADY_DEREGISTERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa ya dada de baja"])}
    },
    "DOMICILIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domicilio"])},
    "BRANCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sucursal"])},
    "CHECK_EMPLOYER": {
      "NO_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha podido verificar la información"])},
      "FALSE_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La empresa no está excluida de cotización"])},
      "TRUE_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La empresa deja de cotizar"])},
      "COLLECTIVE_PROCEEDING": {
        "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han detectado procedimientos colectivos pendientes"])},
        "MORE_THAN_ONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uno o más procedimientos colectivos pendientes detectados"])},
        "RECENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uno o más procedimientos colectivos en curso detectados, incluido al menos uno reciente (< 3 años)"])}
      }
    }
  },
  "ERRORS": {
    "MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Oops! Parece que hay un problema. Nuestros equipos ya están trabajando en su resolución. Por favor, inténtalo de nuevo más tarde."])},
    "NOT_FOUND_SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de identificacióningresado no existe."])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ninguna información recuperada"])},
    "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestra herramienta de procesamiento de declaración de impuestos no admite documentos anteriores a 2018."])},
    "TAX_REPORT_TOO_OLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaración de impuestos demasiado antigua."])},
    "DOCUMENT_PROCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se produjo un error durante la lectura del documento."])},
    "COMPANY_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de identificación ingresado no está vinculado a ninguna empresa activa."])},
    "ENRICHMENT_PROCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" No se encontró ninguna información."])},
    "ERROR_OCCURRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se produjo un error, por favor intente nuevamente más tarde"])},
    "NO_DATA_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron datos, por favor inicie un nuevo análisis"])},
    "CALL_FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llamada finalizada, tenga en cuenta que algunos datos no están accesibles. Para obtener un informe completo, inicie un nuevo análisis"])},
    "SIREN_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis completado, no se encontraron datos con el número de identificación proporcionado"])},
    "CREDIT_SAFE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se recuperaron datos"])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay datos disponibles"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontramos un error con el número de teléfono ingresado. Por favor verifique la información ingresada"])},
    "UNPROCESSABLE_CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha ocurrido un error, por favor intente nuevamente en una nueva carpeta o contacte con el soporte"])},
    "CANNOT_ACCESS_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puedes acceder a esta carpeta."])}
  },
  "BUTTONS": {
    "VALIDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar"])},
    "VERIFY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar"])},
    "CONNEXION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexión"])},
    "VALIDATE_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar Información"])},
    "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])},
    "VERIFY_IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de Documentos"])},
    "START_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar Open Banking"])},
    "JOURNEY_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completa la jornada de Open Banking en el navegador"])},
    "FINISH_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar Análisis"])},
    "NEW_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar un Nuevo Análisis"])},
    "EXPORT_PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar Resumen"])},
    "PREVIOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
    "DETAILS_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del Análisis"])},
    "VERIFY_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar Dirección"])},
    "SEND_TEXT_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar SMS"])},
    "SEND_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar Correo Electrónico"])},
    "BACK_HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a la Página Principal"])},
    "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML/CFT"])},
    "VALIDATE_AND_SEND_SMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar y Enviar SMS"])},
    "VALIDATE_AND_SEND_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar y Enviar Correo Electrónico"])},
    "IFRAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iframe"])},
    "RUN_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejecutar Análisis"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de IBAN"])},
    "FRAUD_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación"])},
    "SCORE_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuaciones de Fraude"])},
    "SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar"])},
    "SELECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionado"])},
    "CONSULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultar"])},
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elegir"])},
    "DEACTIVATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivar"])},
    "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
    "ENGLISH_VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versión en Inglés"])},
    "FRENCH_VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versión en Francés"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "UPLOAD_HERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir aquí"])},
    "MY_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi empresa"])},
    "MY_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis usuarios"])},
    "CONSUMPTION_MONITORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoreo de Consumo"])},
    "EXTRACT_MEMORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraer Memoria"])},
    "RULES_ENGINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motor de Reglas"])},
    "BATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lote"])},
    "MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi cuenta"])},
    "LINK_TEXT_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace por SMS"])},
    "NEW_REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva solicitud"])},
    "RESTART_REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reiniciar la solicitud"])},
    "LINK_BROWSER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace del navegador"])},
    "LINK_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace por correo electrónico"])},
    "CREATE_A_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un usuario"])},
    "UNBLOCK_A_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desbloquear un usuario"])},
    "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
    "ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir"])},
    "CONFIRM_AND_CONTINUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar y continuar"])},
    "RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer"])},
    "MODIFY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar"])},
    "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecimiento de contraseña"])},
    "ACTIVATE_DEACTIVATE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar / Desactivar cuenta"])},
    "RESETTING_API_KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecimiento de clave de API"])},
    "DELETE_THE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar la cuenta"])},
    "RETURN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regreso"])},
    "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "CONSULT_FINOVOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultar el análisis de Finovox"])},
    "LINKEDIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a LinkedIn"])},
    "DEPLOY_EVERYTHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desplegar todo"])},
    "REDUCE_EVERYTHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desplegar todo"])},
    "CLOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
    "SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "SCORE_SMB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de Identidad Profesional"])},
    "SCORE_COMPANY_FRAUD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de Confianza de la Empresa"])},
    "SELECT_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar todo"])},
    "MY_TEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi equipo"])},
    "DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
    "COMPLETE_UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completar la subida"])},
    "LIVE_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminación de dudas"])},
    "COMPANY_MOTIVES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivos de la empresa"])}
  },
  "APPLICANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitante"])},
  "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
  "VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactable"])},
  "LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculado"])},
  "MAIL_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuentas de correo vinculadas"])},
  "PHONE_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuentas de teléfono vinculadas"])},
  "APPLICANT_RESULTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados del solicitante"])},
  "CONTACT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de contacto"])},
  "CLIENT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del cliente"])},
  "VERIFICATION_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de documento"])},
  "VERIFICATION_TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de aviso de impuestos"])},
  "CONFIDENCE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de confianza en la identidad"])},
  "SMB_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de confianza en la identidad de la empresa"])},
  "CONFIDENCE_INDEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Índice de confianza en la identidad"])},
  "DETAILED_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la puntuación"])},
  "CONFIDENCE_EXPLICATIONS": {
    "UNDER_TWENTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos proporcionados no relacionados con la persona"])},
    "BETWEEN_TWENTY_AND_FIFTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos sospechosos, se recomienda verificar la identidad"])},
    "ABOVE_FIFTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos proporcionados existentes y relacionados con la persona"])}
  },
  "DOCUMENT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de documentos"])},
  "ANALYSIS_RESULT_RETRIEVAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperando resultados de análisis en curso ..."])},
  "UPLOAD_FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carga de documento finalizada, puede cerrar esta ventana."])},
  "FACE_MATCHING_LINK_CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se le ha enviado un enlace por SMS, verifique su teléfono"])},
  "LINK_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vínculo con la empresa"])},
  "IDENTITY_CONSISTENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coherencia de identidad"])},
  "COMPANY_CONSISTENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coherencia con la empresa"])},
  "OTHER_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otra persona"])},
  "SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situación"])},
  "NOT_FOUND": {
    "NO_BUSINESS_OWNERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron beneficiarios efectivos conocidos."])},
    "NO_REPRESENTATIVES_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron representantes conocidos."])},
    "NO_LEADERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron líderes recuperados..."])}
  },
  "OPEN_BANKING": {
    "END_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos se han transmitido correctamente. Puedes cerrar esta página para ver el resultado del análisis."])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking"])},
    "RESULT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de OpenBanking"])},
    "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis detallado de OpenBanking"])},
    "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del banco"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "INCOMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresos"])},
    "OUTCOMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos"])},
    "HOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titular"])},
    "ACCOUNT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la cuenta"])},
    "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salario mensual promedio"])},
    "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alquiler mensual promedio"])},
    "BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo actual"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, pide a tu cliente que tenga su teléfono móvil y sus datos bancarios a mano."])},
    "CATEGORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])},
    "FINANCIAL_ANALYSIS_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de análisis financiero"])},
    "ANALYSIS_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período analizado"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
    "CATEGORIES": {
      "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto máximo de transacciones"])},
      "MONTH_MAX_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de la transacción máxima"])},
      "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto promedio mensual total"])},
      "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto promedio mensual - Salarios"])},
      "MONTH_SALARIES_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha promedio mensual - Salarios"])},
      "MONTH_INTERESTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto promedio mensual - Intereses"])},
      "MONTH_TRANSFERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto promedio mensual - Transferencias"])},
      "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto promedio mensual - Alquileres"])},
      "MONTH_WITHDRAWALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto promedio mensual - Retiros en cajeros"])},
      "MONTH_INSURANCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto promedio mensual - Seguros"])},
      "MONTH_UTILITIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto promedio mensual - Servicios públicos"])},
      "MONTH_GROCERIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto promedio mensual - Compras de alimentos"])},
      "MONTH_FOOD_CONSUMPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto promedio mensual - Consumo de alimentos"])},
      "MONTH_SHOPPINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto promedio mensual - Compras"])},
      "MONTH_HEALTH_WELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto promedio mensual - Salud y bienestar"])},
      "MONTH_TRANSPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto promedio mensual - Transporte"])},
      "MONTH_TELECOMMUNICATION_SUBSCRIPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto promedio mensual - Suscripciones de telecomunicaciones"])},
      "MONTH_AUDIO_VISUAL_SUBSCRIPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto promedio mensual - Suscripciones de audiovisual"])},
      "MONTH_INCOMES_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promedio mensual de ingresos"])},
      "MONTH_INCOMES_SALARIES_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promedio mensual de salarios"])},
      "MONTH_INCOMES_ALLOWANCES_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promedio mensual de subsidios"])},
      "MONTH_INCOMES_PENSIONS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promedio mensual de pensiones"])},
      "MONTH_INCOMES_AMOUNT_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promedio mensual más alto"])},
      "MONTH_INCOMES_AMOUNT_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promedio mensual más bajo"])},
      "MONTH_OUTCOMES_RENTS_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promedio mensual de alquileres"])},
      "MONTH_OUTCOMES_CREDITS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promedio mensual de créditos"])},
      "MONTH_OUTCOMES_CREDITS_DATE_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha promedio mensual de vencimiento de créditos"])},
      "MONTH_OUTCOMES_PENSIONS_DATE_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de pago de pensión"])},
      "RECOMMENDED_DEBIT_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha óptima para el débito"])},
      "RECOMMENDED_DEBIT_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto óptimo para el débito"])},
      "MONTHLY_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto mensual"])},
      "MONTH_OUTCOMES_RENTS_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto mínimo de alquileres"])},
      "MONTH_OUTCOMES_RENTS_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto máximo de alquileres"])},
      "MONTH_OUTCOMES_UTILITIES_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto mínimo de servicios"])},
      "MONTH_OUTCOMES_UTILITIES_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto máximo de servicios"])},
      "MONTH_OUTCOMES_CREDITS_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto mínimo de créditos"])},
      "MONTH_OUTCOMES_CREDITS_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto máximo de créditos"])},
      "MONTH_OUTCOMES_CREDITS_COUNT_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número mínimo de débitos al mes"])},
      "MONTH_OUTCOMES_CREDITS_COUNT_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número máximo de débitos al mes"])},
      "BANK_CARD_AND_DEBITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta(s) bancaria(s) y débitos"])},
      "DEBITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débitos"])},
      "BANK_CARD_PRESENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta(s) bancaria(s) vinculada(s)"])},
      "BANK_CARD_QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de tarjetas que han realizado transacciones salientes"])},
      "BANK_CARD_NUMBERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Números de tarjeta identificados en transacciones salientes"])},
      "MONTH_NEGATIVE_BALANCE_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha más temprana"])},
      "MONTH_NEGATIVE_BALANCE_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha más tardía"])},
      "MAX_BALANCE_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha mínima mensual más temprana en el mes con el saldo máximo"])},
      "LAST_SALARY_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha del último salario"])},
      "MONTH_END_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porcentaje de gastos sobre ingresos"])},
      "MONTH_NEGATIVE_BALANCE_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha promedio de caída en el rojo de la cuenta"])},
      "AVERAGE_MONTHLY_ENTRIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entradas mensuales promedio"])},
      "TOTAL_FOR_THE_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total para el período"])},
      "AVERAGE_MONTHLY_TAX_REVENUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresos fiscales mensuales promedio"])},
      "AVERAGE_MONTHLY_EXITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salidas mensuales promedio"])},
      "ANNUAL_NET_INCOME_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto del ingreso neto anual"])},
      "ANNUAL_REVENUE_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto del ingreso anual"])},
      "MONTHLY_TAX_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto mensual de impuestos"])},
      "AVERAGE_MONTHLY_PAYROLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promedio mensual de la masa salarial"])},
      "OTHER_ACCOUNTS": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otras cuentas"])},
        "MONTH_INCOMES_SAVINGS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingreso medio mensual de ahorros"])},
        "MONTH_OUTCOMES_SAVINGS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salida media mensual de ahorros"])},
        "MONTH_INCOMES_TRANSFERS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media mensual de transferencias entrantes"])},
        "MONTH_OUTCOMES_TRANSFERS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media mensual de transferencias salientes"])}
      }
    },
    "DEBT_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relación de deuda"])},
    "MONTH_OUTCOMES_CREDITS_COUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de créditos en curso"])},
    "REMAINS_TO_LIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remanente para vivir"])},
    "START_STUDY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de inicio del período de análisis financiero"])},
    "END_STUDY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de finalización del período de análisis financiero"])},
    "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo mensual final"])},
    "TOP_JOINT_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta conjunta destacada"])},
    "TOP_RECOMMENDED_DATE_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de débito recomendada"])},
    "TOP_BANKING_SUPERVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisión bancaria destacada"])},
    "GLOBAL_TRANSACTIONS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número total de transacciones"])},
    "MOVEMENT_SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen de movimientos"])},
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiqueta"])},
    "ACCOUNT_STATEMENT": {
      "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver el extracto de cuenta"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXTRACTO(S) DE CUENTA(S)"])},
      "ACCOUNT": {
        "CHECKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUENTA CORRIENTE"])},
        "SAVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUENTA DE AHORRO"])}
      },
      "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe"])},
      "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción pendiente"])},
      "NO_DATA_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han encontrado datos, por favor, inicie una nueva búsqueda"])},
      "PRIVACY_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por motivos de seguridad y protección de datos, el extracto de cuenta de OpenBanking deja de estar disponible después de un determinado periodo de tiempo."])},
      "CATEGORY": {
        "INCOME": {
          "ADVERTISINGPAYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago publicitario"])},
          "ALLOWANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subsidio"])},
          "ALLOWANCE:FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subsidio familiar"])},
          "ALLOWANCE:UNEMPLOYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subsidio de desempleo"])},
          "ALLOWANCE:OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro subsidio"])},
          "AUDIOVISUALSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción audiovisual"])},
          "AUTOMOBILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos de automóvil"])},
          "AUTOMOBILE:MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantenimiento de automóviles"])},
          "AUTOMOBILE:PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estacionamiento de automóviles"])},
          "AUTOTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia automática"])},
          "BANKINGFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifa bancaria"])},
          "BANKINGFEE:CONTRACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifas relacionadas con el contrato bancario"])},
          "BANKINGFEE:USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifas relacionadas con el uso de un producto bancario"])},
          "CASHCOLLECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recolección de efectivo"])},
          "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheque"])},
          "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédito"])},
          "CREDIT:CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédito al consumidor"])},
          "CREDIT:CONSUMER:SPLIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédito al consumo fraccionado"])},
          "CREDIT:HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédito hipotecario"])},
          "CRYPTOCURRENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criptomoneda"])},
          "CULTUREEVENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento cultural"])},
          "DATAPURCHASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compra de datos"])},
          "DEPOSIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depósito"])},
          "DONATIONANDCHARITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donación y caridad"])},
          "EDUCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educación"])},
          "EDUCATION:DRIVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educación vial"])},
          "EDUCATION:SCHOOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educación escolar"])},
          "FINEFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifa de multa"])},
          "FITNESSSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción de fitness"])},
          "FOODCONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo de alimentos"])},
          "FOODCONSUMPTION:DELIVERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo alimentario a domicilio"])},
          "FRAUDULENTTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia fraudulenta"])},
          "GAMBLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juegos de azar"])},
          "GROCERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comestibles"])},
          "GROCERY:DRIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supermercado en modo drive"])},
          "HEALTHWELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salud y bienestar"])},
          "HEALTHWELLNESS:BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos relacionados con el cuidado del cuerpo"])},
          "HEALTHWELLNESS:CONSULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos relacionados con consultas médicas"])},
          "HEALTHWELLNESS:SHOP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos relacionados con compras médicas"])},
          "HOSTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospedaje"])},
          "INDEMNITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indemnización"])},
          "INSURANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguro"])},
          "INTEREST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interés"])},
          "INTERNATIONALTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia internacional"])},
          "INTERVENTIONFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifa de intervención"])},
          "INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inversión"])},
          "LEGALFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifa legal"])},
          "LEISURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocio"])},
          "PENSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensión"])},
          "PETCARE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuidado de mascotas"])},
          "READINGSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción a lecturas"])},
          "REIMBURSEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reembolso"])},
          "REJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazo"])},
          "REJECT:CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazo de crédito"])},
          "REJECT:OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro rechazo"])},
          "RENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alquiler"])},
          "RENT:CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alquiler para consumidores"])},
          "RENT:HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alquiler de vivienda"])},
          "SALARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salario"])},
          "SALARY:REGULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salario regular"])},
          "SALARY:TEMPORARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salario temporal"])},
          "SALARY:ADVANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anticipo de salario"])},
          "SALARY:BONUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonificaciones e incentivos"])},
          "SALARY:FEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informes de gastos"])},
          "SAVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahorro"])},
          "SECURITYSERVICESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción a servicios de seguridad"])},
          "SEIZUREFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifa de embargo"])},
          "SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio"])},
          "SHOPPING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compras"])},
          "SHOPPING:TOBACCOBAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compras en bares y estancos"])},
          "SOCIALFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costes relacionados con las cargas sociales"])},
          "SOFTWARESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción de software"])},
          "STOCKMARKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado de valores"])},
          "SYNDICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sindicato"])},
          "TAXE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuesto"])},
          "TELECOMSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción de telecomunicaciones"])},
          "TELECOMSUB:BOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costes relacionados con suscripciones de telecomunicaciones para internet"])},
          "TELECOMSUB:PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costes relacionados con suscripciones de telecomunicaciones para telefonía"])},
          "TRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia"])},
          "TRANSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporte"])},
          "TRANSPORT:ENERGY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costes de suministro de combustible"])},
          "TRANSPORT:MOBILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costes de movilidad en transporte"])},
          "TRANSPORT:PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costes de estacionamiento"])},
          "TRANSPORT:TOLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costes de peaje"])},
          "UNPAID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impago"])},
          "UTILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilidad"])},
          "UTILITY:ENERGY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de energía"])},
          "UTILITY:WATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de agua"])},
          "UTILITY:OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro servicio público"])},
          "VIDEOGAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videojuego"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro"])}
        },
        "OUTCOME": {
          "ADVERTISINGPAYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago publicitario"])},
          "ALLOWANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subsidio"])},
          "ALLOWANCE:FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subsidio familiar"])},
          "ALLOWANCE:UNEMPLOYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subsidio de desempleo"])},
          "ALLOWANCE:OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro subsidio"])},
          "AUDIOVISUALSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción audiovisual"])},
          "AUTOMOBILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos de automóvil"])},
          "AUTOMOBILE:MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantenimiento de automóviles"])},
          "AUTOMOBILE:PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estacionamiento de automóviles"])},
          "AUTOTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia automática"])},
          "BANKINGFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifa bancaria"])},
          "BANKINGFEE:CONTRACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifas relacionadas con el contrato bancario"])},
          "BANKINGFEE:USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifas relacionadas con el uso de un producto bancario"])},
          "CASHCOLLECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recolección de efectivo"])},
          "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheque"])},
          "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédito"])},
          "CREDIT:CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédito al consumidor"])},
          "CREDIT:CONSUMER:SPLIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédito al consumo fraccionado"])},
          "CREDIT:HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédito hipotecario"])},
          "CRYPTOCURRENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criptomoneda"])},
          "CULTUREEVENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento cultural"])},
          "DATAPURCHASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compra de datos"])},
          "DONATIONANDCHARITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donación y caridad"])},
          "EDUCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educación"])},
          "EDUCATION:DRIVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educación vial"])},
          "EDUCATION:SCHOOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educación escolar"])},
          "FINEFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifa de multa"])},
          "FITNESSSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción de fitness"])},
          "FOODCONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo de alimentos"])},
          "FOODCONSUMPTION:DELIVERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo alimentario a domicilio"])},
          "FRAUDULENTTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia fraudulenta"])},
          "GAMBLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juegos de azar"])},
          "GROCERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comestibles"])},
          "GROCERY:DRIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supermercado en modo drive"])},
          "HEALTHWELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salud y bienestar"])},
          "HEALTHWELLNESS:BODY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos relacionados con el cuidado del cuerpo"])},
          "HEALTHWELLNESS:CONSULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos relacionados con consultas médicas"])},
          "HEALTHWELLNESS:SHOP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos relacionados con compras médicas"])},
          "HOSTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospedaje"])},
          "INDEMNITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indemnización"])},
          "INSURANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguro"])},
          "INTEREST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interés"])},
          "INTERNATIONALTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia internacional"])},
          "INTERVENTIONFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifa de intervención"])},
          "INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inversión"])},
          "LEGALFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifa legal"])},
          "LEISURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocio"])},
          "PENSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensión"])},
          "PETCARE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuidado de mascotas"])},
          "READINGSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción a lecturas"])},
          "REIMBURSEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reembolso"])},
          "REJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazo"])},
          "REJECT:CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazo de crédito"])},
          "REJECT:OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro rechazo"])},
          "RENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alquiler"])},
          "RENT:CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alquiler para consumidores"])},
          "RENT:HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alquiler de vivienda"])},
          "SALARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salario"])},
          "SALARY:REGULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salario regular"])},
          "SALARY:TEMPORARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salario temporal"])},
          "SALARY:ADVANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anticipo de salario"])},
          "SALARY:BONUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonificaciones e incentivos"])},
          "SALARY:FEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informes de gastos"])},
          "SAVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahorro"])},
          "SECURITYSERVICESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción a servicios de seguridad"])},
          "SEIZUREFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifa de embargo"])},
          "SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio"])},
          "SHOPPING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compras"])},
          "SHOPPING:TOBACCOBAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compras en bares y estancos"])},
          "SOCIALFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costes relacionados con las cargas sociales"])},
          "SOFTWARESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción de software"])},
          "STOCKMARKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado de valores"])},
          "SYNDICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sindicato"])},
          "TAXE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuesto"])},
          "TELECOMSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción de telecomunicaciones"])},
          "TELECOMSUB:BOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costes relacionados con suscripciones de telecomunicaciones para internet"])},
          "TELECOMSUB:PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costes relacionados con suscripciones de telecomunicaciones para telefonía"])},
          "TRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia"])},
          "TRANSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporte"])},
          "TRANSPORT:ENERGY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costes de suministro de combustible"])},
          "TRANSPORT:MOBILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costes de movilidad en transporte"])},
          "TRANSPORT:PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costes de estacionamiento"])},
          "TRANSPORT:TOLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costes de peaje"])},
          "UNPAID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impago"])},
          "UTILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilidad"])},
          "UTILITY:ENERGY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de energía"])},
          "UTILITY:WATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de agua"])},
          "UTILITY:OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro servicio público"])},
          "VIDEOGAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videojuego"])},
          "WITHDRAWAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retiro"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro"])}
        }
      }
    },
    "RECOMMENDATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendaciones"])},
    "APPROVALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprobaciones"])},
    "INDEBTEDNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deuda"])},
    "HABITAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vivienda"])},
    "EXISTING_CREDIT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de créditos existentes"])},
    "ALERTS": {
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
      "CAUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causa"])},
      "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
      "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha detectado ninguna alerta"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertas"])}
    },
    "SERVICES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["servicios"])},
    "WATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agua"])},
    "GAS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gas"])},
    "ELECTRICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["electricidad"])},
    "BANK_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjetas bancarias"])},
    "DEFERRED_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detección de débitos diferidos"])},
    "GLOBAL_TRANSACTION_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número total de transacciones"])},
    "MONTH_NEGATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Días en números rojos en el período"])},
    "MAIN_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta principal"])},
    "NO_TRANSACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha detectado ninguna transacción de ingresos."])},
    "ALERT": {
      "ABSENCE_OF_INCOME": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausencia de ingresos"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha detectado ninguna transacción de ingresos"])}
      },
      "PRESENCE_OF_REJECTS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presencia de rechazos"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha detectado al menos una transacción con rechazos"])}
      },
      "PRESENCE_OF_UNPAIDS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presencia de impagos"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha detectado al menos una transacción con impagos"])}
      },
      "PRESENCE_OF_PENSIONS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presencia de pensiones"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha detectado al menos una transacción con pensiones"])}
      },
      "PRESENCE_OF_GAMBLINGS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presencia de juegos de azar"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos el 10 % de los gastos globales se utilizan para juegos de azar"])}
      },
      "PRESENCE_OF_ALLOWANCES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presencia de subsidios"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha detectado al menos una transacción con subsidios"])}
      },
      "PRESENCE_OF_SEIZURE_FEES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presencia de tasas de embargo"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha detectado al menos una transacción con tasas de embargo"])}
      },
      "MONTH_NEGATIVE_BALANCE": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo negativo del mes"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemos detectado uno o más días con saldo negativo"])}
      },
      "BANK_WITH_EXTREME_RISK_LEVEL": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco con nivel de riesgo extremo"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemos detectado una o más cuentas de un banco considerado de riesgo extremo"])}
      },
      "BANK_WITH_HIGH_RISK_LEVEL": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco con nivel de riesgo alto"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemos detectado una o más cuentas de un banco considerado de riesgo alto"])}
      },
      "TRANSACTION_HISTORY_DATE": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha del historial de transacciones"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se han transmitido menos de 2 meses de historial de transacciones para este análisis"])}
      },
      "TRANSACTION_HISTORY_DATE_ONE_MONTH": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha del historial de transacciones"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se transmitió menos de 1 mes de historial de transacciones para este análisis"])}
      },
      "PRESENCE_OF_CASH_COLLECTIONS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presencia de empresas de cobro"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos 1 transacción con una agencia de cobro detectada"])}
      },
      "PRESENCE_OF_CRYPTOCURRENCIES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presencia de criptomonedas"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos el 10 % de los gastos globales se utiliza para criptomonedas"])}
      },
      "TRANSACTION_HISTORY_COUNT": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de operaciones en el historial de transacciones"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se han transmitido menos de 10 transacciones para este análisis"])}
      },
      "PRESENCE_OF_CREDITS_PER_MONTH": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presencia de créditos por mes"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se han detectado al menos 3 transacciones de crédito por mes"])}
      },
      "PRESENCE_OF_INTERVENTION_FEES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presencia de tasas de intervención"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha detectado al menos una transacción con tasas de intervención"])}
      },
      "PRESENCE_OF_WITHDRAWALS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presencia de retiros por mes"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos el 25 % de los gastos globales se utiliza para retiradas"])}
      },
      "PRESENCE_OF_INTERNATIONALS_TRANSFERS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presencia de transferencias internacionales"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha detectado al menos una transacción con transferencias internacionales"])}
      },
      "TRANSACTION_HISTORY_FOR_ANALYSIS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de transacciones para análisis"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de transacciones transmitidas no es suficiente para permitir un análisis oportuno."])}
      },
      "ABSENCE_OF_TAXE": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausencia de impuestos"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha detectado ninguna transacción de impuestos"])}
      },
      "LOSS_OF_SALARY": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pérdida de salario"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha detectado ninguna transacción de salario"])}
      },
      "ABSENCE_OF_SOCIAL_FEES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausencia de cotizaciones sociales"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha detectado ninguna transacción de cotizaciones sociales"])}
      },
      "PRESENCE_OF_AUTOTRANSFERS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presencia de transferencias automáticas"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos el 50 % de los ingresos o gastos globales se utilizan para transferencias automáticas"])}
      },
      "PRESENCE_OF_UNEMPLOYMENT_ALLOWANCES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presencia de subsidios de desempleo"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha detectado al menos una transacción con subsidios de desempleo"])}
      },
      "ACCOUNT_WITH_BANKING_SUPERVISION": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta con supervisión bancaria"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemos detectado una o más cuentas con supervisión bancaria"])}
      },
      "ACCOUNT_NEWLY_CREATED": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta recién creada"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemos detectado una o más cuentas recién creadas"])}
      },
      "PRESENCE_OF_FRAUDULENT_TRANSFERS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presencia de transferencias fraudulentas"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha detectado al menos 1 transacción con transferencias fraudulentas"])}
      }
    },
    "NET_INCOME_AND_ANNUAL_TURNOVER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresos netos y facturación anual"])},
    "TAXES_AND_PAYROLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuestos y Nómina"])},
    "DAILY_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo diario"])},
    "MONTHLY_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo mensual"])},
    "DAILY_AND_MONTHLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo diario y mensual"])},
    "WRONG_ANALYSIS_B2C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención, el análisis se realizó en una cuenta personal y no en una cuenta empresarial"])},
    "WRONG_ANALYSIS_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención, el análisis se realizó en una cuenta empresarial y no en una cuenta personal"])},
    "PROVIDER_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error durante la recuperación de datos. Por favor, inicie de nuevo el archivo."])},
    "UNDEFINED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no definida"])},
    "AVERAGE_NUMBER_OF_TRANSACTIONS_BY_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número medio de transacciones / mes"])},
    "MONTHLY_AVERAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promedio mensual"])},
    "SUM_OVER_THE_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma durante el período"])},
    "ANNUAL_ESTIMATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimación anual"])},
    "SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salarios"])},
    "ALLOWANCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subsidios"])},
    "PENSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensiones"])},
    "TOTAL_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de ingresos"])},
    "RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alquileres"])},
    "LOANS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créditos"])},
    "TOTAL_OUTCOMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de gastos"])},
    "INSTITUTION_ASSOCIATED_WITH_LOAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entidad(es) asociada(s) a un crédito"])},
    "REVENUE_INCL_VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresos CA con IVA"])},
    "OUTGOING_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salarios salientes"])},
    "OUTGOING_SOCIAL_CHARGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargas sociales salientes"])},
    "OTHER_EXPENSES_INCL_VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otros gastos con IVA"])},
    "CREDITS_INCL_VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créditos con IVA"])},
    "TAXES_INCL_VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuestos con IVA"])},
    "INTRA_COMPANY_TRANSFERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flujos intraempresariales (autotransferencias)"])}
  },
  "SOCIAL_FACE_MATCHING": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Búsqueda de imágenes"])},
    "CATCHED_IMAGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imágenes recuperadas"])},
    "NO_IMAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ninguna imagen"])}
  },
  "AMLCFT": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucha contra el lavado de dinero y el terrorismo"])},
    "FREEZING_ASSETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congelación de activos"])},
    "PPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona políticamente expuesta"])},
    "PPE_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta persona está identificada como políticamente expuesta"])},
    "ADVERSE_MEDIA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medios adversos"])},
    "ADVERSE_MEDIA_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existen varios artículos de prensa sobre el solicitante."])},
    "ADVERSE_MEDIA_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de medios adversos"])},
    "COLUMN_TABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCBFT"])},
    "SANCTIONED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sancionada"])},
    "SANCTIONED_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta persona ha sido identificada como sancionada."])},
    "SANCTIONED_EXPLANATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto puede incluir el congelamiento de sus activos, vínculos con el terrorismo y/o el lavado de dinero."])},
    "CLASS_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jefes de Estado, miembros del ejecutivo nacional, miembros de las legislaturas nacionales, altos funcionarios del ejército y la aplicación de la ley, altos funcionarios de agencias nacionales, miembros de juntas directivas de bancos centrales, altos funcionarios judiciales (nivel nacional), principales líderes de partidos políticos"])},
    "CLASS_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miembros de juntas directivas de organizaciones internacionales y federaciones deportivas internacionales, embajadores, altos comisionados y otros cargos diplomáticos de alto nivel, miembros del ejecutivo regional, miembros de legislaturas regionales, altos funcionarios judiciales (nivel regional)"])},
    "CLASS_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección general y juntas directivas de empresas y organizaciones públicas"])},
    "CLASS_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alcaldes y miembros de gobiernos locales (nivel subregional)"])},
    "LEVEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel"])},
    "ADVERSE_MEDIA_DETAILS": {
      "financial-crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crimen financiero"])},
      "violent-crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crimen violento"])},
      "sexual-crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crimen sexual"])},
      "terrorism": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terrorismo"])},
      "fraud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraude"])},
      "narcotics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estupefacientes"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
      "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propiedad"])},
      "financial-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Financiero"])},
      "fraud-linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculado a fraude"])},
      "narcotics-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Estupefacientes"])},
      "violence-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Violencia"])},
      "cybercrime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciberdelincuencia"])},
      "general-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT General"])},
      "regulatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regulación"])},
      "financial-difficulty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dificultades financieras"])},
      "violence-non-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No AML CFT Violencia"])},
      "other-financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro Financiero"])},
      "other-serious": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro Grave"])},
      "other-minor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro Menor"])}
    },
    "LINKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlaces"])},
    "NO_RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta persona no está identificada ni como sancionada ni como políticamente expuesta."])}
  },
  "TOOLTIP": {
    "CURRENT_PROCEDURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa con un procedimiento judicial en curso."])},
    "MOTHBALLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa puesta en pausa y reactivada desde entonces."])},
    "MOBILE_PHONE_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación del teléfono móvil."])},
    "SOCIAL_FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imágenes encontradas en Google relacionadas con la información proporcionada."])},
    "EMAIL_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación del correo electrónico."])},
    "AVAILABLES_PICTURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imágenes disponibles en redes sociales en relación con la información del solicitante."])},
    "NUMBER_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de establecimientos propiedad de la empresa."])},
    "FREEZING_ASSETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí si hay una congelación de activos en curso."])},
    "SCORE_WEB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de Fraude en la existencia y confiabilidad de los datos de contacto del solicitante."])},
    "SCORE_SECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de Riesgo en el análisis financiero y la solvencia de la empresa."])},
    "DEFAULT_PROBABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medida del riesgo de incumplimiento de la estructura."])},
    "SCORE_LAST_UPDATE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuaciones actualizadas el"])},
    "SCORE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La puntuación evalúa la solidez financiera de la empresa, según su sector y sus estados financieros."])},
    "SCORE_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de Riesgo en el análisis financiero de la cuenta bancaria."])},
    "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo mensual promedio en el período estudiado."])},
    "EMAIL_DISPOSABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico temporal"])},
    "EMAIL_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico arriesgado"])},
    "MOBILE_PHONE_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono vinculado"])},
    "EMAIL_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico vinculado"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar con la entrada del expediente"])},
    "VIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultar el expediente"])},
    "DUPLICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carpeta duplicada"])},
    "DELETE_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar la carpeta"])},
    "BLOCK_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es imposible reabrir un expediente terminado."])}
  },
  "EMAIL_DISPOSABLE": {
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El correo electrónico proporcionado está asociado a un dominio desechable."])}
  },
  "DOCUMENT_CONTROL": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control de documentos"])},
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elegir un tipo de documento"])},
    "ID_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cédula de identidad"])},
    "ID_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cédula de identidad"])},
    "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cédula de identidad"])},
    "DRIVING_LICENSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licencia de conducir"])},
    "PASSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasaporte"])},
    "RESIDENCE_PERMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permiso de residencia"])},
    "KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis - Empresa o Situación"])},
    "INDIVIDUAL_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis - Persona física"])},
    "COMPANY_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis - Persona jurídica"])},
    "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticidad - Análisis de la no alteración digital"])},
    "AUTHENTICITY_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de autenticidad"])},
    "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe fiscal"])},
    "TAX_REPORT_S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe fiscal simplificado"])},
    "TAX_REPORT_C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe fiscal complejo"])},
    "TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso de impuestos - Ingresos o propiedades"])},
    "INCOME_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuesto sobre la renta"])},
    "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro tipo"])},
    "PROPERTY_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuesto sobre la propiedad"])},
    "BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIB"])},
    "BANK_STATEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extracto bancario"])},
    "BLOC_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control de documentos"])},
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La análisis del documento ha fallado"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, complete la carga de su documento en la ventana izquierda para poder ver el análisis del documento."])},
    "PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nómina"])},
    "PAYSLIP_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nómina"])},
    "RIB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIB"])},
    "KBIS_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS"])},
    "TAX_NOTICE_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso de impuestos"])},
    "OTHER_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro documento"])},
    "NEW_DOCUMENT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizar un nuevo documento"])},
    "NEW_FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control biométrico"])},
    "FACE_MATCHING_DESC_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Desea realizar un nuevo análisis de identidad con un control biométrico?"])},
    "FACE_MATCHING_DESC_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca el número de teléfono del cliente para que pueda recibir por SMS el enlace que le permitirá realizar su análisis."])},
    "ID_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de identidad"])},
    "ID_ANALYSIS_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede iniciar aquí un análisis documental sobre documentos de identidad como DNI, pasaportes, tarjetas de residencia, y licencias de conducir."])},
    "DOCUMENT_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis documental"])},
    "DOCUMENT_ANALYSIS_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede iniciar aquí un análisis documental para una declaración de impuestos, KBIS, nómina, aviso de impuestos o cualquier otro documento en formato PDF."])},
    "SELECT_DOCUMENT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar uno o varios tipos de documento"])},
    "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconocido"])},
    "UNKNOWN_DOC_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El documento que pasó no pudo identificarse como una identificación. Para comprobarlo, recuerde cargar el anverso y el reverso del documento o utilizar el panel de análisis del documento."])},
    "NO_DOCUMENT_UPLOADED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ningún documento cargado."])},
    "FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control biométrico"])},
    "SELFIE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfie"])},
    "TABLE": {
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
      "DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento"])},
      "COMPLIANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumplimiento"])},
      "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticidad"])},
      "ID_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva análisis de identidad"])},
      "DOCUMENT_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo análisis documental"])},
      "RESULT": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del análisis"])},
        "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación"])},
        "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados"])},
        "WAITING_CONFIRMATION_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis pendiente de confirmación por su parte"])},
        "IN_PROGRESS_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis en curso"])},
        "LIVE_CHECK": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desencadenamiento de la revisión de dudas"])},
          "DESC_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Está a punto de desencadenar la revisión de dudas del documento <", _interpolate(_named("documentName")), ">."])},
          "DESC_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La verificación humana solo está disponible durante los primeros 10 minutos después de la carga del documento."])},
          "DESC_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El procesamiento del análisis puede llevar tiempo; se le notificará una vez que el análisis haya terminado."])},
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Revisión de dudas le permite solicitar una verificación manual del documento por un operador humano en menos de quince minutos. Atención, solo está disponible durante los primeros 10 minutos después de la carga del documento."])},
          "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisión de dudas en proceso"])},
          "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisión de dudas disponible"])},
          "IN_PROGRESS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisión de dudas en proceso."])},
          "IN_PROGRESS_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los controles a continuación se actualizarán una vez que se complete la revisión manual. Por favor, espere."])},
          "FAILED_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de la revisión de dudas del documento no disponible."])},
          "FAILED_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se produjo un error técnico al desencadenar el análisis de la revisión de dudas del documento."])},
          "SUCCESS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de la revisión de dudas del documento realizado."])},
          "ANALYSIS_RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado del análisis"])},
          "ANALYST_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje del analista"])}
        },
        "CHECKS": {
          "EMISSION_COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistencia del país de emisión del documento"])},
          "EMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistencia entre el tipo de documento y la fecha de emisión"])},
          "EXPIRATION_DATE_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validez de la fecha de vencimiento"])},
          "VALIDITY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento válido"])},
          "MRZ_FIELDS_SYNTAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control MRZ"])},
          "MRZ_CHECKSUMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control MRZ"])},
          "MRZ_EXPECTED_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control MRZ"])},
          "MRZ_ALIGNEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control MRZ"])},
          "MRZ_CLASSIFIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control MRZ"])},
          "FIRST_NAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistencia de la identidad de la persona / MRZ"])},
          "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistencia de la identidad de la persona / MRZ"])},
          "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistencia de la identidad de la persona / MRZ"])},
          "DOCUMENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de documento coherente con MRZ"])},
          "EXPIRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de vencimiento coherente con MRZ"])},
          "OCR_EMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de emisión coherente con MRZ"])},
          "PERSONAL_NUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificación personal coherente con MRZ"])},
          "PHOTO_COMPLIANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de la foto"])},
          "PHOTO_LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de la foto"])},
          "VISUAL_SECURITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumplimiento de puntos de seguridad del documento"])},
          "PHOTOCOPY_DETECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detección de fotocopia"])},
          "SCREENSHOT_DETECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detección de captura de pantalla"])},
          "OLD_DRIVING_LICENSE_DOCUMENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de documento de antigua licencia"])},
          "DRIVING_LICENSE_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control de validez de la licencia"])},
          "DRIVING_LICENSE_DIGITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control de validez de la licencia"])},
          "AGE_AT_CERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edad de obtención coherente"])},
          "MRZ_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control MRZ"])},
          "MRZ_IDENTITY_COHERENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coherencia de la identidad de la persona"])},
          "PICTURE_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de la foto"])},
          "BARCODE_EXPECTED_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de barras encontrado"])},
          "BARCODE_FIELDS_SYNTAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos del código de barras codificados correctamente"])},
          "BARCODE_SIGNATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma del código de barras válida"])},
          "TYPE_RECOGNIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tipo de documento ha sido reconocido"])},
          "NOT_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de caducidad"])},
          "MRZ_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La zona legible por máquina (MRZ) del documento es válida"])},
          "VALIDITY_PERIOD_MODEL_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El modelo de periodo de validez coincide"])},
          "OCR_ANALYSIS_MRZ_FIRST_NAMES_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los nombres de pila en la MRZ coinciden con el análisis OCR"])},
          "OCR_ANALYSIS_MRZ_DOCUMENT_NUMBER_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de documento en la MRZ coincide con el análisis OCR"])},
          "DOCUMENT_ASPECT_GENERAL_APPEARANCE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La apariencia general del documento coincide con las expectativas"])},
          "SECURITY_ELEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementos de seguridad presentes en el documento"])},
          "QUALITY_ABOVE_MINIMUM_THRESHOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La calidad del documento está por encima del umbral mínimo"])},
          "NOT_SPECIMEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El documento no es un espécimen"])},
          "FRONT_SIDE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha encontrado el lado frontal del documento"])},
          "OCR_ANALYSIS_MRZ_BIRTH_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre de nacimiento en la MRZ coincide con el análisis OCR"])},
          "OCR_ANALYSIS_MRZ_BIRTH_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de nacimiento en la MRZ coincide con el análisis OCR"])},
          "OCR_ANALYSIS_FAITHFUL_FONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El análisis OCR indica el uso de una fuente fiel en el documento"])},
          "DOCUMENT_ASPECT_PICTURE_PRESENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hay una imagen presente en el documento"])},
          "OCR_ANALYSIS_EXPIRATION_DATE_READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de vencimiento se leyó correctamente mediante análisis OCR"])},
          "OCR_ANALYSIS_ISSUANCE_DATE_READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de emisión se leyó correctamente mediante análisis OCR"])},
          "ISSUANCE_AND_EXPIRATION_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las fechas de emisión y vencimiento coinciden"])},
          "NO_TAMPERING_SIGN_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ningún signo de manipulación en el documento"])},
          "NO_ANNOTATION_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ninguna anotación en el documento"])},
          "BACK_SIDE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se encontró el reverso del documento"])},
          "OCR_ANALYSIS_MRZ_EXPIRATION_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de vencimiento en el MRZ coincide con el análisis OCR"])},
          "OCR_ANALYSIS_MRZ_ISSUANCE_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de emisión en el MRZ coincide con el análisis OCR"])},
          "OCR_ANALYSIS_MRZ_LAST_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El apellido en el MRZ coincide con el análisis OCR"])},
          "DOC_2D_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento 2D válido"])},
          "DOC_2D_AND_DOCUMENT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento 2D coherente con la información del documento"])},
          "WORKABLE_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La captura puede ser procesada para verificación"])},
          "WORKABLE_ELEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El elemento puede ser procesado para verificación"])},
          "STATUS_ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expediente aceptado"])},
          "FRAUD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento fraudulento"])},
          "DOCUMENT_INSUFFICIENT_QUALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La calidad de la captura del documento no permite revisión humana"])},
          "DOCUMENT_INCORRECTLY_FRAMED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El documento no está correctamente enmarcado en la imagen"])},
          "DOCUMENT_SIDE_MISSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo se ve un lado del documento (se requieren ambas caras)"])},
          "DOCUMENT_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento vencido"])},
          "DOCUMENT_UNSUPPORTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de documento no compatible"])},
          "DOCUMENT_COUNTRY_UNAUTHORIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País de emisión del documento no compatible"])},
          "DOCUMENT_UNSUPPORTED_RECEIPT_ACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de documento no compatible"])},
          "BIOMETRY_INSUFFICIENT_QUALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La calidad de los datos biométricos no permite revisión humana"])},
          "LIVE_CHECK_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error técnico ocurrido con el proveedor"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otra razón, ver detalles abajo"])},
          "ID_PHOTO_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistencia entre el selfie del usuario y el documento"])},
          "PASSIVE_LIVENESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condiciones para la captura del selfie cumplidas"])},
          "IS_ALIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movimientos detectados durante la captura del selfie"])},
          "SEQUENCE_CONSISTENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movimientos coherentes durante la realización"])},
          "ORIGINAL_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento original presentado"])},
          "PICTURE_NOT_TAMPERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto de identidad no alterada"])},
          "DOCUMENT_NUMBER_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validez del número del documento"])},
          "MULTI_REQUESTS": {
            "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El documento no está sujeto a múltiples solicitudes"])},
            "KO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El documento ha sido enviado varias veces (múltiples solicitudes)"])},
            "WARNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El documento ha sido enviado varias veces (múltiples solicitudes)"])}
          },
          "MULTI_REQUESTS_INCONSISTENT": {
            "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El documento no presenta datos inconsistentes relacionados con múltiples solicitudes"])},
            "KO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El documento ha sido enviado varias veces con datos diferentes"])},
            "WARNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El documento ha sido enviado varias veces con datos diferentes"])}
          },
          "MULTI_REQUESTS_FRAUD": {
            "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El documento no ha sido detectado como fraude relacionado con múltiples solicitudes"])},
            "KO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El documento ha sido enviado varias veces y detectado como fraude"])},
            "WARNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El documento ha sido enviado varias veces y detectado como fraude"])}
          }
        },
        "KBIS": {
          "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificación"])},
          "SIRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET"])},
          "REGISTRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de registro"])},
          "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la empresa"])},
          "HEADQUARTER_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de la sede"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
          "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
          "PUBLICATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de publicación de KBIS"])},
          "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identidad del miembro"])},
          "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento"])},
          "IDENTIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificación"])},
          "MAIN_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad principal"])},
          "MEMBERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miembros"])},
          "NATURAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona(s) física(s)"])},
          "LEGAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona(s) jurídica(s)"])},
          "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posición"])},
          "BIRTH_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de nacimiento"])},
          "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
          "FIRST_NAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre(s)"])},
          "LEGAL_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma jurídica"])},
          "COMPANY_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción de la empresa"])},
          "ESTABLISHMENT_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción del establecimiento"])}
        },
        "TAX_REPORT": {
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de la empresa"])},
          "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificación"])},
          "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de cierre del ejercicio"])},
          "OPENING_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de apertura del ejercicio"])},
          "CONFIDENTIALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento confidencial"])},
          "FISCAL_PERIOD_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración del período fiscal"])},
          "RATIOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratios financieros"])},
          "MONTH": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " mes"]), _normalize([_interpolate(_named("n")), " meses"])])}
        },
        "TAX_NOTICE": {
          "TAX_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección fiscal"])},
          "BANK_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificación bancaria"])},
          "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de cuenta"])},
          "REMAINING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo restante"])},
          "BIRTH_LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido de nacimiento"])},
          "NUMBER_PARTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de partes"])},
          "FAMILY_SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situación familiar"])},
          "CHECKS": {
            "FOUND_2D_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento 2D identificado"])},
            "NAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistencia de nombres"])},
            "ASSESSMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistencia de la fecha de establecimiento"])},
            "REFERENCE_TAX_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistencia del ingreso fiscal de referencia"])},
            "TAX_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistencia del número fiscal"])},
            "REFERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistencia de la referencia"])},
            "REFERENCE_YEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistencia del año de referencia"])},
            "NUMBER_OF_PARTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistencia del número de partes"])},
            "NUMBER_OF_REGISTRANTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistencia del número de declarantes"])}
          }
        },
        "AUTHENTICITY": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticidad"])},
          "DETAILED_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del análisis"])},
          "NO_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha detectado ninguna alerta en el análisis del documento."])},
          "FAILED_TASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error, por favor reinicie la verificación."])},
          "BINARY_MODIFICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento modificado desde su creación"])},
          "COHERENCE_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El documento no tiene coherencia en cuanto a los datos que recogemos."])},
          "AUTOGENERATED_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este documento proviene de un software generador de documentos falsos"])},
          "METADATA_MODIFICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El análisis de metadatos revela una modificación del documento"])},
          "PIXEL_MODIFICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha modificado una parte de la imagen"])},
          "SOFTWARE_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El último software utilizado es un programa de edición de imágenes (como Photoshop) que puede ser utilizado para redimensionar (para que parezca auténtico) o modificar la imagen."])},
          "REPORT_FINOVOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe detallado de Finovox (PDF)"])},
          "ANALYSIS_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El análisis de autenticidad puede tomar varios minutos."])},
          "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error durante el proceso de OCR. Por favor, verifique la extensión de su archivo (pdf, jpg, jpeg, png permitidos) antes de intentar de nuevo. Si el problema persiste, comuníquese con el soporte técnico."])}
        },
        "PROPERTY_TAX": {
          "PAYMENT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de pago"])},
          "LEGAL_DEBTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deudores legales"])},
          "PROPERTIES_AND_BUILDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propiedades y edificios"])},
          "PAYMENT_DEADLINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha límite de pago"])},
          "REMAINING_AMOUNT_TO_PAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto restante a pagar"])},
          "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta"])},
          "BANK_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificación bancaria"])},
          "CREDITOR_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del acreedor"])},
          "TAX_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto del impuesto"])},
          "IDENTIFIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador"])},
          "RIGHT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derecho"])},
          "DESIGNATION_AND_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designación y dirección"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
          "PAYMENT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de pago"])},
          "REMAINING_AMOUNT_TO_REPAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto restante a reembolsar"])},
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información sobre el aviso de impuestos"])},
          "ADDRESSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direcciones"])},
          "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declarante"])},
          "TAX_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número fiscal"])},
          "REF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencia del aviso"])},
          "ROLE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de rol"])},
          "TAX_DEPARTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departamento de impuestos"])},
          "OWNER_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de propietario"])},
          "TAX_COMMUNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comuna de impuestos"])},
          "SERVICE_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del servicio"])},
          "ISSUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de emisión"])},
          "ASSESSMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de evaluación"])}
        },
        "INCOME_TAX": {
          "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información sobre el aviso de impuestos"])},
          "ISSUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
          "FIP_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número FIP"])},
          "SERVICE_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del servicio"])},
          "TAX_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificación fiscal"])},
          "REFERENCE_TAX_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingreso fiscal de referencia"])},
          "ASSESSMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluación"])},
          "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declarante"])},
          "ROLE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de rol"])},
          "TAX_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección fiscal"])},
          "PAYMENT_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de pago"])},
          "FAMILY_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del hogar"])},
          "REMAINING_AMOUNT_TO_PAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto restante a pagar"])},
          "BIRTH_LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido de nacimiento"])},
          "NUMBER_OF_PARTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de partes"])},
          "FAMILY_SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situación familiar"])},
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información sobre el aviso de impuestos"])},
          "PAYMENT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de pago"])},
          "MANDATE_UNIQUE_REFERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencia de Mandato Único"])},
          "REMAINING_AMOUNT_TO_REPAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto restante a reembolsar"])},
          "CREDITOR_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del acreedor"])},
          "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta"])},
          "BANK_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificación del banco"])},
          "REF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencia del aviso"])},
          "OWNER_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de propietario"])},
          "REFERENCE_YEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingreso fiscal de referencia"])}
        },
        "BANK_DETAILS": {
          "BANK_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de banco"])},
          "BRANCH_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de sucursal"])},
          "ACCOUNT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de cuenta"])},
          "KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clave RIB"])},
          "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
          "BIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
          "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del banco"])},
          "BANK_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección del banco"])},
          "IBAN_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validez de IBAN"])},
          "ACCOUNT_NUMBER_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validez del número de cuenta"])},
          "DOCUMENT_ANALYZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento analizado"])},
          "DIRECT_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domiciliación"])},
          "CHECKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificaciones"])}
        },
        "PAYSLIP": {
          "PAYSLIP_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información sobre la Nómina"])},
          "PAYMENT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de Pago"])},
          "HIRE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de Contratación"])},
          "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
          "SIRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET"])},
          "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
          "NET_PAYABLE_BEFORE_INCOME_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neto a Pagar Antes de Impuestos"])},
          "NET_PAYABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neto a Pagar Después de Impuestos"])},
          "PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de Pago"])}
        },
        "FACE_MATCHING": {
          "IN_PROGRESS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control biométrico en curso."])},
          "IN_PROGRESS_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una vez que el análisis sea realizado por el cliente, se mostrarán los controles a continuación. Por favor, espere."])},
          "FAILED_OR_CANCELLED_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control biométrico no disponible."])},
          "FAILED_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocurrió un error técnico durante la realización del control biométrico."])},
          "CANCELLED_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El usuario ha cancelado el control biométrico."])}
        }
      }
    },
    "NO_PERSON_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron personas"])},
    "ERROR_MESSAGE_API": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un problema"])},
    "FAILED_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se produjo un problema durante el análisis del documento. Por favor, inténtelo de nuevo."])},
    "SOCIAL_SECURITY_CERTIFICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificado de Seguridad Social"])},
    "DISABLED_WORKER_CERTIFICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificado de Trabajador con Discapacidad"])},
    "PROOF_OF_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprobante de Domicilio"])},
    "FINALIZE_MY_DOCUMENT_SUBMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar mi envío de documentos"])}
  },
  "SCORE": {
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación"])},
    "WEB_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web"])},
    "SECTOR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sector"])},
    "COMPANY_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa"])},
    "DEFAULT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defecto"])},
    "REQUEST_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de solicitud"])},
    "HELPER_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendamos realizar controles adicionales."])},
    "LOCAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación local "])},
    "INTERNATIONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación internacional"])}
  },
  "FINANCIAL_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis financiero de la empresa"])},
  "TAX_REPORTS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informes fiscales"])},
    "FISCAL_PERIOD_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración del período fiscal"])},
    "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de cierre"])},
    "TURNOVER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturación"])},
    "NET_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresos netos"])},
    "AUTO_FINANCE_CAPACITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacidad de autofinanciamiento"])},
    "NET_MARGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margen neto"])},
    "FB_TB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estructura financiera"])},
    "FINANCIAL_LIQUIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidez financiera"])},
    "WORKING_CAPITAL_REQUIREMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requerimiento de capital de trabajo"])},
    "WORKING_CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital de trabajo"])},
    "FINANCIAL_SOLDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo financiero"])},
    "DEBTS_SHORT_TERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deudas a corto plazo / balance"])},
    "DEBTS_MIDDLE_LONG_TERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deudas a medio y largo plazo / balance"])},
    "NOT_AVAILABLE_NEED_UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe fiscal no disponible. Puede cargar uno para obtener su análisis."])}
  },
  "FINANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzas"])},
  "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible"])},
  "UNAVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No disponible"])},
  "NO_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No disponible"])},
  "ENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entidad"])},
  "ENTITIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entidades"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mes"])},
  "OF_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["del mes"])},
  "EFFECTIVE_RECIPIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiario efectivo"])},
  "EMPLOYEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empleado"])},
  "REPRESENTATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representante"])},
  "WELCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido"])},
  "LAST_UPDATE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuaciones actualizadas el"])},
  "VERIFICATION": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existencia"])},
    "TOOLTIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de la existencia de la información con el solicitante"])}
  },
  "RELATED": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace Suficiente"])},
    "TOOLTIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de la asociación de la información con el solicitante"])}
  },
  "FINISH": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar Expediente"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Desea recuperar el resumen en formato PDF antes de cerrar la página?"])}
  },
  "FUNCTION": {
    "MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director General"])}
  },
  "SOCIAL_NETWORKS": {
    "MAIL_LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo Electrónico Vinculado"])},
    "PHONE_LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono Vinculado"])},
    "ADDRESS_LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección Vinculada"])},
    "MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculado al Correo Electrónico"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculado al Teléfono"])},
    "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redes Sociales"])},
    "INFORMATIONS": {
      "AGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edad"])},
      "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad"])},
      "STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Región"])},
      "GENDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Género"])},
      "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
      "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])}
    }
  },
  "TELEPHONE": {
    "LINETYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "TIMEZONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Región"])},
    "CARRIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operador Actual"])},
    "ORIGINAL_CARRIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operador Inicial"])},
    "GEOLOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geolocalización"])},
    "OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propietario"])},
    "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número"])}
  },
  "HISTORIC_PAGE": {
    "HISTORIC_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de Expedientes"])},
    "B2B_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de Empresa"])},
    "B2C_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de Particular"])},
    "TODAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoy"])},
    "LAST_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos 7 días"])},
    "ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])}
  },
  "TABLE": {
    "PER_PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por Página"])},
    "FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expediente"])},
    "SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen"])},
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
    "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
    "MARKET_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Mercado"])},
    "JOURNEY_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de Trayecto"])},
    "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
    "NUMBER_OF_TAX_NOTICES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Avisos de Impuestos"])},
    "NUMBER_OF_TAX_REPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Liasas Fiscales"])},
    "NUMBER_OF_ID_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de documentos de identificación"])},
    "NUMBER_OF_PASSPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Pasaportes"])},
    "NUMBER_OF_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Kbis"])},
    "NUMBER_OF_BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Datos Bancarios"])},
    "NUMBER_OF_DRIVING_LICENSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Licencias de Conducir"])},
    "NUMBER_OF_RESIDENCE_PERMITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Permisos de Residencia"])},
    "NUMBER_OF_PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de recibos de salario"])},
    "HOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora"])},
    "MAIL_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo Electrónico"])},
    "POSTAL_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección Postal"])},
    "TELEPHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación"])},
    "SCORE_SMB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de Fraude"])},
    "SCORE_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de Empresa"])},
    "TAG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiqueta"])},
    "STATUS_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado del Expediente"])},
    "DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalle"])},
    "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razón Social"])},
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificación"])},
    "OPERATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operador"])},
    "SYNTHESIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Síntesis"])},
    "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
    "FILE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de expediente"])},
    "DATE_AND_TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha y hora"])},
    "DOCUMENT_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de documento"])},
    "DOCUMENT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del documento"])}
  },
  "REGEX": {
    "NOT_NULL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No debe estar vacío."])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número no es válido."])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El correo electrónico no es válido."])},
    "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El formato debe ser DD/MM/AAAA."])},
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe contener 9 dígitos."])},
    "NATIONAL_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe contener 10 dígitos."])},
    "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha no válida."])},
    "LETTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe contener letras."])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe estar en formato IBAN."])},
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe contener solo letras."])},
    "LINKEDIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El enlace no es válido."])},
    "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe contener solo números"])},
    "UPPER_CASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe contener solo letras mayúsculas, números y guiones bajos."])},
    "FILE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólo puede contener letras, números y los símbolos _+%-"])}
  },
  "USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])},
  "FIRST_SEEN_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rastros de uso en la web"])},
  "NUMBER_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Identificación de la Empresa"])},
  "EMPLOYER_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación del empleador"])},
  "PROCESS_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceso de Lanzamiento de Open Banking"])},
  "INFORMATION_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de la Empresa"])},
  "SCORING_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de Confianza de la Empresa"])},
  "EMAIL_DOMAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominio de Correo Electrónico"])},
  "EMAIL_DOMAIN_CREATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de Creación del Dominio de Correo Electrónico"])},
  "EMAIL_DOMAIN_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculado con el Dominio de Correo Electrónico"])},
  "EMAIL_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculado al Correo Electrónico"])},
  "OTHER_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posesión de Otra Empresa"])},
  "IS_RECENTLY_ACQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adquirida Recientemente"])},
  "SCORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuaciones Financieras"])},
  "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo Electrónico"])},
  "EMAIL_CORRELATION_WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondencia entre el dominio del correo electrónico y el sitio web"])},
  "CHECK_IBAN": {
    "CONTAINER": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de IBAN"])},
      "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la empresa"])}
    },
    "RESULT": {
      "OK": {
        "IBAN_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El IBAN existe"])},
        "OWNER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tipo de titular es correcto"])},
        "REGISTRATION_NUMBER_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de registro coincide con el IBAN"])},
        "BIRTHDATE_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de nacimiento coincide con el IBAN"])},
        "NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre coincide"])},
        "BANK_CONNECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexión bancaria exitosa"])},
        "IBAN_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN válido"])},
        "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa conocida"])},
        "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa conocida"])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coincidencia entre el nombre y el IBAN"])},
        "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha válida"])},
        "SEPAMAIL_BANK_ELIGIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco elegible para SEPAMAIL"])},
        "REGISTRATION_NUMBER_ENROLLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa registrada en la plataforma"])},
        "ANALYSIS_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El análisis del historial de pagos permite vincular la empresa con el IBAN y determinar una frecuencia de pago promedio"])},
        "ANALYSIS_COMMUNITY_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El análisis del historial de pagos de la comunidad permite vincular la empresa con los datos bancarios"])},
        "FACTORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos bancarios no pertenecen a una empresa de factoring"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconocido"])},
        "BANK_ACCOUNT_LINKED_TO_COMPANY_FROM_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El análisis del historial de pagos permite vincular la empresa con los datos bancarios"])},
        "COMPANY_OR_ESTABLISHMENT_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La empresa está activa"])},
        "COUNTRY_COMPANY_COHERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La asociación entre el país de la empresa y el país de los datos bancarios es coherente"])},
        "FRAUDULENT_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos bancarios no han sido identificados como fraudulentos"])},
        "OTHER_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los otros nombres y apellidos coinciden"])},
        "PAYMENT_DEFAULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha reportado ninguna cesación de pagos para esta empresa"])},
        "REGISTRATION_NUMBER_MATCHES_TVA_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de IVA corresponde al IBAN"])},
        "SUSPICIOUS_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos bancarios no han sido identificados como potencialmente fraudulentos"])},
        "TOO_MANY_COMPANIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha encontrado una empresa para este IBAN"])},
        "UNKNOWN_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa conocida"])},
        "VIRTUAL_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos bancarios no corresponden a un IBAN virtual"])},
        "BANK_ACCOUNT_LINKED_TO_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coincidencia verificada: Los datos bancarios pertenecen a la empresa verificada."])}
      },
      "KO": {
        "IBAN_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El IBAN no existe"])},
        "OWNER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tipo de titular es incorrecto"])},
        "REGISTRATION_NUMBER_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de registro no coincide con el IBAN"])},
        "BIRTHDATE_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de nacimiento no coincide con el IBAN"])},
        "NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre no coincide"])},
        "BANK_CONNECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexión bancaria fallida"])},
        "IBAN_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN inválido"])},
        "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa desconocida"])},
        "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa desconocida"])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay coincidencia entre el nombre y el IBAN"])},
        "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha inválida"])},
        "SEPAMAIL_BANK_ELIGIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco no elegible para SEPAMAIL"])},
        "REGISTRATION_NUMBER_ENROLLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa no registrada en la plataforma"])},
        "ANALYSIS_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El análisis del historial de pagos no permite vincular la empresa con el IBAN"])},
        "ANALYSIS_COMMUNITY_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El análisis del historial de pagos de la comunidad no permite vincular la empresa con los datos bancarios"])},
        "FACTORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos bancarios probablemente pertenecen a una empresa de factoring o se utilizan para factoring"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconocido"])},
        "BANK_ACCOUNT_LINKED_TO_COMPANY_FROM_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El análisis del historial de pagos no pudo completarse porque los datos bancarios son desconocidos"])},
        "COMPANY_OR_ESTABLISHMENT_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La empresa está cerrada"])},
        "COUNTRY_COMPANY_COHERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La asociación no es posible, el país de la empresa y el país de los datos bancarios son diferentes"])},
        "FRAUDULENT_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos bancarios son fraudulentos"])},
        "BANK_ACCOUNT_CLOSED_LESS_THAN_13_MONTHS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cuenta bancaria fue cerrada hace menos de 13 meses"])},
        "BANK_ACCOUNT_CLOSED_MORE_THAN_13_MONTHS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cuenta bancaria fue cerrada hace más de 13 meses"])},
        "OTHER_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los otros nombres y apellidos no coinciden"])},
        "OWNER_TYPE_CORRECT_WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación en curso de la correspondencia entre los datos bancarios y la empresa"])},
        "PAYMENT_DEFAULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cesación de pagos reportada para esta empresa"])},
        "REGISTRATION_NUMBER_MATCHES_TVA_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de IVA no corresponde al IBAN"])},
        "SEPAMAIL_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEPAMAIL DIAMOND no puede realizar la verificación"])},
        "SUSPICIOUS_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos bancarios son potencialmente fraudulentos"])},
        "TECHNICAL_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha ocurrido un error técnico"])},
        "TOO_MANY_COMPANIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demasiadas empresas encontradas para este IBAN"])},
        "UNKNOWN_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa desconocida"])},
        "VIRTUAL_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos bancarios corresponden a un IBAN virtual"])},
        "BANK_ACCOUNT_LINKED_TO_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coincidencia no válida: Los datos bancarios no pertenecen a la empresa verificada"])}
      },
      "WARNING": {
        "IBAN_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No es posible verificar el IBAN"])},
        "OWNER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No es posible verificar el tipo de titular"])},
        "REGISTRATION_NUMBER_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No es posible verificar la correspondencia entre el número de registro y el IBAN"])},
        "BIRTHDATE_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No es posible verificar la fecha de nacimiento"])},
        "NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre no coincide completamente"])},
        "BANK_CONNECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexión bancaria fallida"])},
        "IBAN_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN desactivado (por ejemplo: cuenta cerrada)"])},
        "ICQX_ORDERING_PARTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICQX del ordenante no transmitido (El ICQX es el identificador único de un acreedor persona jurídica en la red SEPAmail)"])},
        "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa no encontrada"])},
        "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa no encontrada"])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha verificado la coincidencia entre el nombre y el IBAN"])},
        "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha no verificada"])},
        "SEPAMAIL_BANK_ELIGIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco no elegible para SEPAMAIL"])},
        "REGISTRATION_NUMBER_ENROLLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa no registrada en la plataforma"])},
        "ANALYSIS_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El análisis del historial de pagos no permite vincular la empresa con el IBAN y/o detecta una baja frecuencia de pagos"])},
        "ANALYSIS_COMMUNITY_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El análisis del historial de pagos de la comunidad no se pudo completar porque los datos bancarios son desconocidos"])},
        "FACTORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos bancarios podrían pertenecer a una empresa de factoring o usarse con ese fin"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconocido"])},
        "BANK_ACCOUNT_LINKED_TO_COMPANY_FROM_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El análisis del historial de pagos pone de manifiesto que los datos bancarios son conocidos pero están asociados a otra empresa, o que el historial de pagos es demasiado débil para confirmar la asociación entre la empresa y los datos bancarios"])},
        "COMPANY_OR_ESTABLISHMENT_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este establecimiento está cerrado, pero su empresa matriz está activa"])},
        "COUNTRY_COMPANY_COHERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La asociación no es posible, el país de la empresa y el país de los datos bancarios son diferentes"])},
        "FRAUDULENT_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos bancarios son fraudulentos"])},
        "BANK_ACCOUNT_CLOSED_LESS_THAN_13_MONTHS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cuenta bancaria fue cerrada hace menos de 13 meses"])},
        "BANK_ACCOUNT_CLOSED_MORE_THAN_13_MONTHS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cuenta bancaria fue cerrada hace más de 13 meses"])},
        "OTHER_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los otros nombres y apellidos no coinciden completamente"])},
        "OWNER_TYPE_CORRECT_WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación en curso de la correspondencia entre los datos bancarios y la empresa"])},
        "PAYMENT_DEFAULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cesación de pagos reportada para esta empresa"])},
        "REGISTRATION_NUMBER_MATCHES_TVA_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación imposible entre el número de IVA y el IBAN"])},
        "SEPAMAIL_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEPAMAIL DIAMOND no puede realizar la verificación"])},
        "SUSPICIOUS_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos bancarios son potencialmente fraudulentos"])},
        "TECHNICAL_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha ocurrido un error técnico"])},
        "TOO_MANY_COMPANIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demasiadas empresas encontradas para este IBAN"])},
        "UNKNOWN_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa desconocida"])},
        "VIRTUAL_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos bancarios corresponden a un IBAN virtual"])},
        "BANK_ACCOUNT_LINKED_TO_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La correspondencia entre los datos bancarios y la empresa no es verificable o la información no es elegible para la verificación"])}
      },
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de IBAN"])},
      "VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificaciones"])},
      "BANK_CONNECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexión con el banco"])},
      "typeTitulaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de titular"])},
      "dateNaissance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento"])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "autreNom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro nombre"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La solicitud de verificación no se pudo realizar debido a un fallo técnico."])},
      "IBAN_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El IBAN existe"])},
      "IBAN_NOT_EXISTS_ANYMORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El IBAN ya no existe y la cuenta se cerró hace menos de 13 meses."])},
      "IBAN_NOT_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El IBAN no existe o la cuenta se cerró hace más de 13 meses."])},
      "IBAN_CAN_BE_VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El IBAN no se puede verificar: el usuario solicitó que su cuenta no sea verificable con DIAMOND o la cuenta está en una moneda extranjera o la cuenta no es compatible con operaciones de retiros y transferencias, y por lo tanto está fuera de alcance..."])},
      "HOLDER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tipo de titular informado es correcto"])},
      "HOLDER_TYPE_INCORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tipo de titular informado es incorrecto"])},
      "SIREN_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de identificación informado coincide con el IBAN verificado"])},
      "SIREN_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de identificación informado no coincide con el IBAN verificado"])},
      "SIREN_CANNOT_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El banco no puede verificar el número de identificación (por ejemplo, porque no lo conoce)"])},
      "BIRTHDATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de nacimiento informada coincide con el IBAN verificado"])},
      "BIRTHDATE_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de nacimiento informada no coincide con el IBAN verificado"])},
      "BIRTHDATE_CANNOT_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El banco no puede verificar la fecha de nacimiento informada (por ejemplo, porque no la conoce)"])},
      "OWNER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tipo de propietario es correcto"])},
      "REGISTRATION_NUMBER_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de registro corresponde al IBAN"])},
      "BIRTHDATE_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de nacimiento corresponde al IBAN"])},
      "NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre coincide"])},
      "IBAN_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN inválido"])},
      "ICQX_ORDERING_PARTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICQX del ordenante"])},
      "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIREN"])},
      "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de registro"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha inválida"])},
      "SEPAMAIL_BANK_ELIGIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco elegible para SEPAMAIL"])},
      "REGISTRATION_NUMBER_ENROLLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de registro inscrito"])},
      "ANALYSIS_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis del historial de pagos"])},
      "ANALYSIS_COMMUNITY_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis del historial de pagos comunitarios"])},
      "FACTORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factoraje"])},
      "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconocido"])},
      "REASON_CODE": {
        "IncorrectAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN inválido"])},
        "UnrecognisedInitiatingParty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICQX del Ordenante"])},
        "InvalidIdentificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificación"])},
        "MissingName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "InvalidData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos inválidos"])},
        "BICInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco no elegible para el control SEPAMAIL"])}
      }
    },
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parece que el banco consultado está experimentando un problema de conexión con SepaMail. Gracias por su comprensión."])},
    "MEELO_RECOMMENDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendación Meelo"])},
    "RECOMMENDATION": {
      "DECISION": {
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conforme"])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precaución"])},
        "risky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerta"])}
      },
      "MESSAGE": {
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El análisis de IBAN no contiene alertas."])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El análisis de IBAN contiene algunas alertas."])},
        "risky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El análisis de IBAN contiene al menos una alerta importante."])}
      }
    }
  },
  "INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informaciones"])},
  "SYNTHESIS": {
    "FOLDER_STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de la carpeta"])},
    "ANALYST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analista"])},
    "LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculado"])},
    "VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificado"])},
    "JOURNEY_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador único"])},
    "PHONE_AND_SOCIAL_NETWORKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono y redes sociales"])},
    "LINKED_TO_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculado al correo electrónico"])},
    "LINKED_TO_PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculado al teléfono"])},
    "BUSINESS_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificación"])},
    "VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación"])}
  },
  "WARNING_LIST": {
    "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la empresa"])},
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificación"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron alertas durante nuestro análisis"])},
    "MESSAGE_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención, los siguientes parámetros fueron identificados en un caso de fraude anterior"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de advertencia Meelo"])},
    "MESSAGE_ALERT_SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención, el parámetro ingresado fue identificado en un caso de fraude anterior."])},
    "MESSAGE_NO_ALERT_SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron alertas durante nuestro análisis."])}
  },
  "SELECT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione los análisis que desea iniciar"])},
  "RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados"])},
  "PHONE_INDICATION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un indicador e ingrese un número sin espacios ni caracteres entre los números, por favor."])},
  "BIRTHDATE_INDICATION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altamente recomendado para mostrar el ecosistema del cliente."])},
  "EXAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejemplo"])},
  "FROM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
  "DOMAIN_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del dominio"])},
  "DOMAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominio"])},
  "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declarante"])},
  "REGISTRAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrador"])},
  "ADDITIONAL_CONTROLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controles adicionales"])},
  "COMPANY_DOMICILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa domiciliada"])},
  "EMAIL_DOMAIN_CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de creación del dominio del correo electrónico"])},
  "WEBSITE_DOMAIN_CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de creación del dominio del sitio web"])},
  "MORE_THAN_THREE_YEARS_WITHOUT_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de inactividad superior a 3 años"])},
  "LOCATION_AT_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubicación en riesgo"])},
  "OPERATOR_AT_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operador en riesgo"])},
  "INITIAL_RISKY_OPERATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operador inicial de riesgo"])},
  "NO_WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para obtener más información, inicie los puntajes"])},
  "HAS_OTHER_COMPANIES_IN_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otras empresas en procedimientos colectivos"])},
  "HAS_OTHER_COMPANIES_IN_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otras empresas radiadas"])},
  "HAS_OTHER_COMPANIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otra empresa"])},
  "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
  "SUPPORT_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Tiene algún problema? No dude en contactarnos"])},
  "SIDEBAR": {
    "KIT_B2C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de Consumidor"])},
    "KIT_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de Empresa"])},
    "KIT_OB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking"])},
    "KIT_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de Documento"])},
    "HISTORICAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico"])},
    "MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi Cuenta"])},
    "BATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lote"])},
    "HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayuda"])},
    "BACKOFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oficina trasera"])},
    "PORTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal"])},
    "MEELO_ACADEMY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academia Meelo"])},
    "MEELO_KIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo Kit"])},
    "KIT_RECOVERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperación"])},
    "DOCUMENT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de documento(s)"])},
    "USERS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis usuarios"])},
      "PROFILE_AND_ACCESS_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de perfiles y acceso"])},
      "USER_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de usuarios"])},
      "GROUP_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de grupos"])},
      "LOCATION_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de lugares"])}
    },
    "LIST_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de listas"])},
    "HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "MONITORING": {
      "MY_USAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis consumos"])},
      "COMPANY_USAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo de la compañía"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoreo de consumo"])}
    },
    "RULES_ENGINE": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motor de reglas"])},
      "COMPANY_RULES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas de la empresa"])},
      "SETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conjuntos de reglas"])}
    },
    "COMPANY": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi empresa"])},
      "MANAGE_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de listas"])}
    }
  },
  "BREADCRUMBS": {
    "COMPANY_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de la empresa"])},
    "APPLICANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitante"])},
    "DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decisión"])},
    "JUSTIFICATORIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justificaciones"])},
    "SCORE_CHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selección de puntuación"])},
    "RECEIVER_CHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selección del receptor"])},
    "UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carga"])},
    "BATCH_RUN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejecución por lotes"])}
  },
  "PROJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyecto"])},
  "PRODUCT": {
    "VEHICLES_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor total de los vehículos con IVA"])},
    "CONTRIBUTION_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto de la contribución"])}
  },
  "CGI": {
    "DECISION": {
      "SUCCESS": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Último paso!"])},
        "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanzar a la recopilación de documentos justificativos"])},
        "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar y Continuar"])}
      },
      "FAILED": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡La análisis ha terminado!"])},
        "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decisión desfavorable"])},
        "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminar"])}
      }
    },
    "DOCUMENTS": {
      "BLOCK": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarga de documentos"])}
      },
      "ID_CARD": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento de identidad"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anverso y reverso de su documento de identidad"])}
      },
      "BANK_DETAILS": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de cuenta bancaria internacional"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de cuenta bancaria internacional de tu cuenta principal"])}
      },
      "KBIS": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe de Registro Comercial"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS con fecha de menos de 3 meses"])}
      },
      "TAX_REPORT": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaración fiscal"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última declaración de impuestos, con al menos 2 años de historial"])}
      }
    },
    "CONGRATULATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felicidades"])},
    "END_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has completado el expediente en el portal Meelo."])}
  },
  "TITLE_ACTION_DOCUMENT_BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo de adquisición"])},
  "ONLY_PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo PDF"])},
  "MULTI_REQUESTS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud Múltiple"])},
    "DESCRIPTION_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En los últimos 30 días, se han creado uno o varios expedientes por"])},
    "DESCRIPTION_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["con información similar y/o idéntica."])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ningún expediente similar o idéntico registrado en los últimos 30 días."])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de registros con el mismo correo electrónico"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de registros con el mismo teléfono"])},
    "REGISTRATIONNUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de registros con el mismo número de registro"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de archivos con el mismo IBAN"])}
  },
  "RECOVERY": {
    "CLIENT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de cliente"])},
    "REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud"])},
    "FINANCIAL_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis financiero"])},
    "ANALYSIS_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de realización del análisis"])},
    "BANK_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información bancaria"])},
    "CUSTOMER_RELIABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiabilidad del cliente"])},
    "REJECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazo"])},
    "ON_AVERAGE_PER_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en promedio por mes"])},
    "INTERVENTION_FEES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifas de intervención"])},
    "THIRD_PARTY_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso a terceros"])},
    "GAMBLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juegos de azar"])},
    "FINANCIAL_SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situación financiera"])},
    "AVERAGE_DISPOSABLE_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingreso disponible promedio"])},
    "LOWEST_DISPOSABLE_INCOME_OVER_THE_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingreso disponible más bajo en el período"])},
    "LOWEST_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresos más bajos"])},
    "LACK_OF_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausencia de ingresos"])},
    "AVERAGE_MONTHLY_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingreso mensual promedio"])},
    "FINANCIAL_PRESSURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presión financiera"])},
    "CREDITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créditos"])},
    "DEBT_COLLECTION_AGENCIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresas de cobro de deudas"])},
    "RISK_LEVEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel de riesgo"])},
    "LOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bajo"])},
    "MODERATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderado"])},
    "RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arriesgado"])},
    "LACK_OF_INCOME_SOURCES_DETECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha detectado una o varias fuentes de ingresos"])},
    "LACK_OF_INCOME_SOURCES_NOT_DETECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se detectaron ingresos"])}
  },
  "BATCH": {
    "CLICK_ON_SCORES_BELOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz clic en uno o más puntajes a continuación para elegir los puntajes a ejecutar a través del lote."])},
    "MANUAL_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrucciones"])},
    "MANUAL_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para un uso óptimo del lote, le recomendamos que descargue la plantilla proporcionada arriba. Estará compuesta por la estructura ideal adaptada para todos los puntajes seleccionados previamente. Luego puede completar el archivo de Excel con sus datos y cargarlo un poco más abajo en la página."])},
    "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelación del Lote exitosa"])},
    "DOWNLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar el resultado del lote"])},
    "STARTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio del Lote en progreso..."])},
    "RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acaba de recibir un correo electrónico con el resultado del lote"])},
    "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar el lote"])},
    "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar el lote"])},
    "USAGE_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de uso"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
    "DOWNLOAD_TEMPLATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar la plantilla"])},
    "LIMIT_PER_DAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["líneas / archivo"])},
    "FILE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del archivo de salida deseado"])},
    "SCORE": {
      "REQUEST_ID": {
        "WEB_SCORE_B2C_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de Fraude de Identidad"])},
        "SMB_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de Fraude de Identidad Profesional"])},
        "WEB_SCORE_B2B_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de Confianza de la Empresa"])},
        "ENERGY_SCORE_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de Evaluación del Riesgo del Sector Energético"])},
        "E_REPUTATION_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación E-Reputación"])},
        "E_PAYMENT_RISK_SCORE_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de Riesgo"])},
        "ALTARES_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación Altares"])},
        "ELLISPHERE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación Ellisphere"])},
        "CREDIT_SAFE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación CréditSafe"])}
      },
      "DESCRIPTION": {
        "WEB_SCORE_B2C_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Puntaje de Fraude de Identidad es un modelo que permite evaluar el riesgo de fraude de identidad de un cliente. Se basa en la evaluación de varios criterios: nombre, apellido, correo electrónico, teléfono, así como la dirección postal o la fecha de nacimiento."])},
        "SMB_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Puntaje de Fraude de Identidad Profesional es un modelo que permite evaluar el riesgo de fraude de identidad de un cliente profesional (en un contexto B2B). Se basa en la evaluación de varios criterios: nombre, apellido, correo electrónico, teléfono, así como la dirección postal o la fecha de nacimiento."])},
        "WEB_SCORE_B2B_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El servicio de puntuación 'Score Confiance Entreprise' es una herramienta de evaluación de la empresa y de la relación entre el solicitante (persona física) y la empresa (persona jurídica)."])},
        "ENERGY_SCORE_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El 'Puntuación de Riesgo Energético' es un modelo diseñado específicamente para el sector energético que evalúa el riesgo de impagos del cliente. Se basa en la evaluación de varios criterios: el operador de telecomunicaciones / el banco / los datos IRIS sobre la dirección postal / el monto mensual (exacto o estimado)."])},
        "E_REPUTATION_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Score E-Reputación es un modelo diseñado para evaluar la satisfacción y la confianza de los consumidores hacia la empresa basándose en las diferentes opiniones que han dejado."])},
        "E_PAYMENT_RISK_SCORE_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Score de Riesgo es un modelo diseñado para evaluar la solvencia de una persona basándose en su información personal así como en su información de pago."])},
        "ALTARES_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Score Altares es un modelo diseñado para evaluar el riesgo de solvencia de una empresa basado en los resultados proporcionados por la compañía de calificaciones financieras Altares."])},
        "ELLISPHERE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Score Ellisphere es un modelo diseñado para evaluar el riesgo de solvencia de una empresa basado en los resultados proporcionados por la compañía de calificaciones financieras Ellisphere."])},
        "CREDIT_SAFE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Score CréditSafe es un modelo diseñado para evaluar el riesgo de solvencia de una empresa basado en los resultados proporcionados por la compañía de calificaciones financieras CréditSafe."])}
      }
    }
  },
  "FILE": {
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elegir un archivo"])}
  },
  "IMPORTANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importante"])},
  "RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arriesgado"])},
  "BACKOFFICE": {
    "GROUPS": {
      "COMPANY_GROUPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de grupos"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
      "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
      "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar grupo"])},
      "CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear grupo"])},
      "DELETE_WARN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este grupo es utilizado por usuarios, ¿estás seguro de que quieres eliminarlo?"])}
    },
    "PLACES": {
      "COMPANY_PLACES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de lugares"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
      "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
      "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar lugar"])},
      "CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear lugar"])},
      "DELETE_WARN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este lugar es utilizado por usuarios, ¿estás seguro de que quieres eliminarlo?"])}
    },
    "USERS": {
      "SHORTCUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los atajos"])},
      "COMPANY_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios de la compañía"])},
      "GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
      "PLACE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lugar"])},
      "PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
      "MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerente"])},
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
      "USER_MODIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificación de usuario"])},
      "PROFILE_ASSIGNMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignaciones de Perfiles"])},
      "USER_DELETION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminación de usuario"])},
      "DELETE": {
        "MESSAGE_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estás a punto de eliminar al usuario"])},
        "OF_THE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de la empresa"])},
        "MESSAGE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El usuario ya no podrá acceder a su cuenta."])},
        "ACTION_IRREVERSIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta acción es irreversible."])},
        "QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro de que desea eliminar a este usuario?"])}
      },
      "USER_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil de usuario"])},
      "TECHNICAL_ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones técnicas"])},
      "SUPERVISOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisor"])},
      "ACTIVE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta activa"])},
      "UPDATE_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El estado de la cuenta ha sido modificado correctamente."])},
      "USER_ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar usuario"])},
      "RESET_PASSWORD": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer una contraseña"])},
        "MESSAGE_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estás a punto de enviar un correo electrónico de restablecimiento de contraseña para el usuario"])},
        "MESSAGE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a la dirección de correo electrónico"])},
        "SENDING_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico de restablecimiento de contraseña enviado"])}
      },
      "RESET_API_KEY": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer una clave API"])},
        "CONFIRM_RESET_USER": {
          "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está a punto de enviar un correo electrónico de restablecimiento de clave API para el usuario"])},
          "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a la dirección de correo electrónico"])},
          "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importante : El enlace para recuperar la clave API solo es válido por 30 minutos."])}
        },
        "CONFIRM_RESET_US": {
          "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estás a punto de restablecer tu clave API."])},
          "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por razones de seguridad, el valor de su nueva clave solo se puede recuperar una vez."])},
          "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta nueva clave se copiará directamente a su portapapeles digital."])},
          "FOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le recomendamos que lo guarde inmediatamente en un lugar seguro para poder reutilizarlo más tarde."])}
        },
        "SENDING_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico de restablecimiento de clave API enviado"])},
        "NEW_API_KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su nueva clave API se ha copiado en su portapapeles"])}
      }
    },
    "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compañía"])},
    "FUNCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Función"])},
    "ADVANCED_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario avanzado"])},
    "ADVANCED_USER_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede crear otros usuarios avanzados y/o básicos y acceder a ciertas configuraciones."])},
    "PROFILE_AND_ACCESS": {
      "COMPANY_PROFILES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfiles de la empresa"])},
      "PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisos"])},
      "MESSAGE_PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, selecciona un perfil arriba para ver la lista de permisos asociados."])},
      "CREATED_BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creado por"])},
      "KIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit"])},
      "MODULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módulo"])},
      "RIGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derechos"])},
      "ADDING_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un perfil"])},
      "PROFILE_MODIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificación de perfil"])},
      "SAVE_PERMISSION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se han detectado cambios, no olvides guardarlos utilizando el botón de arriba."])},
      "DESCRIPTION_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción del perfil"])},
      "NO_PROFILE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ningún perfil"])}
    },
    "PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisos"])},
    "MONITORING": {
      "DOWNLOAD_BALANCE_SHEET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar el balance"])},
      "TOTAL_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo total"])},
      "MONTHLY_EVOLUTION_CURVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curva de evolución mensual"])},
      "TODAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoy"])},
      "THIS_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta semana"])},
      "LAST_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La semana pasada"])},
      "THIS_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este mes"])},
      "LAST_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El mes pasado"])},
      "GENERAL_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo general"])},
      "COMPANY_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo para la empresa"])},
      "DATA_ON_THE_WAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sus datos están en camino..."])},
      "CONSUMPTION": {
        "OCR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de documento de identidad"])},
        "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis documental"])},
        "CHECK_VALUE_COMPANY_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de IBAN B2B"])},
        "CHECK_VALUE_PERSONAL_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de IBAN B2C"])},
        "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de la empresa"])},
        "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis LCB-FT"])},
        "GET_LINKEDIN_PROFILE_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enriquecimiento de LinkedIn"])},
        "OPENBANKING_MEELO_OPENBANKING_PERSONAL_SCORING_MODEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking B2C"])},
        "OPENBANKING_MEELO_OPENBANKING_PROFESSIONAL_SCORING_MODEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking B2B"])},
        "SCORING_ELLISPHERE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación Ellisphère"])},
        "SCORING_CREDIT_SAFE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación CréditSafe"])},
        "SCORING_WEB_SCORE_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación Confianza Empresa"])},
        "SCORING_WEB_SCORE_B2C_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación Identidad Particular"])},
        "SCORING_E_REPUTATION_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación E-Reputación"])},
        "SCORING_ALTARES_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación Altares"])},
        "SCORING_SMB_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación Identidad Profesional"])},
        "SELECTED_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo de usuarios seleccionados"])},
        "OF_THE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo de la empresa"])},
        "OF_MY_TEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo de mi equipo"])},
        "MY_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi consumo"])}
      },
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han encontrado datos. Ejecute un análisis inicial para poder consultar su consumo."])}
    },
    "RULES_ENGINE": {
      "RULES": {
        "FOR_API_IMPLEMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para la implementación de la API"])},
        "EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquí encontrará la lista de reglas aprobadas para su empresa."])},
        "RULES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas"])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "CONTAINED_IN_A_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenida en un conjunto"])},
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
        "GENERIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genérica"])},
        "SPECIFIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Específica"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
        "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acción"])},
        "MODIFY_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar regla"])},
        "RULE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la regla"])},
        "RULE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción de la regla"])},
        "VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor"])},
        "DEFAULT_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor por defecto"])},
        "PARAMETERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros"])},
        "ARGUMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argumentos"])},
        "EDIT_PARAMETER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar parámetro"])},
        "PARAMETER_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del parámetro"])},
        "PARAMETER_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción del parámetro"])},
        "FLOAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimal"])},
        "INTEGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entero"])},
        "STRING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto"])},
        "BOOLEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booleano"])},
        "LIST_STRING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de textos"])},
        "RULE_PRESENT_IN_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regla presente en los siguientes conjuntos"])},
        "RULE_V1_NOT_EDITABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta regla es una regla V1 y no es editable. Si desea modificarla, por favor contacte a Meelo."])}
      },
      "SETS": {
        "SETS_RULES_EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para ver las reglas de un conjunto, por favor seleccione la fila correspondiente en el área de tabla izquierda."])},
        "NO_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay conjuntos de reglas para su empresa. Por favor contacte a Meelo para crear uno."])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "SETS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de conjuntos"])},
        "SETS_RULES_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de reglas del conjunto"])},
        "EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquí encontrará la lista de conjuntos de reglas creados para su empresa."])},
        "ADD_OR_DELETE_RULE_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para cualquier cambio relacionado con agregar o eliminar una regla en un conjunto, por favor contacte a Meelo."])}
      }
    },
    "MY_COMPANY": {
      "USEFUL_CONTACTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactos útiles"])},
      "KITS_AND_MODULES_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kits y módulos disponibles"])},
      "ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador"])},
      "SUPPORT_MEELO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apoyo a Meelo"])}
    }
  },
  "RESEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investigación"])},
  "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
  "PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página"])},
  "ON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sobre"])},
  "RESULTS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados encontrados"])},
  "NUMBER_OF_ITEMS_PER_PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de elementos por página"])},
  "SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen"])},
  "DATA_CONTACTABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactabilidad de datos"])},
  "AND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y"])},
  "UNREACHABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["incontactable"])},
  "LOCALE": {
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alemán"])},
    "EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglés"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francés"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neerlandés"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugués"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bélgica"])}
  },
  "ADVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reseñas"])},
  "E_REPUTATION": {
    "AVERAGE_GRADE_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promedio de calificaciones no encontrado"])},
    "LIST_TEN_LAST_REVIEWS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de las últimas 10 reseñas"])},
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de E-Reputación de Meelo"])},
    "NEGATIVE_REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opinión negativa"])},
    "REVIEW_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario de la reseña"])}
  },
  "NO_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin comentarios"])},
  "PRINT": {
    "FOLDER_INFO": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del expediente"])},
      "CREATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de creación"])},
      "FOLDER_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado del expediente"])},
      "ANALYST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analista"])},
      "UNIQ_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID único"])}
    },
    "APPLICANT_OB": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitante"])},
      "OB_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de OpenBanking"])},
      "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
      "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "BIRTH_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de Nacimiento"])},
      "BIRTH_PLACE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lugar de Nacimiento"])},
      "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo Electrónico"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono Móvil"])},
      "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección Postal"])},
      "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco"])},
      "HOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titular"])},
      "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la Cuenta"])},
      "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "MAIN_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta Principal"])},
      "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salario Mensual Promedio"])},
      "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alquiler Mensual Promedio"])},
      "MONTH_END_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proporción de Gastos sobre Ingresos"])},
      "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo Mensual"])},
      "BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo Actual"])},
      "TOP_BANKING_SUPERVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisión Bancaria"])},
      "TOP_DEFERRED_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detección de Débito Diferido"])},
      "GLOBAL_TRANSACTIONS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Transacciones"])}
    },
    "DOCUMENT_ANALYSIS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de Documentos"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
      "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])},
      "COMPLIANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumplimiento"])},
      "DOCUMENT_CONTROL": {
        "ID_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento de identidad"])},
        "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento de identidad"])},
        "DRIVING_LICENSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licencia de conducir"])},
        "PASSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasaporte"])},
        "RESIDENCE_PERMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permiso de residencia"])},
        "KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS - Compañía o Situación"])},
        "INDIVIDUAL_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS Aviso de situación"])},
        "COMPANY_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extracto de KBIS"])},
        "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticidad - Análisis de no alteración digital"])},
        "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaración de impuestos"])},
        "TAX_REPORT_S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaración de impuestos simplificada"])},
        "TAX_REPORT_C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaración de impuestos compleja"])},
        "TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso de impuestos - Ingresos o Propiedad"])},
        "INCOME_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuesto sobre la Renta"])},
        "PROPERTY_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuesto sobre Bienes Inmuebles"])},
        "BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles Bancarios"])},
        "PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nómina"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconocido"])},
        "FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control biométrico"])},
        "BANK_STATEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extracto bancario"])}
      }
    },
    "SCORE_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la Puntuación"])},
    "CHECK_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de IBAN"])},
    "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucha contra el Lavado de Dinero y el Financiamiento del Terrorismo"])},
    "OB_ANALYSIS_DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis Detallado de OpenBanking"])},
    "ANALYSIS_B2B": {
      "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
      "COMPANY_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de la empresa"])},
      "LEGAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información legal"])},
      "CLIENT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del cliente"])},
      "CORPORATE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre corporativo"])},
      "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de registro"])},
      "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
      "WORKFORCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuerza laboral"])},
      "CREATED_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de creación"])},
      "VAT_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificación fiscal"])},
      "ACTIVITY_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de actividad"])},
      "LEGAL_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma legal"])},
      "CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital"])},
      "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
      "FIELD_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo de actividad"])},
      "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de cierre"])},
      "DOMICILIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domiciliación"])},
      "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrante"])},
      "WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio web"])},
      "REGISTRAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrador"])},
      "BUSINESS_OWNERS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accionistas y beneficiarios conocidos"])},
      "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento"])},
      "NATIONALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nacionalidad"])},
      "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posición"])},
      "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificación"])},
      "WARNING_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de advertencias Meelo"])},
      "BUSINESS_REPRESENTS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directivos y representantes conocidos"])},
      "SCORE_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la puntuación"])},
      "COMPANY_SCORE_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de confianza de la empresa"])},
      "COMPANY_FINANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzas de la empresa"])},
      "COMPANY_LEGAL_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentos legales de la empresa"])},
      "ANNUAL_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuentas anuales"])},
      "ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establecimientos"])},
      "COMPANY_EVOLUTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evolución de la empresa"])},
      "SCORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuaciones financieras"])},
      "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reputación electrónica"])},
      "OB_ANALYSIS_DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis detallado de OpenBanking"])},
      "ALERTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertas"])},
      "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de IBAN"])},
      "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucha contra el lavado de dinero y el terrorismo"])}
    },
    "COVER_PAGE": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe sobre los controles realizados"])},
      "CREATED_BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo creado por"])},
      "CREATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo creado el"])},
      "DOC_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis Empresarial"])},
      "DOC_B2C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis Particular"])},
      "DOC_OB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis Open-Banking"])},
      "DOC_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de Documento"])},
      "DOC_SYNTHESIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Síntesis de Documento"])}
    }
  },
  "ENRICHMENT": {
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para iniciar una búsqueda de coordenadas, por favor pegue a continuación la URL del perfil de LinkedIn de su contacto."])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enriquecimiento de datos"])},
    "NOPROFIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ningún perfil de LinkedIn."])},
    "NOCORDINATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron coordenadas."])},
    "TITLE_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordenadas encontradas :"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email(s)"])},
    "EMAIL_PRO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email(s) profesional(es) :"])},
    "EMAIL_PERSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email(s) personal(es) :"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono(s):"])},
    "NO_PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ningún teléfono"])},
    "NO_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ningún email"])},
    "MATCH": {
      "EMAIL_PERSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coincidencia con correo electrónico personal introducido"])},
      "EMAIL_PRO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coincidencia con correo electrónico profesional introducido"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coincide con el teléfono introducido"])}
    }
  },
  "NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOVEDAD"])},
  "DECLARATION": {
    "FRAUD": {
      "BUTTON_SIDEBAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["declarar un fraude"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaración de fraude"])},
      "EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, complete el formulario a continuación para los casos que involucren robo de identidad o fraude confirmado."])},
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Fecha de recepción de la solicitud fraudulenta"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Tipo de caso"])},
      "MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Dirección de correo electrónico declarada"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Número de teléfono declarado"])},
      "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto estimado del fraude"])},
      "URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL del caso"])},
      "AVOID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿ Se evitó el fraude ?"])},
      "TOOLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con qué herramienta(s)"])},
      "COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿ Qué elementos te hacen pensar que es fraude ?"])},
      "CONDITION_FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" * Acepto los "])},
      "CONDITION_SECOND_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["términos generales de uso"])},
      "CONDITION_THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" de Meelo sobre la declaración manual de fraude"])},
      "PROFESIONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesional"])},
      "INDIVIDUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particular"])}
    }
  },
  "RECOMMENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información opcional, pero muy recomendada"])},
  "THANKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GRACIAS"])},
  "CONFIRM_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su declaración ha sido enviada exitosamente"])},
  "MODULE": {
    "ALTARES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de Altares"])},
    "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCBFT"])},
    "BUSINESS_OWNERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propietarios de Negocios Efectivos"])},
    "CHECK_IBAN_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de IBAN B2B"])},
    "COMPANY_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertas de Empresa"])},
    "COMPANY_ANNUAL_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuentas Anuales de Empresa"])},
    "COMPANY_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establecimientos de Empresa"])},
    "COMPANY_EVOLUTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evolución de Empresa"])},
    "COMPANY_FINANCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzas de Empresa"])},
    "COMPANY_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de Empresa"])},
    "COMPANY_LEGAL_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentos Legales de Empresa"])},
    "COMPANY_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de Confianza de Empresa"])},
    "CREDIT_SAFE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de Crédito Seguro"])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Reputación"])},
    "OB_ALERTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertas de Open Banking"])},
    "LITIGATION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situación jurídica - Litigios"])},
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juzgado por "])},
      "DEMAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demanda: "])},
      "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localización: "])},
      "MAIN_HEARING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main hearing"])},
      "SUMMARY_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary procedure"])},
      "UKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconocido"])}
    },
    "FINANCIAL_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sección de análisis de riesgos financieros"])}
  },
  "CARTOGRAPHY": {
    "COMPANY_COLLECTIVE_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa en Procedimiento Colectivo"])},
    "BUSINESS_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre Comercial"])},
    "CARTOGRAPHY_OF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartografía de"])},
    "STUDIED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa analizada"])},
    "ACTIVE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa Activa"])},
    "COLLECTIVE_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa en Procedimiento Colectivo"])},
    "COMPANY_CARTOGRAPHY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartografía de la Empresa"])},
    "DEREGISTERED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa Radiada"])},
    "INDIVIDUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona Física"])},
    "COMPANY_ACTIVITIES_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de actividad de la empresa"])},
    "COMPANY_ACTIVITIES_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la actividad de la empresa"])},
    "RECENTLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recientemente"])},
    "UNVERIFIED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa no verificada"])},
    "OPEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrandar"])}
  },
  "MENU": {
    "MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi cuenta"])},
    "MY_CONSUPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi consumo"])},
    "PORTAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del portal"])},
    "LOG_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión como"])},
    "BACK_OFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back-office"])},
    "LOGOUT_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión de la cuenta del cliente"])},
    "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])}
  },
  "CONNECT_AS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión como "])},
    "PARAGRAPH": {
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está a punto de iniciar sesión como"])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de la empresa"])},
      "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por lo tanto, verá el portal de acuerdo con los permisos otorgados a este usuario."])},
      "FOURTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas sus acciones se rastrearán bajo el nombre del cliente y el suyo."])},
      "FIFTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor,"])},
      "SIXTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sea cauteloso"])},
      "SEVENTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al usar el portal como cliente, y"])},
      "EIGHTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["respete la confidencialidad"])},
      "NINTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de los datos de nuestros clientes."])},
      "TENTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No olvide"])},
      "ELEVENTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cerrar sesión en la cuenta del cliente"])},
      "TWELFTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["una vez completadas sus acciones."])}
    },
    "TOAST": {
      "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya no está conectado como"])},
      "LOG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora está conectado como"])}
    },
    "BADGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conectado como"])},
    "MODAL": {
      "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa"])},
      "USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])}
    }
  },
  "PORTAL_INFORMATION": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información en el portal MEELO"])},
    "SUBTITLE": {
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El portal Meelo"])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" : Una recomendación de un vistazo"])}
    },
    "DESCRIPTION": {
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los productos Meelo a tu alcance para que tomes tu decisión en tan solo unos minutos."])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestro portal de apoyo a la decisión le permite utilizar los productos Meelo necesarios para la adecuada verificación del cliente que entra en contacto con usted."])},
      "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de fraude, verificación de identidad, banca abierta, verificación de documentos… Los productos Meelo a tu alcance con una mínima integración técnica."])}
    },
    "VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versión de su portal"])},
    "ENVIRONMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medio ambiente"])},
    "PRODUCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producción"])},
    "DEVELOPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollo"])},
    "STAGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puesta en escena"])},
    "GDPR": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RGPD"])},
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damos gran importancia a la protección de sus datos personales. De acuerdo con el Reglamento General de Protección de Datos (GDPR), solo recopilamos la información necesaria, obtenemos su consentimiento explícito, utilizamos sus datos y los de sus clientes de forma legítima, garantizamos su seguridad, limitamos su retención y respetamos sus derechos."])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tiene alguna pregunta o inquietud respecto a esta política de privacidad, puede consultar nuestra"])},
      "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" o póngase en contacto con nuestro DPO, en "])}
    },
    "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagina dedicada"])}
  },
  "MAINTENANCE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los siguientes servicios pueden experimentar problemas: "])},
    "FINISHED_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuerde actualizar su puerta para un uso óptimo."])},
    "ONGOING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantenimiento en curso"])},
    "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantenimiento finalizado"])},
    "SERVICE": {
      "AML_CFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de Lucha contra el Blanqueo de Capitales y Financiación del Terrorismo"])},
      "CHECKOUT_PROXY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de Firma Electrónica"])},
      "DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de Subida de Documentos"])},
      "DOCUMENT_PROXY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de Control de Documentos"])},
      "DOCUMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de Documentación Externa"])},
      "IAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de Autenticación"])},
      "OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de Banca Abierta"])},
      "PORTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal Meelo"])},
      "RULES_ENGINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motor de Reglas"])},
      "SCORING_PROXY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de Puntuación"])},
      "TASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de Gestión de Tareas Internas"])},
      "TAX_NOTICE_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de Análisis de Avisos Fiscales"])},
      "TAX_REPORT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de Análisis de Declaraciones Fiscales"])},
      "TRANSLATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio Interno de Traducción"])},
      "SHORTENER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio Interno de Acortamiento de URL"])},
      "CHECK_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de Enriquecimiento de Empresas"])},
      "UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio Interno de Subida de Documentos"])},
      "TRACKETIO_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de Análisis de Elementos de Seguimiento Meelo"])},
      "STORAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio Interno de Almacenamiento de Documentos"])},
      "ALERTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de Alertas"])},
      "TRACKETIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de Seguimiento de Usuarios en el Navegador"])},
      "OPEN_REVENUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de Ingresos Abiertos"])},
      "ENRICHMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de Enriquecimiento de Datos"])},
      "FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de Búsqueda y Coincidencia de Imágenes"])},
      "CHECK_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de Verificación de IBAN"])}
    }
  },
  "FOLDER": {
    "WORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["carpeta"])},
    "STATE": {
      "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En curso"])},
      "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptado"])},
      "REFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazado"])},
      "CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creado"])},
      "WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En espera"])},
      "CANCELED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelado"])},
      "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminado"])},
      "TODO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tratar"])},
      "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En error"])}
    },
    "DELETION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminación de la carpeta"])},
      "CONTENT": {
        "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está a punto de eliminar el"])},
        "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y los datos que lo constituyen"])},
        "QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro de que desea eliminar esta carpeta?"])},
        "QUESTION_MOTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro de que desea eliminar este motivo?"])}
      }
    },
    "EDITION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualización del estado del expediente"])},
      "CONTENT": {
        "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está a punto de"])},
        "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya no podrá continuar ingresando datos en este expediente"])},
        "QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro de que desea modificar el estado del expediente?"])}
      },
      "STATE": {
        "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poner en marcha"])},
        "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aceptar"])},
        "REFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rechazar"])},
        "WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esperar"])},
        "CANCELED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelar"])},
        "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eliminar"])}
      }
    },
    "UPDATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado del expediente actualizado"])}
  },
  "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.º"])},
  "NOTIFICATIONS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin notificaciones."])},
    "NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novedades "])},
    "READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer "])},
    "MARK_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MARCAR TODO COMO LEÍDO"])},
    "DELETE_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BORRAR TODO"])},
    "DATE": {
      "TODAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOY"])},
      "YESTERDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AYER"])},
      "OLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIEJO"])}
    },
    "TIMESTAMP": {
      "NOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora mismo"])},
      "PREFIX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hace"])},
      "SUFFIX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "MINUTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minuto"])},
      "MINUTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutos"])},
      "HOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hora"])},
      "HOURS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["horas"])}
    },
    "ACTIONS": {
      "CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
    },
    "SERVICES": {
      "OPEN_BANKING": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis OpenBanking"])},
        "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking Análisis - Fallo en el análisis"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La solicitud de análisis OpenBanking para la empresa "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha sido completada."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se ha completado. Haga clic en la notificación para ver el resultado."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El análisis de OpenBanking ha encontrado un problema. Haga clic en la notificación para ver el archivo."])}
      },
      "LIVE_CHECK": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levantamiento de dudas - Análisis completado"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levantamiento de dudas - Fallo en el análisis"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El levantamiento de dudas del expediente "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha finalizado."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha finalizado. Haga clic en la notificación para consultar el resultado."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un problema con el levantamiento de dudas. Haga clic en la notificación para consultar el expediente."])}
      },
      "BATCH_CHECK_COMPANY": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis empresarial - Lote completado"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis empresarial - Fallo en el lote"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El lote de análisis empresarial "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha finalizado."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha finalizado."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El lote de análisis empresarial encontró un problema."])}
      },
      "BATCH_CHECK_IBAN": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis IBAN - Lote completado"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis IBAN - Fallo en el lote"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El lote de análisis IBAN "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha finalizado."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha finalizado."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El lote de análisis IBAN encontró un problema."])}
      },
      "BATCH_SCORING_PROXY": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring - Lote completado"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring - Fallo en el lote"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El lote de análisis de scoring "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha finalizado."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha finalizado."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El lote de análisis de scoring encontró un problema."])}
      },
      "FACE_MATCHING": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control biométrico - Análisis completado"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control biométrico - Fallo en el análisis"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La verificación biométrica del expediente "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha finalizado."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha finalizado. Haga clic en la notificación para ver el resultado."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La verificación biométrica encontró un problema. Haga clic en la notificación para ver el expediente."])}
      }
    }
  },
  "STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "OF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
  "THE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["el"])},
  "SCORE_CREDIT_SAFE": {
    "RISK_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riesgo financiero"])},
    "PAYMENT_TREND_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendencia de pago"])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos no encontrados."])},
    "OTHER_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otra información adicional"])},
    "PRESENCEOFJUDGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presencia de sentencia"])},
    "PRESENCEOFPRIVILEGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presencia de privilegio"])},
    "PRESENCEOFSANCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presencia de la sanción de la DGCCRF"])},
    "HEADBAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para obtener más información sobre la salud financiera de esta empresa, active su suscripción CreditSafe"])},
    "RISK": {
      "WEAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bajo riesgo financiero"])},
      "MODERATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riesgo financiero moderado"])},
      "HIGH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alto riesgo financiero"])},
      "NOT_SCORED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No verificable"])}
    },
    "PAYMENT_TREND": {
      "WORSENING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendencia de pago en deterioro"])},
      "IMPROVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tendencia de pago mejora"])},
      "STABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendencia de pago estable"])},
      "NOT_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendencia de pago no disponible"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendencia de pago no verificable"])}
    }
  },
  "MALICIOUS_PAYLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de entrada no válidos"])},
  "RESET_API_KEY": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su clave API"])},
    "WARNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo puede consultar su nueva clave API una vez"])},
    "CANNOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya no puede visualizar su clave API, por favor póngase en contacto con su referente o contacte con el soporte de Meelo"])},
    "COPY_YOUR_KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar su clave"])}
  },
  "DQE_CONTROL": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coincidencia de la línea telefónica - ID móvil"])},
    "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error, la búsqueda no se pudo completar."])},
    "NO_SEARCH_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este número de teléfono no puede ser verificado actualmente por el operador."])},
    "MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coincidencia"])},
    "KEY": {
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
      "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección postal"])}
    },
    "LAUNCH_MATCHING_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar la búsqueda de coincidencias"])},
    "NO_GLOBAL_DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La verificación se ha realizado pero no puede completarse."])}
  },
  "MOTIVE": {
    "THIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de motivos"])},
    "REASON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo razón"])},
    "TABLE": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiqueta"])},
      "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
      "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
      "NODATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No existe ningún motivo."])}
    },
    "MODAL": {
      "CREATE_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un motivo"])},
      "UPDATE_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar un motivo"])},
      "DELETE_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar un motivo"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiqueta"])},
      "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
      "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivo"])},
      "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
    },
    "TOAST": {
      "SUCCESS": {
        "CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patrón creado con éxito"])},
        "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patrón editado con éxito"])},
        "DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patrón eliminado con éxito"])}
      },
      "FAILED": {
        "CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al crear el patrón"])},
        "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al editar el patrón"])}
      }
    }
  },
  "CHECK_LIST": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Síntesis Global"])},
    "MEELO_RECOMMENDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendación Meelo"])},
    "RULES": {
      "credit-safe-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación Financiera"])},
      "web-score-b2b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de Confianza Empresarial"])},
      "smb-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de Confianza de Identidad"])},
      "company-radiation-and-collective-proceedings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa Dada de Baja o en Procedimiento Colectivo"])},
      "id-document-analysis-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentos de Identidad"])},
      "document-authenticity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticidad del Documento"])},
      "check-iban-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "phone-carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono Riesgoso"])},
      "warning-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de Advertencia"])},
      "web-score-b2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de Confianza de Identidad"])},
      "ob-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de OpenBanking"])}
    },
    "RECOMMENDATION": {
      "DECISION": {
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buena"])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
        "risky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arriesgada"])}
      },
      "MESSAGE": {
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El expediente no contiene alertas; se han realizado las verificaciones de los elementos del expediente, y es posible continuar."])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El expediente contiene algunas alertas; le sugerimos actuar con precaución y realizar un control reforzado (como contactar al empleador, contador)."])},
        "risky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El expediente contiene una alerta importante; se recomienda encarecidamente realizar un control reforzado antes de avanzar positivamente con este expediente."])}
      }
    },
    "NUMBER_OF_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de verificaciones realizadas"])}
  },
  "CLIENT_CONTEXT": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contexto"])},
    "COMPANIES": {
      "MEELO": {
        "INIT": {
          "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
          "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
          "maidenName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido de soltera"])},
          "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tratamiento"])},
          "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento"])},
          "socialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de seguridad social"])},
          "familySituation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situación familiar"])},
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de correo electrónico"])},
          "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
          "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° y calle"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad"])},
          "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
          "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
          "disabledWorker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabajador discapacitado"])}
        },
        "CONSENT": {
          "CONSENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentimiento"])}
        },
        "CLIENT": {
          "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
          "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
          "maidenName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido de soltera"])},
          "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tratamiento"])},
          "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento"])},
          "socialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de seguridad social"])},
          "familySituation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situación familiar"])},
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de correo electrónico"])},
          "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
          "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° y calle"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad"])},
          "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
          "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
          "disabledWorker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabajador discapacitado"])}
        }
      },
      "VORWERK": {
        "INIT": {
          "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
          "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
          "maidenName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido de soltera"])},
          "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tratamiento"])},
          "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento"])},
          "socialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de seguridad social"])},
          "familySituation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situación familiar"])},
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de correo electrónico"])},
          "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
          "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° y calle"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad"])},
          "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
          "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
          "disabledWorker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabajador discapacitado"])}
        },
        "CLIENT": {
          "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
          "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
          "maidenName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido de soltera"])},
          "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tratamiento"])},
          "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento"])},
          "socialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de seguridad social"])},
          "familySituation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situación familiar"])},
          "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de correo electrónico"])},
          "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
          "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° y calle"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad"])},
          "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
          "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
          "disabledWorker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabajador discapacitado"])}
        },
        "CONSENT": {
          "CONSENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentimiento"])}
        }
      }
    }
  }
}