import { translate } from "@/i18n";

export const openBankingCountryoptions = [
  {
    name: translate("COMPANY.ORIGIN.AT"),
    value: "AT",
  },
  {
    name: translate("COMPANY.ORIGIN.BE"),
    value: "BE",
  },
  {
    name: translate("COMPANY.ORIGIN.DE"),
    value: "DE",
  },
  {
    name: translate("COMPANY.ORIGIN.DK"),
    value: "DK",
  },
  {
    name: translate("COMPANY.ORIGIN.EE"),
    value: "EE",
  },
  {
    name: translate("COMPANY.ORIGIN.EN"),
    value: "EN",
  },
  {
    name: translate("COMPANY.ORIGIN.ES"),
    value: "ES",
  },
  {
    name: translate("COMPANY.ORIGIN.FI"),
    value: "FI",
  },
  {
    name: translate("COMPANY.ORIGIN.FR"),
    value: "FR",
  },
  {
    name: translate("COMPANY.ORIGIN.GB"),
    value: "GB",
  },
  {
    name: translate("COMPANY.ORIGIN.IE"),
    value: "IE",
  },
  {
    name: translate("COMPANY.ORIGIN.IT"),
    value: "IT",
  },
  {
    name: translate("COMPANY.ORIGIN.LT"),
    value: "LT",
  },
  {
    name: translate("COMPANY.ORIGIN.LV"),
    value: "LV",
  },
  {
    name: translate("COMPANY.ORIGIN.NL"),
    value: "NL",
  },
  {
    name: translate("COMPANY.ORIGIN.NO"),
    value: "NO",
  },
  {
    name: translate("COMPANY.ORIGIN.PL"),
    value: "PL",
  },
  {
    name: translate("COMPANY.ORIGIN.PT"),
    value: "PT",
  },
  {
    name: translate("COMPANY.ORIGIN.SE"),
    value: "SE",
  },
];
